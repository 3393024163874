import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import ConfigureRouter from './router/'

// import 'antd/dist/antd.css';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import './assets/css/antd/antd.css'
import './assets/css/reset.scss'
import './assets/css/hospitali-icon4/iconfont.css'
import './assets/css/hospitali-icon1/iconfont.css'
import './assets/css/hospitali-icon2/iconfont.css'
import './assets/css/hospitali-icon3/iconfont.css'

ReactDOM.render(<ConfigureRouter />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
