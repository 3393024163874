import React, { Component } from 'react'
import Head from '../../components/head'
import { Button } from 'antd';
import businessQuitTime from '../../utils/businessQuitTime'
import { getSchedule } from '@/utils/request'
import { connect } from 'react-redux';
import * as registerAction from '../../store/actions/register'
import './todayRegister.scss'
import leftBtn from "../../assets/icons/DoubleLeftOutlined.svg";

// 渲染医生列表
class LoadDoctorList extends Component {

  render(){
    const { baseInfo, scheduleInfo, setSelectDoctor } = this.props
    let baseData = JSON.parse(localStorage.getItem('base'))
    const { timesNumberEnable, regToDeptTypeMap } = baseData        // timesNumberEnable 是否是分时段展示
    const { feeType=[] } = baseInfo
    console.log('timesNumberEnable 是否是分时段展示：', timesNumberEnable)
    console.log('baseInfo', baseInfo)
    console.log('scheduleInfo', scheduleInfo)

    let forenoonDoctor = []
    let afternoonDoctor = []
    let timeFrames = []
    let timeFrameEnum = {}
    let timeEnum = []

    // 过滤掉没有号源为0的字段
    let schedules =[]
    schedules = scheduleInfo.filter((item) => {
      return item.residue > 0
    })

    if(schedules.length > 0){
      timeEnum = typeof baseInfo.time == 'string' ? JSON.parse(baseInfo.time) : baseInfo.time   // 上下午数组、时段字符串
      timeFrameEnum = baseInfo.timeFrame && JSON.parse(baseInfo.timeFrame)    // 时段字符串
      if(!timesNumberEnable){
        // 号源按上下午
        forenoonDoctor = schedules.filter((doctor) => {
          return doctor.acceptsTimeBucket === '1'
        })
        afternoonDoctor = schedules.filter((doctor) => {
          return doctor.acceptsTimeBucket === '2'
        })
      }
      else{
        // 先将号源按动态时段分类
        let timesArr = Object.keys(timeFrameEnum) 
        let timeObject = {}
        timesArr.map((timeVal) => {
          timeObject[timeVal] = []
        })
        schedules.forEach((schedule) => {
          timeObject[schedule.timeFrame].push(schedule)
        })

        /*[
        *    {
        *      timeName: '2022-11-06',
        *      timeKey: '1',
        *      timeData: [],
        *    }
        * ] */
        //  组装成数组用于渲染
        for(let times in timeObject){
          timeFrames.push({
            timeName: timeFrameEnum[times],
            timeKey: times,
            timeData: timeObject[times]
          })
        }
      }
    }

    // 挂号医生展示
    const regToDoctor = (schedule) => {
      let fee = ''
      let doctorName = ''
      let feeItem = baseInfo.feeType.filter((fee) => {
        return fee.id == schedule.typeId
      })
      let doctorLabel = '医生姓名：'
      if(feeItem){
        fee = feeItem[0].fee
        if(schedule.regToDoctor == '0'){
          doctorLabel = '号别：'
          doctorName = feeItem[0].name
        }
      }

      if(schedule.doctor && schedule.regToDoctor != '0'){
        doctorName = schedule.doctor.name
      }
  
      return (
        <div className="sd-const">
          <div className="sd-cloum">
            {
              schedule.regToDoctor === '1' ? <h2 className="sd-til">{ doctorLabel }{ doctorName }</h2> : ''
            }
            {
              feeItem.length > 0 ? <p>号别：{ feeItem[0].name }</p> : ''
            }
            <p>诊察费：<span className="sd-amount">￥{ fee }</span></p>
            <p>剩余号：<span className="sd-surplus">{ schedule.residue }</span>个</p>
            <p>接诊日期：{ schedule.acceptsAt } </p>
            <p>接诊时段：
              { 
                timeEnum[schedule.acceptsTimeBucket]
              }
            </p>
          </div>
          <div className="sd-cloum">
            <Button type="primary" size="large" style={{marginTop: "12px", width: "100%"}} disabled={schedule.residue === 0 ? true : false} onClick={setSelectDoctor.bind(this, schedule)}>挂号</Button>
          </div>
        </div>
      );
    }

    // 挂号到科室展示
    const regToDepartment = (schedule) => {
      const feeItem = feeType.filter((fee) => fee.id === schedule.typeId)
      const fee = feeItem[0].fee
      let typeName = ''
      if(feeItem.length > 0){
        typeName = feeItem[0].name
      }
      
      // 判断是否需要过滤号别，如果号别不存在regToDeptTypeMap数组中就不需要渲染
      let isFilterName = false
      if(regToDeptTypeMap.length > 0){
        isFilterName = regToDeptTypeMap.some((deptType) => deptType === typeName)
      }

      return isFilterName ?  (
        <div className="sd-const">
          <div className="sd-cloum">
            <h2 className="sd-til">号别：{ typeName }</h2> 
            <p>诊察费：<span className="sd-amount">￥{ fee }</span></p>
            <p>剩余号：<span className="sd-surplus">{ schedule.residue }</span>个</p>
            <p>接诊日期：{ schedule.acceptsAt } </p>
            <p>接诊时段：
              { 
                timeEnum[schedule.acceptsTimeBucket]
              }
            </p>
          </div>
          <div className="sd-cloum">
            <Button type="primary" size="large" style={{marginTop: "12px", width: "100%"}} disabled={schedule.residue === 0 ? true : false} onClick={setSelectDoctor.bind(this, schedule)}>挂号</Button>
          </div>
        </div>
      ) : ''
    }

    // 分时段展示
    const showRegister = (scheduleData) => {
      let addSchedule = [];

      if(scheduleData.length > 0){
        scheduleData.forEach((schedule, idx, arr) => {
          let fee = ''
          let doctorName = ''
          let feeItem = baseInfo.feeType.filter((fee) => {
            return fee.id == schedule.typeId
          })

          let doctorLabel = '医生姓名：'
          if(feeItem){
            fee = feeItem[0].fee
            if(schedule.regToDoctor == '0'){
              doctorLabel = '号别：'
              doctorName = feeItem[0].name
            }
          }
          if(schedule.doctor && schedule.regToDoctor != '0'){
            doctorName = schedule.doctor.name
          }
          addSchedule.push(
            <div className="sd-const">
              <div className="sd-cloum">
                <h2 className="sd-til">{ doctorLabel }{ doctorName }</h2>
                {
                  feeItem.length > 0 ? <p>号别：{ feeItem[0].name }</p> : ''
                }
                <p>诊察费：<span className="sd-amount">￥{ fee }</span></p>
                <p>剩余号：<span className="sd-surplus">{ schedule.residue }</span>个</p>
                <p>接诊日期：{ schedule.acceptsAt } </p>
                <p>接诊时段：{ baseInfo.time[schedule.acceptsTimeBucket] }</p>
              </div>
              <div className="sd-cloum">
                <Button type="primary" size="large" style={{marginTop: "12px", width: "100%"}} disabled={schedule.residue === 0 ? true : false} onClick={setSelectDoctor.bind(this, schedule)}>挂号</Button>
              </div>
            </div>
          )
        })
      }

      return addSchedule
    }

    // 显示时段挂号
    const showTimeSchedule = (schedule, idx) => {
      const { timeName, timeData=[] } = schedule
      return (
        <>
          {
            timeData.length >0 ? (<div key="idx">
                <div className="vis-time">{timeName}</div> 
                <div style={{overflow: 'hidden'}}>{showRegister(timeData)}</div>
              </div>) : ''
          }
        </>
      )
    }
    
    return  (
      <div className="dep-list">
        {/* 时段医生展示 */}
        {
          timesNumberEnable ? 
            <>
              {
                schedules.length > 0 
                  ? timeFrames.map((schedule, idx) => {
                    return showTimeSchedule(schedule, idx)
                  })
                  : <div className="noSchedule">没有出诊医生</div>
              }
            </>
            : ''
        }

        {/* 
          * 上、下午展示 
          * eg.regToDoctor == '1' && reg.doctorId  
          * regToDoctor='0' 挂号到科室
          * regToDoctor='1' 挂号到医生
          */}
        {
          !timesNumberEnable ?
          <>
            <div className="pb-50">上午号源</div>
            <div className="clearfix">
              {
                forenoonDoctor.length > 0 ?
                  forenoonDoctor.map((reg) => {
                    
                    return reg.regToDoctor == '1' && reg.doctorId ? regToDoctor(reg) : regToDepartment(reg)
                  }) : <div className="noSchedule">暂无出诊医生</div>
              }
            </div>
            <div className="pb-50">下午号源</div>
            <div className="clearfix">
              {
                afternoonDoctor.length > 0 ?
                  afternoonDoctor.map((reg) => {
                    return reg.regToDoctor == '1' && reg.doctorId ? regToDoctor(reg) : regToDepartment(reg) 
                  }) : <div className="noSchedule">暂无出诊医生</div>
              }
            </div>
          </> : ''
        }
      </div>
    )
  }
}

/*
 * 选择医生
 */
class TodaySelectDoctor extends Component {
  constructor(props){
    super(props);
    this.state = {
      baseInfo: {},
      scheduleInfo: [],
      feeEnum: {},
    }
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/todaySelectDept')
  }

  componentDidMount(){
    this.querySchedule();
  }

  querySchedule(){
    const that = this;
    const { selectedDate, selectedDept } = this.props.register;

    getSchedule({
      deptId:selectedDept.id,
      date: selectedDate
    })
    .then(od => {
      if(od.status){
        console.log('baseInfo', od.data.base)
        console.log('schedule', od.data.schedule)

        const { base, schedule } = od.data
        that.setState({
          baseInfo: base,
          scheduleInfo: schedule,
        })
        const registerObj = Object.assign(this.props.register, {
          regBase: base,
          schedules: schedule
        })
        this.props.addRegister(registerObj);
      }
    })
    .catch(err => {
      that.props.history.push({
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  setSelectDoctor = (doctorInfo) => {
    // 需要存配置信息
    let registerObj = Object.assign(this.props.register, {
      doctorInfo,
    })
    this.props.addRegister(registerObj);
    this.props.history.push('/trPatientInfo');
  }

  render() {
    const quitTime = businessQuitTime.todayRegister.todaySelectDoctor;
    const { baseInfo, scheduleInfo }  = this.state;
    const toDoctorList = {
      baseInfo,
      scheduleInfo,
      setSelectDoctor: this.setSelectDoctor
    }

    return (
      <div>
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <h3 className="business-name">当日挂号 - 选择就诊医生</h3>
          <LoadDoctorList {...toDoctorList} />
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage.bind(this)} className="left"
                  style={{ backgroundColor: "#CEE2EE", border: "1px #B3D5E1 solid", color: "#514F4F"}}
          >
            <img src={leftBtn} width={28} /> 上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage.bind(this)}
                  style={{ "backgroundColor": "#bfdfe9", color: "#8D2829", border: "1px #3976A9 solid", "float": "right"}}>
            退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    register: state.register
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addRegister: (data) => {
      dispatch(registerAction.addRegister(data));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TodaySelectDoctor)
