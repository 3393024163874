import config from '../config'
// import generateMock from '../mock/api'

/**
 * Request
 */
import hprose from 'hprose-html5'
import { notification } from 'antd'

const hardwareDomainName = config.hardwareUrl
const hisDomainName = config.hisUrl
const adminDomainName = config.adminUrl
const logDomainName = config.loggerUrl
const registrationName = config.registrationUrl
const queryAPIName = config.queryAPIUrl
const apiName = config.apiUrl

if(config.isShowTestLog){
console.log(`
🌏 调用服务:
hardware: ${hardwareDomainName},
his: ${hisDomainName},
admin: ${adminDomainName},
log: ${logDomainName},
registration: ${registrationName},
queryAPI: ${queryAPIName},
api: ${apiName},
`)
}

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
}

// 创建硬件接口客户端
export function hardwareRpc() {
  return hprose.Client.create(
	  hardwareDomainName, [
		  "ReadIDCard",         // 读取身份证
		  "MakeCard",           // 获取卡号
      "ReadCard",           // 获取display_no
		  "TakeCard",           // 有卡时读取就诊卡序列号
		  "WriteCard",          // 写卡
		  "ExitCard",           // 退卡
      "printTradBill",      // 打印交易凭条
      "PrintRegBill",       // 打印挂号凭条
      "PrintPayBill",       // 打印支付凭条
      "PrintPayBill_HIS",   // 打印缴费凭条
      "PrintPayCopy",       // 补打
      "PrintRechargeBill",  // 打印充值凭条
      "PrintDepositBill",   // 打印住院凭条
      "PrintCardFee",       // 打印卡费凭条
      "PrintVouCard",       // 打印发卡凭条
		  "CardDevStatus",      // 查询硬件设备
      "RecycleCard",        // 吞卡
      "CardDevReset", 
      "getUserInfo",        // 读银行卡获取用户信息
      "TransPay",           // 银行卡支付
      "PrintLisResult",     // 门诊检验结果查询打印
      "PrintLisReport",     // Lis门诊检验结果查询打印（故城特殊处理）
      "ReadjzCard",         // 获取mr_no
      "ReadICCard",         // 本地获取mr_no
      "ReadIDCard",         // 身份证获取用户信息
      "GetPatientID",       // 通过串号取得患者ID
      "PrintjzQrcode",      // 打印就诊二维码
      "PrintEcgResult",     // 打印心电报告单
      "getInvInfo",         // 解析告知单二维码
      "printeinvoice",      // 单据文件打印（base64图片打印）
      "PrintEinvNotice",    // 打印电子发票
      "BankRefund",         // 银行卡退费
      "getLocalMac",        // 获取本地信息
      "getInsurEcCode",     // 电子凭证二维码取医保信息
      "getPersonInfoByQrCode",   // 电子健康卡二维码解码
      "eJjkSignup",         // 注册电子健康卡
      "PrintPayBill_Insu",   // 打印医保缴费凭条
    ],
    { timeout: 120000 }
  )
}

// 创建HIS接口客户端
export function hisRpc() {
  return hprose.Client.create(hisDomainName, [
      "GetKey",             // 获取KeyB
      "CheckCard",          // 获取就诊卡状态
      "MakeCard",           // 创建就诊卡
      "GetPatientInfo",     // 获取患者信息
      "GetBalance",         // 查询就诊卡余额
      "Recharge",           // 就诊卡充值
      "GetPrescriptions",   // 获取门诊经缴费的处方
      "Pay",                // 门诊支付
      "GetPayDetails",      // 门诊缴费明细
      "QueryCurOutFee",     // 门诊费用查询
      "QueryDrugPrice",     // 药品费用查询
      "QueryCurInFee",      // 住院费用查询
      "GetDepositCost",     // 住院押金余额
      "DepositRecharge",    // 住院押金充值
      "sysinfo",
      "GetPatientID",       // 通过身份证号获取mr_no
      "GetPatientINNO",     // 通过身份证号获取住院号
      "Payment",            // 门诊缴费、今日挂号费
      "LISQueryReport",     // LIS查询要打印的检验报告
      "LISReportDetail",    // LIS查询检验报告明细
      "LISReportFile",      // LIS查询检验报告图片文件
      "LISPrintFinish",     // LIS报告明细打印完成
      "MakePatient",        // 没有院内就诊卡的自助机
      "ECGQueryReport",     // 心电查询要打印的报告
      "GetPrintInvoice",    // 查询需要打印的电子发票
      "FinishPrintInvoice", // 修改状态为已打印
      "NewOrderFromHIS",    // HIS生成订单
      "SaveOrdersToHIS",    // 更新订单状态
      "GetPrintregdata",    // 取挂号凭条数据
      "QueryDrugPrice",     // 药品查询接口 His（新）
      "QueryItemPrice",     // 项目价格查询接口 His（新）
      "GetPatInfoAll",      // 获取患者信息（医保）
      "InsuPreSettle",      // 医保预结算（医保）
      "InsuSettle",         // 门诊缴费（医保）
    ],
    { timeout: 120000 }
  )
}

// 创建管理后台服务接口客户端
export function adminRpc() {
  return hprose.Client.create(adminDomainName, [
      "initDevice",
      "getSetting",
      "newSession",     // 创建Session
      "generateQR",     // 返回二维码
      "IsAdmin",        // 管理员登录
      "printData",      // 获取补打数据
      "newOrderFromBank",
      "updateOrderFromBank",
      "refund",         // 退费
      "getPersonInfoByQrCode",
      "RegisterHealthCard", // 注册电子健康卡
    ],
    { timeout: 120000 }
  )
}

// 特殊处理支付超时
export function adminPayRpc() {
  return hprose.Client.create(adminDomainName, [
      "queryOrder",     // 返回订单
    ],
    { timeout: 300000 }
  )
}

// 挂号取号
export function registrationRpc() {
  return hprose.Client.create(registrationName, [
      "getDept",              // 获取号源科室列表
      "getSchedule",          // 获取制定科室的号源排期
      "registration",         // 患者预约挂号
      "regToday",             // 今日挂号
      "getRegistrationsByMrNo", // 获取患者挂号列表
      "takeNo",               // 取预约号
    ],
    { timeout: 120000 }
  )
}

// 查询接口API
export function queryAPIRpc() {
  return hprose.Client.create(queryAPIName, [
      "QueryDrugs",               // 药品查询
      "QueryItems",               // 收费项目查询
      "QueryInvoiceFile",         // 获取电子票据文件信息
      "GetInvoiceFileOne",        // 获取电子票据文件(BASE64)
      "QueryPatientinfoFromLIS",  // 通过身份证号获取患者信息
    ],
    { timeout: 120000 }
  )
}

export function apiRpc() {
  return hprose.Client.create(apiName, [
      "applyEinvoice",   // 申请电子发票
      "getInsurEcCode",
    ],
    { timeout: 120000 }
  )
}

// 创建Log服务接口客户端
export function logRpc() {
  return hprose.Client.create(logDomainName, [
      "flow",
      "hardware",
      "structure",
    ],
    { timeout: 120000 }
  )
}

// 处理状态
const checkStatus = response => {
  let errorText = response.message || codeMessage[response.status]
  
  if(response.status === 500){   
    errorText = codeMessage['500']
    notification.error({
      message: `错误`,
      description: response.message || '',
      duration: 10,
    })
    return false
  }

  // 接口约定 status=false 并且 message不等于空串才会提示错误信息
  if (!response.status && response.message !== '') {
    notification.error({
      message: `错误`,
      description: errorText,
      duration: 10,
    })
  }
  
  return response
}

export function Result(result) {
  return checkStatus(result)
}
 
export function invoke(apiMethod, args, InterfaceName) {
  if (args) {
    return apiMethod(args)
    .then(result => {
      return Result(result)
    })
    .catch(e => {
      // console.log(`Catch ======>>>> ${e.toString()}`)
      return Result({
        status: false,
        message: `${InterfaceName}接口，${e.toString()}`
      })
    })
  }

  return apiMethod()
    .then(result => {
      return Result(result)
    })
    .catch(e => {
      // console.log(`Catch ======>>>> ${e.toString()}`)
      return Result({
        status: false,
        message: `${InterfaceName}接口，${e.toString()}`
      })
    })
}

// 中间件写Log日志
function logEventHandler(name, args, context, next) {
  let that = this

  // 测试使用
  if(config.isShowTestLog){
    console.log(`Request: ${name}, ${JSON.stringify(args)}`)
  }
  
  // 如果找不到session不在添加log
  // let baseData = hisStore.getState().base;
  // if(!baseData){
  //   return false;
  // }
  var result = next(name, args, context)
  result.then(function(result) {
    
    // 测试使用
    if(config.isShowTestLog){
      console.log(`Response: ${JSON.stringify(result)}`)
      console.log(`Log Request: ${JSON.stringify({
          // sessionId: window.hisStore.getState().base.sessionId || '',
          // deviceId: window.hisStore.getState().base.deviceId || '',
          name,
          type: result.status ? 'INFO' : 'ERROR',
          content: {
            request: {
              method: name, 
              params: args,
            },
            response: result,
          }
        })}
      `)
    }

    // 提交软件Log日志
    sendLog(name, args, result.status, result)
  })
  .catch(function(e){
    console.log(`Error: ${name} ${e.toString()}`)
    sendLog(name, args, false, e.toString())
  })

  return result
}

// 硬件日志区分log重名
function hardwareLog(name, args, context, next) {
  let that = this
  const logName = `hardware.${name}`

  // 测试使用
  if(config.isShowTestLog){
    console.log(`Request: ${name}, ${JSON.stringify(args)}`)
  }
  
  // 如果找不到session不在添加log
  // let baseData = hisStore.getState().base;
  // if(!baseData){
  //   return false;
  // }
  var result = next(name, args, context)
  result.then(function(result) {
    
    // 测试使用
    if(config.isShowTestLog){
      console.log(`Response: ${JSON.stringify(result)}`)
      console.log(`Log Request: ${JSON.stringify({
          // sessionId: window.hisStore.getState().base.sessionId || '',
          // deviceId: window.hisStore.getState().base.deviceId || '',
          name,
          type: result.status ? 'INFO' : 'ERROR',
          content: {
            request: {
              method: logName, 
              params: args,
            },
            response: result,
          }
        })}
      `)
    }

    // 提交软件Log日志
    sendLog(logName, args, result.status, result)
  })
  .catch(function(e){
    console.log(`Error: ${name} ${e.toString()}`)
    sendLog(logName, args, false, e.toString())
  })

  return result
}

function sendLog(name, args, status, result){
  let sessionId = ''
  let deviceId = ''
  let base = JSON.parse(localStorage.getItem('base'))

  if(base && base.sessionId){
    sessionId = base.sessionId
  }
  if(base && base.deviceId){
    deviceId = base.deviceId
  }

  // if(window.hisStore.getState().base && window.hisStore.getState().base.sessionId){
  //   sessionId = window.hisStore.getState().base.sessionId;
  // }

  // if(window.hisStore.getState().base && window.hisStore.getState().base.deviceId){
  //   deviceId = window.hisStore.getState().base.deviceId;
  // }
  saveEventToLog({
    sessionId,
    deviceId,
    name,
    type: status ? 'INFO' : 'ERROR',
    content: {
      request: {
        method: name,
        params: args,
      },
      response: result,
    }
  })
}

/**************** hardware ****************/
export async function getReadIDCard(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.ReadIDCard, param, "ReadIDCard")
}

export async function getMakeCard(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.MakeCard, param, "MakeCard")
}

export async function getReadCard(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.ReadCard, param, "ReadCard")
}

export async function getTakeCard(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.TakeCard, param, "TakeCard")
}

export async function sendWriteCard(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.WriteCard, param, "WriteCard")
}

export async function sendExitCard(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.ExitCard, param, "ExitCard")
}

export async function printTradBill(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.printTradBill, param, "printTradBill")
}

export async function printRegBill(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.PrintRegBill, param, "PrintRegBill")
}

export async function printPayCopy(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.PrintPayCopy, param, "PrintPayCopy")
}

export async function printRechargeBill(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.PrintRechargeBill, param, "PrintRechargeBill")
}

export async function printPayBill(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.PrintPayBill, param, "PrintPayBill")
}

export async function printPayBillHis(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.PrintPayBill_HIS, param, "PrintPayBill_HIS")
}

export async function cardDevStatus(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.CardDevStatus, param, "CardDevStatus")
}

export async function recycleCard(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.RecycleCard, param, "RecycleCard")
}

export async function printDepositBill(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.PrintDepositBill, param, "PrintDepositBill")
}

export async function printCardFee(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.PrintCardFee, param, "PrintCardFee")
}

export async function printVouCard(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.PrintVouCard, param, "PrintVouCard")
}

export async function getUserInfo(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.getUserInfo, param, "getUserInfo")
}

export async function transPay(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.TransPay, param, "TransPay")
}

export async function printLisResult(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.PrintLisResult, param, "PrintLisResult")
}

export async function printLisReport(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.PrintLisReport, param, "PrintLisReport")
}

export async function readjzCard(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.ReadjzCard, param, "ReadjzCard")
}

export async function readICCard(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.ReadICCard, param, "ReadICCard")
}

export async function readIDCard(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.ReadIDCard, param, "ReadIDCard")
}

export async function hardwareGetPatientID(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.GetPatientID, param, "GetPatientID")
}

export async function printjzQrcode(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.PrintjzQrcode, param, "PrintjzQrcode")
}
export async function printEcgResult(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.PrintEcgResult, param, "PrintEcgResult")
}

export async function getInvInfo(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.getInvInfo, param, "getInvInfo")
}

export async function printeinvoice(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.printeinvoice, param, "printeinvoice")
}

export async function printEinvNotice(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.PrintEinvNotice, param, "printEinvNotice")
}

export async function bankRefund(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.BankRefund, param, "bankRefund")
}

export async function getLocalMac(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.getLocalMac, param, "getLocalMac")
}

export async function getInsurEcCode(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.getInsurEcCode, param, "getInsurEcCode")
}

export async function localGetPersonInfoByQrCode(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.getPersonInfoByQrCode, param, "getPersonInfoByQrCode")
}

export async function eJjkSignup(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.eJjkSignup, param, "eJjkSignup")
}

export async function printPayBillInsu(param) {
  const client = hardwareRpc()
  client.use(hardwareLog)
  return invoke(client.PrintPayBill_Insu, param, "PrintPayBill_Insu")
}


/**************** his ****************/
export async function getGetKey(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.GetKey, param, "GetKey")
}

export async function getCheckCard(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.CheckCard, param, "CheckCard")
}

export async function getHisMakeCard(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.MakeCard, param, "MakeCard")
}

export async function getHisPatientInfo(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.GetPatientInfo, param, "GetPatientInfo")
}

export async function getHisBalance(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.GetBalance, param, "GetBalance")
}

export async function sendHisRecharge(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.Recharge, param, "Recharge")
}

export async function getHisPrescriptions(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.GetPrescriptions, param, "GetPrescriptions")
}

export async function sendHisPay(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.Pay, param, "Pay")
}

export async function getPayDetails(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.GetPayDetails, param, "GetPayDetails")
}

export async function queryCurOutFee(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.QueryCurOutFee, param, "QueryCurOutFee")
}

export async function queryCurInFee(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.QueryCurInFee, param, "QueryCurInFee")
}

export async function getDepositCost(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.GetDepositCost, param, "GetDepositCost")
}

export async function depositRecharge(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.DepositRecharge, param, "DepositRecharge")
}

export async function getPatientID(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.GetPatientID, param, "GetPatientID")
}

export async function getPatientINNO(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.GetPatientINNO, param, "GetPatientINNO")
}

export async function sendPayment(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.Payment, param, "Payment")
}

export async function queryDrugPrice(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.QueryDrugPrice, param, "QueryDrugPrice")
}

export async function lisQueryReport(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.LISQueryReport, param, "LISQueryReport")
}

export async function lisReportDetail(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.LISReportDetail, param, "LISReportDetail")
}

export async function lisReportFile(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.LISReportFile, param, "LISReportFile")
}

export async function lisPrintFinish(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.LISPrintFinish, param, "LISPrintFinish")
}

export async function makePatient(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.MakePatient, param, "MakePatient")
}

export async function getECGQueryReport(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.ECGQueryReport, param, "ECGQueryReport")
}

export async function getPrintInvoice(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.GetPrintInvoice, param, "GetPrintInvoice")
}

export async function finishPrintInvoice(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.FinishPrintInvoice, param, "FinishPrintInvoice")
}

export async function newOrderFromHIS(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.NewOrderFromHIS, param, "NewOrderFromHIS")
}

export async function saveOrdersToHIS(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.SaveOrdersToHIS, param, "SaveOrdersToHIS")
}

export async function getPrintregdata(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.GetPrintregdata, param, "GetPrintregdata")
}

export async function queryDrugPriceHisNew(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.QueryDrugPrice, param, "QueryDrugPrice")
}

export async function queryItemPrice(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.QueryItemPrice, param, "QueryItemPrice")
}

export async function getPatInfoAll(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.GetPatInfoAll, param, "GetPatInfoAll")
}

export async function insuPreSettle(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.InsuPreSettle, param, "InsuPreSettle")
}

export async function insuSettle(param) {
  const client = hisRpc()
  client.use(logEventHandler)
  return invoke(client.InsuSettle, param, "InsuSettle")
}


/**************** 查询接口API ****************/
export async function queryDrugs(param) {
  const client = queryAPIRpc()
  client.use(logEventHandler)
  return invoke(client.QueryDrugs, param, "QueryDrugs")
}

export async function queryItems(param) {
  const client = queryAPIRpc()
  client.use(logEventHandler)
  return invoke(client.QueryItems, param, "QueryItems")
}

export async function queryInvoiceFile(param) {
  const client = queryAPIRpc()
  client.use(logEventHandler)
  return invoke(client.QueryInvoiceFile, param, "QueryInvoiceFile")
}

export async function getInvoiceFileOne(param) {
  const client = queryAPIRpc()
  client.use(logEventHandler)
  return invoke(client.GetInvoiceFileOne, param, "GetInvoiceFileOne")
}

export async function queryPatientinfoFromLIS(param) {
  const client = queryAPIRpc()
  client.use(logEventHandler)
  return invoke(client.QueryPatientinfoFromLIS, param, "QueryPatientinfoFromLIS")
}

/**************** API ****************/
export async function applyEinvoice(param) {
  const client = apiRpc()
  client.use(logEventHandler)
  return invoke(client.applyEinvoice, param, "applyEinvoice")
}

export async function getInsurEcCodeApi(param) {
  const client = apiRpc()
  client.use(logEventHandler)
  return invoke(client.getInsurEcCode, param, "getInsurEcCode")
}

/**************** admin ****************/
export async function getNewSession(param) {
  const client = adminRpc()
  client.use(logEventHandler)
  return invoke(client.newSession, param, "newSession")
}

export async function getSetting(param) {
  const client = adminRpc()
  client.use(logEventHandler)
  return invoke(client.getSetting, param, "getSetting")
}

export async function getInitDevice(param) {
  const client = adminRpc()
  // client.use(logEventHandler)
  return invoke(client.initDevice, param, "initDevice")
}

export async function getGenerateQR(param) {
  const client = adminRpc()
  client.use(logEventHandler)
  return invoke(client.generateQR, param, "generateQR")
}

export async function getQueryOrder(param) {
  const client = adminPayRpc()
  client.use(logEventHandler)
  return invoke(client.queryOrder, param, "queryOrder")
}

export async function isAdmin(param) {
  const client = adminRpc()
  client.use(logEventHandler)
  return invoke(client.IsAdmin, param, "IsAdmin")
}

export async function printData(param) {
  const client = adminRpc()
  client.use(logEventHandler)
  return invoke(client.printData, param, "printData")
}

export async function newOrderFromBank (param) {
  const client = adminRpc()
  client.use(logEventHandler)
  return invoke(client.newOrderFromBank, param, "newOrderFromBank")
}

export async function updateOrderFromBank(param) {
  const client = adminRpc()
  client.use(logEventHandler)
  return invoke(client.updateOrderFromBank, param, "updateOrderFromBank")
}

export async function refund(param) {
  const client = adminRpc()
  client.use(logEventHandler)
  return invoke(client.refund, param, "refund")
}

export async function getPersonInfoByQrCode(param) {
  const client = adminRpc()
  client.use(logEventHandler)
  return invoke(client.getPersonInfoByQrCode, param, "getPersonInfoByQrCode")
}

export async function registerHealthCard(param) {
  const client = adminRpc()
  client.use(logEventHandler)
  return invoke(client.RegisterHealthCard, param, "RegisterHealthCard")
}

/************ registration *************/
export async function getDept(param) {
  const client = registrationRpc()
  client.use(logEventHandler)
  return invoke(client.getDept, param, "getDept")
}

export async function getSchedule(param) {
  const client = registrationRpc()
  client.use(logEventHandler)
  return invoke(client.getSchedule, param, "getSchedule")
}

export async function registration(param) {
  const client = registrationRpc()
  client.use(logEventHandler)
  return invoke(client.registration, param, "registration")
}

export async function getRegistrationsByMrNo(param) {
  const client = registrationRpc()
  client.use(logEventHandler)
  return invoke(client.getRegistrationsByMrNo, param, "getRegistrationsByMrNo")
}

export async function takeNo(param) {
  const client = registrationRpc()
  client.use(logEventHandler)
  return invoke(client.takeNo, param, "takeNo")
}

export async function regToday(param) {
  const client = registrationRpc()
  client.use(logEventHandler)
  return invoke(client.regToday, param, "regToday")
}

/**************** log ****************/
export async function saveEventToLog(param) {
  const client = logRpc()
  return invoke(client.flow, param, "flow")
}

export async function getHardware(param) {
  const client = logRpc()
  return invoke(client.hardware, param, "hardware")
}

export async function getStructure(param) {
  const client = logRpc()
  return invoke(client.structure, param, "structure")
}
