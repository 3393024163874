import React, { Component } from 'react'
import Head from '../head/'
import { Button, Icon } from 'antd';
import businessQuitTime from '../../utils/businessQuitTime'

import './message.scss'

export default class Error extends Component {
  constructor(props){
    super(props);
    this.state = {
      msgTitle: '',
      msgContent: ''
    }
    this.goHomePage = this.goHomePage.bind(this);
  }

  goHomePage(){
    this.props.history.push('/')
  }

  componentDidMount(){
    const { location } = this.props;
    let msgTitle = location.query && (location.query.messageTitle || '');
    let msgContent = location.query && (location.query.messageContent || '');
    this.setState({
      msgTitle,
      msgContent
    })
  }

  render() {
    const { msgTitle, msgContent } = this.state;

    return (
      <div>
        <Head quitTime={businessQuitTime.errorMessage} />

        <div className="context-container clearfix">
          <div className="status-box">
            <div className="error-ico">
              <Icon type="close" theme="outlined" />
            </div>
            <div className="error-text">
              <h1>{ msgTitle }</h1>
              <p>{ msgContent }</p>
            </div>
          </div>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goHomePage}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
        </div>
      </div>
    )
  }
}

// Error.defaultProps = {
//   msgTitle: '充值失败',
//   msgContent: '请取您的充值单请取您的充值单请取您的充值单'
// }