import React, { Component } from 'react'
import Head from '../../components/head'
import { Button, Icon, Table } from 'antd';
import businessQuitTime from '../../utils/businessQuitTime'
import { connect } from 'react-redux';
import * as inpatientAction from '../../store/actions/inpatient'
import { queryCurInFee } from '@/utils/request'
import './inpatientCost.scss'

/*
 * 住院费用列表
 */
class InpatientList extends Component {
  constructor(props){
    super(props);
    this.state = {
      // 表数据
      queryInfo: {
        pageSize: 7
      },
      columns: [
        {
          title: '时间',
          dataIndex: 'CHARGE_DATE',
        }, 
        {
          title: '项目名称',
          dataIndex: 'ITEM_NAME',
        }, 
        {
          title: '项目单位',
          dataIndex: 'UNIT',
        }, 
        {
          title: '单价',
          dataIndex: 'PRICE',
        }, 
        {
          title: '数量',
          dataIndex: 'COUNTS',
        }, 
        {
          title: '总价',
          dataIndex: 'COST',
        }, 
      ],
      hospitalCostData: [],
    }

    this.goUpPage = this.goUpPage.bind(this)
    this.goHomePage = this.goHomePage.bind(this)
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/inpatientCost')
  }
  
  componentDidMount(){
    if(window.screen.height >= 1920){
      this.setState({
        queryInfo: {
          pageSize: 15
        },
      })
    }
    this.queryRecycleCard()
  }

  // 查询列表
  queryRecycleCard(){
    let that = this
    const { inpatient } = this.props
    if(!inpatient){
      return false
    }

    queryCurInFee({
      in_no: inpatient.inpatientNo
    })
    .then(od => {
      if(od.status){
        Object.assign(inpatient, {
          costDataList: od.data
        })
        that.props.addInpatient(inpatient);
        this.setState({
          hospitalCostData: od.data
        })
      }
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  render() {
    const quitTime = businessQuitTime.inpatientCost.inpatientList
    const { queryInfo, columns, hospitalCostData } = this.state

    return (
      <div>
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <h3 className="business-name">住院查询 - 住院费用列表</h3>

          <Table pagination={{ pageSize: queryInfo.pageSize }} 
                 columns={columns} 
                 dataSource={hospitalCostData}
                 className="mt-25"  />
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    inpatient: state.inpatient,
    cardInfo: state.cardInfo,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addInpatient: (data) => {
      dispatch(inpatientAction.addInpatient(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InpatientList)