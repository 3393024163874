import React, { Component } from 'react'
import Head from '../../components/head'
import businessQuitTime from '../../utils/businessQuitTime'
import { getPayDetails } from '@/utils/request'
import { connect } from 'react-redux'
import * as cardInfoAction from '../../store/actions/cardInfo'
import * as outpatientAction from '../../store/actions/outpatient'
import * as paymentAction from '../../store/actions/payment'
import { Button, Icon, Table } from 'antd'
import './outpatientPay.scss'

const btnStyle = {
  backgroundColor: "#00bd58",
  border: "1px #00bd58 solid",
  marginTop: "20px",
  marginLeft: "20px",
}

/*
 *  门诊处方详情列表
 */
class OutpatientPayDetail extends Component {
  constructor(props){
    super(props)

    this.state = {
      pageSize: 7,
      tableColumns: [
        {
          title: '序号',
          width: '10%',
          render:(text,record,index)=> `${index+1}`,
        },
        {
          title: '项目名称',
          dataIndex: 'M_NAME',
        },
        {
          title: '项目数量',
          dataIndex: 'M_COUNT',
        },
        {
          title: '规格',
          dataIndex: 'M_SPEC',
        },
        {
          title: '金额',
          dataIndex: 'M_COST',
        },
      ],
      payDetailData: [],
      loading: true
    }
    this.goUpPage = this.goUpPage.bind(this)
    this.goHomePage = this.goHomePage.bind(this)
  }

  componentDidMount() {
    this.getPayDetailList()
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/payMentList')
  }

  // 获取列表数据
  getPayDetailList(){
    const that = this
    const { records, amount } = this.props.outpatient

    getPayDetails(records)
    .then(od => {
      od.data.forEach((detail, index) => {
        detail['key'] = index
      })

      that.props.addOutpatient({
        amount,
        recordDetail: od.data
      })
      that.setState({
        loading: false,
        payDetailData: od.data
      })
    })
    .catch(err => {
      that.setState({
        loading: false
      })
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  printPayDetail(){
    const { amount } = this.props.outpatient

    this.props.addPayment({
      businessType: 'payBill',
      printTitle: '门诊缴费',
      amount,
    })
    this.props.history.push('/payPrint')
  }

  goPaymentList(){
    this.props.history.push('/payMentList')
  }


  render() {
    const { tableColumns, payDetailData, pageSize, loading } = this.state
    const quitTime = businessQuitTime.outpatientPay.outpatientPayDetail

    return (
      <div>
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <h3 className="business-name">门诊缴费 - 缴费明细</h3>
          <Table 
            pagination={{ pageSize }} 
            columns={tableColumns} 
            dataSource={payDetailData} 
            loading={loading}
            className="mt-25" />
          <div className="pay-balance">
            <em>
              <Button type="primary" size="large" onClick={() => this.goPaymentList()} style={btnStyle}>继续缴费</Button>
              <Button type="primary" size="large" onClick={() => this.printPayDetail()} style={btnStyle}>打印缴费凭条</Button>
            </em>
          </div>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { cardInfo, pay, base, outpatient } = state;
  return {
    cardInfo,
    pay,
    base,
    outpatient
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addCard: (data) => {
      dispatch(cardInfoAction.addCard(data));
    },
    addOutpatient: (data) => {
      dispatch(outpatientAction.addOutpatient(data));
    },
    addPayment: (data) => {
      dispatch(paymentAction.addPayment(data));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OutpatientPayDetail)