import React, { Component } from 'react'
import Head from '../../components/head'
import { Button, Icon, Table, notification } from 'antd';
import { connect } from 'react-redux';
import { getECGQueryReport, printEcgResult } from '@/utils/request'
import businessQuitTime from '../../utils/businessQuitTime'

class PrintHospitalizedECGReportList extends Component {
  constructor(props){
    super(props);
    this.state = {
      printData: [],
      queryInfo: {
        pageSize: 7
      },
      columns: [
        {
          title: '病历号',
          dataIndex: '病历号',
        }, 
        {
          title: '姓名',
          dataIndex: '姓名',
          align: 'center',
        }, 
        {
          title: '性别',
          dataIndex: '性别',
          align: 'center',
        },
        {
          title: '检查项目名称',
          dataIndex: '检查项目名称',
          align: 'center',
        },
      ],
      loading: false,
      spinLogin: false,
      selectedPrint: [],
      printLength: 0,

      selectedRows: [],
      reportDetail: {},
      reportDetailFile: {},
    };
    this.goUpPage = this.goUpPage.bind(this);
    this.goHomePage = this.goHomePage.bind(this);
  }

  componentDidMount(){
    this.getPrintData()
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/printHospitalizedECGReportInfo')
  }

  getPrintData(){
    const that = this
    const { inpatientNo } = this.props.inpatient

    getECGQueryReport({
      '病历号': inpatientNo
    })
    .then(od => {
      if(od.status){
        that.setState({
          printData: od.data,
          printLength: od.data.length
        })
      }
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 请求打印
  printECGReport(){
    const that = this
    const { selectedRows } = this.state
    let sendParams = [];

    if(selectedRows.length === 0){
      notification.error({
        message: `错误`,
        description: '请选择一项打印报告单',
        duration: 10,
      })
      return false
    }

    selectedRows.forEach((row) => {
      sendParams.push({
        ECG_PATH: row['报告路径']
      })
    })

    printEcgResult(sendParams)
    .then(od => {
      if(od.status){
        that.props.history.push({ 
          pathname : '/printResult',
          query : { messageContent: '报告单打印完成'}
        })
      }
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }


  render() {
    const quitTime = businessQuitTime.printOutpatientResult.printOutpatientResult;
    const { columns, printData, loading, queryInfo, printLength, spinLogin } = this.state;

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedRows
        })
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      },
    }

    return (

      <div>
        <Head quitTime={quitTime} />
        <div className="context-container clearfix">
          <h2 className="crumbs">住院心电报告打印报告</h2>
          <div>本次共有{ printLength }份报告单待打印!</div>
          <Table pagination={{ pageSize: queryInfo.pageSize }} 
            rowSelection={ rowSelection }
            columns={columns}
            dataSource={printData} 
            loading={loading}
            className="mt-25" />
          <Button type="primary" size="large" onClick={() => this.printECGReport()} 
            style={{ backgroundColor: "#00bd58", border: "1px #00bd58 solid", margin: "20px auto", display: "block" }}>打印</Button>
        </div>
        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    inpatient: state.inpatient,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintHospitalizedECGReportList)