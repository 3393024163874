import React, { Component } from 'react'
import Head from '../../components/head/'
import { Button, Icon } from 'antd'
import businessQuitTime from '../../utils/businessQuitTime'
import { connect } from 'react-redux'
import * as inpatientAction from '../../store/actions/inpatient'

/*
 * 住院患者信息
 */
class HpPatientInfo extends Component {
  constructor(props){
    super(props)
    this.state = {
      patientInfo: {}
    }
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/inpatientPay')
  }

  componentDidMount(){
    this.setState({
      patientInfo: this.props.inpatient.patientInfo,
    })
  }

  depositPay(){
    this.props.history.push('/inpatientDeposit')
  }  

  render() {
    const quitTime = businessQuitTime.inpatientPay.hpPatientInfo
    const { patientInfo } = this.state

    return (
      <div>
        <Head quitTime={quitTime} />
            
        <div className="context-container clearfix">
          <h3 className="business-name">住院押金 - 患者信息</h3>
          <div className="par-info">
            <p>
              <em>患者姓名</em>
              <i>{ patientInfo.NAME }</i>
            </p>
            <p>
              <em>姓别</em>
              <i>{ patientInfo.SEX === '2' ? '女' : '男' }</i>
            </p>
            <p>
              <em>科室名称</em>
              <i>{ patientInfo.SE_NAME }</i>
            </p>
            <p>
              <em>床位号</em>
              <i>{ patientInfo.SB_NO }</i>
            </p>
            <p>
              <em>病区号</em>
              <i>{ patientInfo.SA_CODE }</i>
            </p>
            <p>
              <em>已交押金总额</em>
              <i>{ patientInfo.DEPOSIT }</i>
            </p>
            <p>
              <em>住院费用额</em>
              <i>{ patientInfo.TOTAL_FEE }</i>
            </p>
            <p>
              <em>住院押金余额</em>
              <i>{ patientInfo.COST }元</i>
            </p>
            <div>
              <Button type="primary" size="large" onClick={this.depositPay.bind(this)} style={{width: "500px", backgroundColor: "#00bd58", border: "1px #00bd58 solid", marginTop: "20px"}}>缴纳住院押金</Button>
            </div>
          </div>
        </div>
        
        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage.bind(this)} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage.bind(this)}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { inpatient } = state
  return {
    inpatient,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addInpatient: (data) => {
      dispatch(inpatientAction.addInpatient(data))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HpPatientInfo)