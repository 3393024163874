/* 
 * 存储全局的一些变量
 * {
 *   sessionId: '',     // sessionId
 *   deviceId: '',      // 设备id
 * }
 */
export const addBase = (data) => {
  return {
    type: "ADD_BASE",
    data
  }
}

export const clearBase = (data) => {
  return {
    type: "CLEAR_BASE",
    data
  }
}
