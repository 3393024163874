import React, { Component } from 'react'
import { Button, Icon } from 'antd';
import Head from '../head/'
import businessQuitTime from '../../utils/businessQuitTime'
import { connect } from 'react-redux';
import './pay.scss'
import zfbIcon from '../../assets/img/zfb.png'
import wxIcon from '../../assets/img/wx.png'
import bankIcon from '../../assets/img/bank.png'
import ylqrIcon from '../../assets/img/ylqr.png'
import ybcardIcon from '../../assets/img/ybcard.png'
import eybcardIcon from '../../assets/img/eybcard.png'

const payEnum = {
  "weixin": {
    style: 'pay-flx wx-bg',
    url: 'wxPay',
    img: wxIcon,
  },
  "alipay": {
    style: 'pay-flx zfb-bg',
    url: 'zfbPay',
    img: zfbIcon,
  },
  "ylcard": {
    style: 'pay-flx yhk-bg',
    url: 'yhkPay',
    img: bankIcon,
  },
  "ylqrcode": {
    style: 'pay-flx ylq-bg',
    url: 'jhPay',
    img: ylqrIcon,
  },
  "nsqrcode": {
    style: 'pay-flx nsylq-bg',
    url: 'nsjhPay',
    img: ylqrIcon,
  },
  "ybcard": {     // 医保
    style: 'pay-flx yb-bg',
    url: 'yhkPay',
    img: ybcardIcon,
  },
  "eybcard": {    // 电子医保
    style: 'pay-flx eyb-bg',
    url: 'yhkPay',
    img: eybcardIcon,
  },
}

class PayList extends Component {
  constructor(props){
    super(props);
    this.state = {
      bankDialog: false,
      zfbDialog: false,
      wxDialog: false,
      payList: [],
    }
    this.goUpPage = this.goUpPage.bind(this);
    this.goHomePage = this.goHomePage.bind(this);
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/rechargeAmount')
  }

  componentDidMount(){
    const { cardList } = this.props.base;
    // const cardList = JSON.parse(localStorage.getItem('base')).cardList
    let cards = []
    cardList.map((card) => {
      if(card.payMode === 2){     // 只匹配外部支付类型
        cards.push(card)
      }
    })
    this.setState({
      payList: cards
    })
  }

  jumpPay(payUrl){
    this.props.history.push(payUrl)
  }

  render () {
    const quitTime = businessQuitTime.pay.payList;
    const { payList } = this.state;

    return (
      <div>
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <h3 className="business-name">请选择支付方式</h3>
          <div className="pay-list">
            {
              payList.map((pay) => {
                return (
                  <div className={payEnum[pay.id].style} onClick={this.jumpPay.bind(this, payEnum[pay.id].url)}>
                    <p><img src={payEnum[pay.id].img} alt="" /></p>{ pay.name }
                  </div>
                )
              })
            }
          </div>
        </div>
        
        <div className="footer">
          {/*<Button type="primary" size="large" onClick={this.goUpPage} className="left">
          <Icon type="left" />上一步</Button> */}
          <Button type="primary" size="large" onClick={this.goHomePage}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
        </div> 
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { base, cardInfo } = state;
  return {
    base,
    cardInfo
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addCard: (data) => {
      dispatch(cardInfoAction.addCard(data));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayList)