import React, { Component } from 'react'
import Head from '../../components/head'
import { Button, Input, Icon, notification } from 'antd'
import DigitalKeyboard from '../../components/digitalKeyboard'
import businessQuitTime from '../../utils/businessQuitTime'
import { getDepositCost } from '@/utils/request'
import { connect } from 'react-redux'
import * as inpatientAction from '../../store/actions/inpatient'
import './inpatientCost.scss'

/*
 * 住院费用查询
 */
class InpatientCost extends Component {
  constructor(props){
    super(props)
    this.state = {
      inNo: '',
    }
    this.goUpPage = this.goUpPage.bind(this)
    this.goHomePage = this.goHomePage.bind(this)
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/')
  }
  
  getInNo(val){
    this.setState({
      inNo: val
    })
  }

  getPatientNo(){
    const that = this
    const { inNo } = this.state

    if(inNo === ''){
      notification.error({
        message: `错误`,
        description: '请输入住院号',
        duration: 10,
      })
      return false
    }

    that.queryDepositCost(inNo)
  }

  // 获取住院押金余额
  queryDepositCost(inNo){
    const that = this
    
    getDepositCost({
      in_no: inNo
    })
    .then(data => {
      if(data.status){
        let patientInfo = {}
        let od = data.data[0]

        for(let key in od){
          patientInfo[key] = String.trim(od[key])
        }
        that.props.addInpatient({
          inpatientNo: inNo,
          patientInfo,
        })

        that.props.history.push('/hcPatientInfo')
      }
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }


  render() {
    const quitTime = businessQuitTime.inpatientCost.inpatientCost
    const { inNo } = this.state

    return (
      <div>
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <h2 className="crumbs">住院查询 - 请输入您的住院号</h2>
          <div className="hc-cont">
            <Input size="large" placeholder="" value={inNo} id="zyh" className="ins-inpatient" />
            <DigitalKeyboard getKeyboardNumber={this.getInNo.bind(this)} confirmBtn={this.getPatientNo.bind(this)} />
          </div>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    inpatient: state.inpatient,
    cardInfo: state.cardInfo,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addInpatient: (data) => {
      dispatch(inpatientAction.addInpatient(data))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InpatientCost)