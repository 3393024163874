import React, { Component } from 'react'
import Head from '../../components/head'
import { Button, Icon, Table, notification, Spin } from 'antd';
import { connect } from 'react-redux';
import { lisQueryReport, lisReportDetail, lisReportFile, lisPrintFinish, printLisReport } from '@/utils/request'
import businessQuitTime from '../../utils/businessQuitTime'

/*
* Lis 住院打印
*/
class HospitalizedPrintLis extends Component {
  constructor(props){
    super(props);
    this.state = {
      printData: [],
      queryInfo: {
        pageSize: 7
      },
      columns: [
        {
          title: '病历号',
          dataIndex: '病历号',
        }, 
        {
          title: '姓名',
          dataIndex: '姓名',
          align: 'center',
        }, 
        {
          title: '性别',
          dataIndex: '性别',
          align: 'center',
        }, 
        {
          title: '年龄',
          dataIndex: '年龄',
          align: 'center',
        },
        {
          title: '年龄单位',
          dataIndex: '年龄单位',
          align: 'center',
        },
        {
          title: '检验单号',
          dataIndex: '检验单号',
          align: 'center',
        },
        {
          title: '标本类型',
          dataIndex: '标本类型',
          align: 'center',
        },
      ],
      loading: false,
      spinLogin: false,
      selectedPrint: [],
      printLength: 0,

      selectedRows: [],
      reportDetail: {},
      reportDetailFile: {},
      quitTime: 200,
    };
    this.goUpPage = this.goUpPage.bind(this);
    this.goHomePage = this.goHomePage.bind(this);
  }

  componentDidMount(){
    const quitTime = businessQuitTime.printOutpatientResult.printOutpatientResult;
    this.setState({
      quitTime
    })
    this.getPrintData()
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/hospitalizedInfo')
  }

  getPrintData(){
    const that = this
    const { inpatientNo } = this.props.inpatient

    lisQueryReport({
      '病历号': inpatientNo
    })
    .then(od => {
      if(od.status){
        that.setState({
          printData: od.data,
          printLength: od.data.length
        })
      }
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 取明细
  getLisReportDetail(){
    const that = this
    const { selectedRows } = this.state

    if(selectedRows.length === 0){
      notification.error({
        message: `错误`,
        description: '至少选择一个打印项',
        duration: 10,
      })
    }

    let reportArr = []
    selectedRows.forEach(function(row){
      reportArr.push({
        "检验单号": row["检验单号"]
      })
    })

    this.setState({
      spinLogin: true
    }, () => {
      lisReportDetail(reportArr)
      .then(od => {
        if(od.status){
          let filterDetail = {}
          const reportData = od.data

          selectedRows.forEach((report) => {
            let listNum = report['检验单号']
            let detArr = reportData.filter((re) => {
              return re['检验单号'] === listNum
            })
            filterDetail[listNum] = detArr
          })

          that.setState({
            reportDetail: filterDetail
          }, () => {
            that.getLisReportFile(reportArr)
          })
        }
        else{
          that.setState({
            spinLogin: false
          })
        }
      })
      .catch(err => {
        that.setState({
          spinLogin: false
        })
        that.props.history.push({ 
          pathname : '/error',
          query : { messageTitle: '失败', messageContent: err.toString()}
        })
      })
    })
  }

  // 取明细图片文件
  getLisReportFile(reportArr){
    const that = this
    const { selectedRows } = this.state

    lisReportFile(reportArr)
    .then(od => {
      let filterFileDetail = {}
      const reportData = od.data

      selectedRows.forEach((report) => {
        let listNum = report['检验单号'];
        let fileArr = reportData.filter((re) => {
          return re['检验单号'] === listNum
        })
        filterFileDetail[listNum] = fileArr
      })

      that.setState({
        reportDetailFile: filterFileDetail
      }, () => {
        that.sendPrintLisReport();
      })
    })
    .catch(err => {
      that.setState({
        spinLogin: false
      })
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 请求打印
  sendPrintLisReport(){
    const that = this
    const { reportDetail, reportDetailFile, selectedRows } = this.state
    let sendParams = [];

    selectedRows.forEach((row) => {
      let orderNum = row['检验单号']
      let testData = {
        "检验单号": row['检验单号'],
        "姓名": row['姓名'],
        "性别": row['性别'],
        "年龄": row['年龄']+row['年龄单位'],
        "病历号": row['病历号'],
        "科室名称": row['科室名称'],
        "医师名称": row['医师名称'],
        "床位号": row['床位号'],
        "诊断": row['诊断'],
        "就诊类型": row['就诊类型'],
        "标本类型": row['标本类型'],
        "标本号": row['标本号'],
        "检验者代码": row['检验者代码'],
        "审核者代码": row['审核者代码'],
        "检验时间": row['检验时间'],
        "审核时间": row['审核时间'],                 
        "设备号": row['设备号'],
        "明细项目列表": reportDetail[orderNum] || [],
        "图片路径列表": reportDetailFile[orderNum] || [],
      }

      sendParams.push(testData)
    })

    printLisReport(sendParams)
    .then(od => {
      if(od.status){
        notification.success({
          message: `成功`,
          description: '打印完成, 谢谢使用!',
        })
        that.sendLisPrintFinish()
      }
      else{
        that.setState({
          spinLogin: false
        })
      }
    })
    .catch(err => {
      that.setState({
        spinLogin: false
      })
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  sendLisPrintFinish(){
    const that = this
    const { selectedRows } = this.state

    selectedRows.forEach(function(row){
      lisPrintFinish({
        "检验单号": row["检验单号"]
      })
      .then(od => {
        that.setState({
          spinLogin: false,
          quitTime: 3
        })
      })
      .catch(err => {
        that.setState({
          spinLogin: false
        })
        that.props.history.push({ 
          pathname : '/error',
          query : { messageTitle: '失败', messageContent: err.toString()}
        })
      })
    })    
  }

  render() {
    const { columns, printData, loading, queryInfo, printLength, spinLogin, quitTime } = this.state;

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedRows
        })
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      },
    }

    return (
      <div>
        <Spin 
          tip="正在打印，请在取单口领取报告，并核对张数"
          spinning={spinLogin}
          size="large">
          <Head quitTime={quitTime} />
          <div className="context-container clearfix">
            <h2 className="crumbs">住院检验结果查询打印</h2>
            <div>本次共有{ printLength }份报告单待打印!</div>
            <Table pagination={{ pageSize: queryInfo.pageSize }} 
              rowSelection={ rowSelection }
              columns={columns} 
              dataSource={printData} 
              loading={loading}
              className="mt-25" />

            <Button type="primary" size="large" onClick={() => this.getLisReportDetail()} 
              style={{backgroundColor: "#00bd58", border: "1px #00bd58 solid", margin: "20px auto 0", display: "block"}}>打印</Button>
          </div>

          <div className="footer">
            <Button type="primary" size="large" onClick={this.goUpPage} className="left">
              <Icon type="left" />上一步</Button>
            <Button type="primary" size="large" onClick={this.goHomePage}
              style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
              <Icon type="poweroff" theme="outlined" />退 出</Button>
            <div className="address"></div>
          </div>
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    inpatient: state.inpatient,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HospitalizedPrintLis)