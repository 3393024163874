import React, { Component } from 'react'
import Head from '../../components/head'
import { Button, Icon } from 'antd';
import { connect } from 'react-redux';
import { printjzQrcode } from '@/utils/request'
import businessQuitTime from '../../utils/businessQuitTime'

class PrintQrcode extends Component {
  constructor(props){
    super(props);
    this.state = {
      resMessage: '正在打印中，请稍后'
    };
    this.goUpPage = this.goUpPage.bind(this);
    this.goHomePage = this.goHomePage.bind(this);
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/insertPhone')
  }
  
  componentDidMount(){
    this.sendPrintjzQrcode()
  }

  sendPrintjzQrcode(){
    const { hospitalInfo={} } = this.props.base;
    const { userInfo, MR_NO } = this.props.createCard;
    const { COMP_NAME } = userInfo 

    const params = {
      HOS_NAME: hospitalInfo.value,
      P_NAME: COMP_NAME,
      MR_NO
    }
    console.log('打印无卡建档 请求参数：', params)

    printjzQrcode(params)
    .then(od => {
      this.setState({
        resMessage: od.message
      })
    })
    .catch(err => {
      this.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  render() {
    const quitTime = businessQuitTime.testResultsPrint.hospitalizedPrint;
    const { resMessage } = this.state;

    return (
      <div>
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <h2 className="crumbs">就诊二维码打印</h2>
          <h1 className="f-col-11" style={{margin: "150px auto", textAlign: "center", fontSize: '50px'}}>
            {resMessage}
          </h1>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { base, createCard } = state
  return {
    base,
    createCard,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintQrcode)