import React, { Component } from 'react'
import Head from '../../components/head'
import businessQuitTime from '../../utils/businessQuitTime'

// redux
import { connect } from 'react-redux';
import * as cardInfoAction from '../../store/actions/cardInfo'

import { Button, Icon } from 'antd';

/*
 *  患者信息 
 */
class PatientInfo extends Component {
  constructor(props){
    super(props);
    this.state = {
      name: '',
      sex: '',
      age: '',
      idCard: '',
      balance: ''
    };

    this.goPayMentList = this.goPayMentList.bind(this)
    this.goUpPage = this.goUpPage.bind(this);
    this.goHomePage = this.goHomePage.bind(this);
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/outpatientPay')
  }

  componentDidMount(){
    let userInfo = this.props.cardInfo.userInfo;

    this.setState({
      name: userInfo.NAME,
      sex: userInfo.SEX === '2' ? '女' : '男',
      age: userInfo.AGE,
      idCard: userInfo.CERTI_NO
    })
  }

  goPayMentList(){
    this.props.history.push('/payMentList');
  }

  render() {
    const quitTime = businessQuitTime.outpatientPay.patientInfo;
    const { name, sex, age, idCard } = this.state;
    const { balance='0', currentCard={} } = this.props.cardInfo || {};
    // 是否显示余额
    const showBalance = () => {
      if(currentCard.payMode !== 0 && currentCard.id === 'jzcard'){
        return (<p>
          <em>卡余额</em>
          <i>{ balance }元</i>
        </p>)
      }
    }

    return (
      <div>
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <h3 className="business-name">门诊缴费 - 确认患者信息</h3>
          <div className="par-info">
            <p>
              <em>姓名</em>
              <i>{ name }</i>
            </p>
            <p>
              <em>性别</em>
              <i>{ sex }</i>
            </p>
            <p>
              <em>年龄</em>
              <i>{ age }</i>
            </p>
            <p>
              <em>证件号</em>
              <i>{ idCard }</i>
            </p>
            <p>
              <em>卡余额</em>
              <i>{ balance }元</i>
            </p>
            <div>
              <Button type="primary" size="large" onClick={this.goPayMentList} style={{width: "500px", backgroundColor: "#00bd58", border: "1px #00bd58 solid", marginTop: "20px"}}>确 定</Button>
            </div>
          </div>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    cardInfo: state.cardInfo
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addCard: (data) => {
      dispatch(cardInfoAction.addCard(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientInfo)