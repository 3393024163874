import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button, Icon, notification } from 'antd';
import { printData } from '@/utils/request'
import AdminHead from './adminHeader'

import './admin.scss'

export default class PrintData extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <AdminHead />

        <div admin->
          菜单
        </div>

      </React.Fragment>
    )
  }
}