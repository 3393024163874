import React, { Component } from 'react'
import {
  Form, Icon, Input, Button, Checkbox,
} from 'antd';
import { withRouter } from 'react-router-dom';
import DigitalKeyboard from '@/components/digitalKeyboard'
import { connect } from 'react-redux';

import { isAdmin } from '@/utils/request'
import './admin.scss'

class AdminLogin extends Component {
  constructor(props){
    super(props);
    this.state = {
      pwd: ''
    }
    this.goUpPage = this.goUpPage.bind(this);
  }
  
  // componentDidMount(){
  //   const isLogin = localStorage.getItem('adminLogin');
  //   if(isLogin && isLogin === 'true'){
  //     this.props.history.push(`/inserOrderId`);
  //   }
  // }

  // 数字键盘
  getKeyboardNumber(pwd){
    this.setState({
      pwd
    })
  }

  goUpPage(){
    this.props.history.push('/')
  }

  successBtn(){
    const that = this;
    const baseInfo = JSON.parse(localStorage.getItem('base'));
    const { deviceId } = baseInfo;
    const { pwd } = this.state;

    isAdmin({
      deviceId,
      password: pwd, 
    })
    .then(od => {
      if(od.status){
        localStorage.setItem('adminLogin', true);
        that.props.history.push(`/inserOrderId`);
      }
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  render() {
    return (
      <div className="login-cont">
        <div className="login-box">
          <h1>自助机管理员后台登录</h1>
          <div className="login-context">
            <div className="l-l">
              <p style={{marginTop: "20px"}}>管理密码：<Input.Password size="large" placeholder="" value={this.state.pwd} /></p>
            </div>
            <div className="l-r">
              <DigitalKeyboard getKeyboardNumber={this.getKeyboardNumber.bind(this)} confirmBtn={this.successBtn.bind(this)} />
            </div>
          </div>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage} style={{ backgroundColor: "#61b0ef", "float": "right", border: "1px #61b0ef solid"}}>返回主界面</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    base: state.base
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addBase: (data) => {
      dispatch(baseAction.addBase(data));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminLogin));