const time = 200;
const payTime = 310;
const lisPintTime = 60;

const businessQuitTime = {

  // 建档发卡
  createArchives: {
    createArchives: time,
    readIdCard: time,
    confirmIdCard: time,
    insertPhone: time,
    grantCard: time,
    bankUserInfo: time,
  },

  // 就诊卡充值
  medicalCardRecharge: {
    medicalCardRecharge: time,
    confirmIdentityInfo: time,
    rechargeAmount: time,
    readCard: time,
  },

  // 预约挂号
  appointmentRegister: {
    appointmentRegister: time,
    readCard: time,
  },

  // 今日挂号
  todayRegister: {
    todayRegister: time,
    trPatientInfo: time,
    todaySelectDoctor: time,
    todaySelectDept: time,
    todaySelectDate: time,
    readCard: time,
  },

  // 当日取号
  todayTakeNumber:{
    todayTakeNumber: time,
    ttPatientInfo: time,
    takeNumList: time, 
    readCard: time,
  },

  // 门诊费用查询
  outpatientCost: {
    outpatientCost: time,
    ocPatientInfo: time,
    selectDate: time,
    costList: time,
    readCard: time,
  },

  // 门诊缴费
  outpatientPay: {
    outpatientPay: time,
    patientInfo: time,
    payMentList: time,
    readCard: time,
    outpatientPayDetail: time,
  },

  // 药品价格查询
  drugPrice: {
    drugPrice: time,
    readCard: time,
  },

  // 住院费用查询
  inpatientCost: {
    inpatientCost: time,
    hcPatientInfo: time,
    inpatientList: time,
  },

  // 住院押金缴纳
  inpatientPay: {
    inpatientPay: time,
    hpPatientInfo: time,
    inpatientDeposit: time,
  },

  // 就诊指南
  consultation: {
    consultation: time,
  },

  // 特色诊疗 
  treatment: {
    treatment: time,
  },

  // 专家介绍 
  expertIntro: {
    expertIntro: time,
  },

  // 科室介绍
  departmentIntro: {
    departmentIntro: time,
  },

  // 门诊检验结果打印
  printOutpatientResult: {
    printOutpatientResult: lisPintTime,
    printOutPatientECGReport: time,
  },

  // 住院检验结果打印
  printHospitalizationResult: {
    printHospitalizationResult: time,
    readCard: time,
  },

  // 检查结果查询打印
  inspectResultsPrint: {
    inspectResultsPrint: time,
  },

  // 支付
  pay: {
    payList: time,
    wxPay: payTime,
    zfbPay: payTime,
    yhkPay: payTime,
  },

  // 打印凭条
  print: {
    payPrint: time,
    rechargePrint: time,
    printInvoice: time
  },

  testResultsPrint: {
    testResultsPrint: time,
    outpatientInfo: time,
    outpatientPrint: time,
    insertHospitalized: time,
    hospitalizedInfo: time,
    hospitalizedPrint: time,
  },

  // 微服务网站
  microService: {
    departmentIntro: time,
    departmentDetail: time,
  },

  admin: {
    readCard: time,
  },

  // 404、成功、失败组件
  notFoundPage: time,
  successMessage: time,
  errorMessage: time,
}

export default businessQuitTime;