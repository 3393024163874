import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button, Icon, notification, Table } from 'antd';
import AdminHead from '../adminHeader'
import { printData } from '@/utils/request';

import { connect } from 'react-redux';
import * as paymentAction from '../../../store/actions/payment'
import * as adminAction from '@/store/actions/admin'
import '../admin.scss'

/*
* 补打打印单
*/
class PrintData extends Component {
  constructor(props){
    super(props);
    this.state = {
      payData: [],
      inspectData: [],
      loading: false,
      queryInfo: {
        pageSize: 5
      },
      columns: [
        {
          title: '订单编号',
          width: 200,
          dataIndex: 'orderId',
        }, 
        {
          title: '费用说明标题',
          dataIndex: 'subject',
        }, 
        {
          title: '费用说明描述',
          dataIndex: 'desc',
        }, 
        {
          title: '费用',
          width: 120,
          dataIndex: 'fee',
          render: (text, record) => {
            return <span>{text}元</span>
          }
        },
        // {
        //   title: '支付平台流水号',
        //   dataIndex: 'transactionId',
        // }, 
        {
          title: '创建时间',
          width: 150,
          dataIndex: 'modifiedAt',
        },
        {
          title: '操作',
          key: 'action',
          render: (text, record) => (
            <span>
              <Button type="primary" onClick={this.print.bind(this, record)} size="small">打印</Button>
            </span>
          ),
        }
      ],
      hospitalCostData: []
    };
  }
  
  componentDidMount () {
    const orderId = this.props.match.params.orderId || '';
    this.getPrintData(orderId);
    // this.getPrintData(1);
  }

  getPrintData(orderId){
    const that = this;

    that.setState({
        loading: true
      }, 
      () => { 
        printData({
          orderId
        })
        .then(od => {
          if(od.status){
            that.setState({
              payData: od.data,
              loading: false,
            })
          }
        })
        .catch(err => {
          that.props.history.push({ 
            pathname : '/error',
            query : { messageTitle: '失败', messageContent: err.toString()}
          })
        })
      }
    )
  }

  print(record){
    const { orderId, fee, platform } = record;
    const { admin } = this.props;
    
    let businessType = 'reprint';
    let paymentObj = {
      orderId,
      amount: fee,
      payType: platform === 'AliPay' ? '支付宝' : '微信支付'
    }

    // if(record.subject.indexOf('就诊卡充值') > 0){
    //   businessType = 'recharge';
    // }
    // if(record.subject.indexOf('住院费充值') > 0){
    //   businessType = 'inpatient';
    // }
    let objReprint = {
      reprint: record,
    }

    if(admin) {
      objReprint = Object.assign(admin, objReprint);
    }
    this.props.addReprint(objReprint);
    
    Object.assign(paymentObj, {businessType});
    this.props.addPayment(paymentObj)
    this.props.history.push('/payPrint');
  }
  
  render() {
    const { columns, payData, inspectData, queryInfo, loading } = this.state;

    return (
      <React.Fragment>
        <AdminHead />

        <div className="adm-content">
          <h1>补打打印列表</h1>
          {/**  <div className="print-tab-btn">
            <span className="payment-slip" onClick={() => this.getPrintData(1)}>查看缴费单</span>
            <span className="check-list" onClick={() => this.getPrintData(2)}>查看检查检验单</span>
          </div>*/}
          <Table pagination={{ pageSize: queryInfo.pageSize }} 
            columns={columns} 
            loading={loading}
            dataSource={payData}
            className="mt-25"  />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    cardInfo: state.cardInfo,
    payment: state.payment,
    admin: state.admin,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addPayment: (data) => {
      dispatch(paymentAction.addPayment(data))
    },
    addReprint: (data) => {
      dispatch(adminAction.addReprint(data))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintData)