import React, { Component } from 'react'
import { notification } from 'antd'
import './index.scss'

/*
* 模拟数字键盘
*/
const codeMap = {
  48: '0',
  49: '1',
  50: '2',
  51: '3',
  52: '4',
  53: '5',
  54: '6',
  55: '7',
  56: '8',
  57: '9',
  96: '0',
  97: '1',
  98: '2',
  99: '3',
  100: '4',
  101: '5',
  102: '6',
  103: '7',
  104: '8',
  105: '9',
}
export default class DigitalKeyboard extends Component {
  constructor(props){
    super(props);
    this.state = {
      totalNum: '',
      disableInput: false
    }
    this.deleteNumber = this.deleteNumber.bind(this)
    this.confirmBtn = this.confirmBtn.bind(this)
    this.onListen = this.onListen.bind(this)
  }

  componentDidMount(){
    this.onListenKeyboard();
    let disableStatus = this.props.disableInput
    disableStatus = disableStatus ? true : false
    this.setState({
      disableInput: disableStatus || false
    })
  }

  componentWillUnmount(){
    const that = this
    document.removeEventListener('keypress', that.onListen)
  }

  onListenKeyboard(){
    const that = this
    document.addEventListener('keypress', that.onListen)
  }

  onListen(evt){
    const that = this
    if(evt.keyCode === 13 || evt.keyCode === 108){
      that.confirmBtn();
    }

    if(evt.keyCode === 8){
      that.deleteNumber();
    }

    if((evt.keyCode >= 48 && evt.keyCode <= 57) 
        || (evt.keyCode >= 96 && evt.keyCode <= 105) 
        || evt.keyCode === 102 
        || evt.keyCode === 110){
      that.insertNumber(codeMap[evt.keyCode])
    }
  }

  insertNumber(num){
    const { disableInput } = this.state

    // 禁用不允许输入
    if(disableInput){
      return false
    }

    let oldNum = this.state.totalNum
    this.setState({
      totalNum: oldNum + num
    }, () => {
      this.sendMessage();
    })
  }
  
  clearInsertNumber(num){
    this.setState({
      totalNum: num
    }, () => {
      this.sendMessage();
    })
  }

  deleteNumber(){
    const { disableInput } = this.state
    let len = this.state.totalNum.length;

    // 禁用不允许输入
    if(disableInput){
      return false
    }
    
    if(len > 0){
      let newNum = this.state.totalNum.substring(0, len-1)
      this.setState({
        totalNum: newNum
      }, () => {
        this.sendMessage()
      })
    }
  }

  regNum(obj){
    const reg = new RegExp(/^(-)?[0-9]+(.[0-9]+)?$/)
    return reg.test(obj)
  }
  
  confirmBtn(){
    const { totalNum } = this.state;
    
    // if(!this.regNum(totalNum)){
    //   notification.error({
    //     message: '提示',
    //     description: '请输入正确数字',
    //     duration: 10,
    //   });
    //   return false
    // }
    this.props.confirmBtn();
  }

  sendMessage(){
    this.props.getKeyboardNumber(this.state.totalNum)
  }

  render() {
    const { addChars } = this.props
    const { disableInput } = this.state
    const showAddChars = () => {
      let chars = []
      if(addChars && addChars.length > 0){
        addChars.map((char) => {
          chars.push(<li onClick={this.insertNumber.bind(this, char)}>{char}</li>)
        })
      }

      return chars
    }
    console.log('disableInput', disableInput)

    return (
      <div>
        <div className="kb-container clearfix">
          <div className="kb-col-l">
            <ul>
              <li onClick={this.insertNumber.bind(this, '1')} className={ disableInput ? "disInput" : ""}>1</li>
              <li onClick={this.insertNumber.bind(this, '2')} className={ disableInput ? "disInput" : ""}>2</li>
              <li onClick={this.insertNumber.bind(this, '3')} className={ disableInput ? "disInput" : ""}>3</li>
              <li onClick={this.insertNumber.bind(this, '4')} className={ disableInput ? "disInput" : ""}>4</li>
              <li onClick={this.insertNumber.bind(this, '5')} className={ disableInput ? "disInput" : ""}>5</li>
              <li onClick={this.insertNumber.bind(this, '6')} className={ disableInput ? "disInput" : ""}>6</li>
              <li onClick={this.insertNumber.bind(this, '7')} className={ disableInput ? "disInput" : ""}>7</li>
              <li onClick={this.insertNumber.bind(this, '8')} className={ disableInput ? "disInput" : ""}>8</li>
              <li onClick={this.insertNumber.bind(this, '9')} className={ disableInput ? "disInput" : ""}>9</li>
              <li onClick={this.insertNumber.bind(this, '0')} className={ disableInput ? "disInput" : ""}>0</li>
              { showAddChars() }
            </ul>
          </div>
          <div className="kb-col-r">
            <ul>
              <li onClick={this.deleteNumber} className={ disableInput ? "disInput" : "back-btn"}>退格</li>
              <li className="success-btn" onClick={this.confirmBtn}>确认</li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}