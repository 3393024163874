/* 存储、删除读取身份证 */
export const addCardData = (data) => {
  return {
    type: "ADD_CARD_DATA",
    data
  }
}

export const clearCardData = (data) => {
  return {
    type: "CLEAR_CARD_DATA",
    data
  }
}