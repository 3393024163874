import React, { Component } from 'react'
import Head from '../../components/head'
import { Button, Icon, notification } from 'antd';
import { connect } from 'react-redux';
import { printLisResult } from '@/utils/request'
import businessQuitTime from '../../utils/businessQuitTime'

class HospitalizedPrint extends Component {
  constructor(props){
    super(props);
    this.state = {
      resMessage: '正在打印中，请稍后'
    };
    this.goUpPage = this.goUpPage.bind(this);
    this.goHomePage = this.goHomePage.bind(this);
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/printHospitalizationResult')
  }

  componentDidMount(){
    this.sendPrintResult();
  }

  sendPrintResult(){
    let pat_no = this.props.inpatient.inpatientNo;

    const params = {
      para_type: '住院',
      pat_no,
    }
    printLisResult(params)
    .then(od => {
      if(od.status){
        notification.success({
          message: `提示`,
          description: od.message,
        });
      }
      this.setState({
        resMessage: od.message
      })
    })
    .catch(err => {
      this.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  render() {
    const quitTime = businessQuitTime.testResultsPrint.hospitalizedPrint;
    const { resMessage } = this.state;

    return (
      <div>
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <h2 className="crumbs">检验结果查询打印</h2>
          <h1 className="f-col-11" style={{margin: "150px auto", textAlign: "center", fontSize: '50px'}}>
            {resMessage}
          </h1>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    cardInfo: state.cardInfo,
    inpatient: state.inpatient,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HospitalizedPrint)