import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Icon } from 'antd';
import PropTypes from 'prop-types'
import './admin.scss'

class AdminHeader extends Component {
  constructor(props, context){
    super(props, context);
    this.state = {};
  }
  
  outLogin(){
    localStorage.setItem('adminLogin', false);
    this.context.router.history.push('/')
  }

  render() {
    return (
      <div className="adm-head">
        <h1>自助机管理员后台系统</h1>
        <div className="adm-out" onClick={this.outLogin.bind(this)}>
          <Icon type="poweroff" rotate={180} style={{marginRight: "10px"}} />退出管理
        </div>
      </div>
    )
  }
}

AdminHeader.contextTypes = {
  router: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {
  return {
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

export default AdminHeader