const admin = (state = {}, action) => {
  switch(action.type){
    case 'ADD_REPRINT':
      return action.data;

    case 'CLEAR_ADMIN':
      return null;

    default:
      return state;
  }
}

export default admin