import React, { Component } from 'react'
import Head from '../../components/head'
import { Button, Icon, notification, Modal } from 'antd'
import businessQuitTime from '../../utils/businessQuitTime'
import { regToday, getHisBalance, getRegistrationsByMrNo } from '@/utils/request'
import { connect } from 'react-redux'
import * as registerAction from '../../store/actions/register'
import * as payMentAction from '../../store/actions/payment'

class TrPatientInfo extends Component {
  constructor(props){
    super(props)

    this.state = {
      cardPayDialog: false,
      balance: 0,
      amount: 0,
    }
    this.goUpPage = this.goUpPage.bind(this)
    this.goHomePage = this.goHomePage.bind(this)
    this.payRegister = this.payRegister.bind(this)
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/todaySelectDoctor')
  }

  payRegister(){
    const { doctorInfo, regBase } = this.props.register;
    const typeId = doctorInfo.typeId-1;
    // const feeType = regBase.feeType[typeId];

    let fee = ''
    let feeItem = regBase.feeType.filter((fee) => {
      return fee.id == doctorInfo.typeId
    })
    if(feeItem){
      fee = feeItem[0].fee
    }

    const payMentObj = Object.assign(this.props.payMent || {}, {
      businessName: '当日挂号',
      businessType: 'register',
      printTitle: '',
      amount: fee,
    })
    this.props.addPayment(payMentObj)

    const amount = parseFloat(fee)

    this.queryRegistrationsByMrNo(amount)
  }

  // 获取数据
  queryRegistrationsByMrNo(amount){
    const that = this;
    const { cardInfo, register } = this.props;

    getRegistrationsByMrNo({
      MR_NO: cardInfo.mr_no,
    })
    .then(od => {
      if(od.status){
        let dataList = od.data

        // 先判断如果已挂成功的号不能允许重复挂
        // 取出号源所有的id，存到数组中
        let scheduleIds = []
        register.schedules.map((schedule) => {
          scheduleIds.push(schedule.id)
        })
        
        // 判断返回的已挂号的号源id，是否存在号源列表中
        let isRegistered = dataList.some((oid) => {
          return scheduleIds.some((sId) => {
            return  sId == oid.scheduleId
          })
        })

        if(isRegistered){
          notification.error({
            message: `错误`,
            description: '已存在相同挂号记录，不允许重复挂号',
            duration: 10,
          })
          return false
        }

        if(amount === 0){  // 0元号直接打印
          this.registerPay(amount)
        }
        else{
          this.queryBalance(amount)
        }
      }
      that.setState({
        loading: false
      })
    })
    .catch(err => {
      that.setState({
        loading: false
      })
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 获取卡余额
  queryBalance(amount){
    let that = this
    let mr_no = this.props.cardInfo.mr_no

    getHisBalance({
      mr_no,
      pwd: '',
    })
    .then(od => {
      if(od.status){
        let balance = od.status ? od.data[0].BALANCE : 0;
        if(balance > amount){
          this.setState({
            balance,
            amount,
            cardPayDialog: true,
          })
        }    
        else{
          this.props.history.push('/payList')
        }
      }
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
      that.props.clearCard()
    })
  }

  // 就诊卡支付
  sendCardPay(){
    const that = this
    const { mr_no, currentCard } = this.props.cardInfo
    const { doctorInfo } = this.props.register
    const base = JSON.parse(localStorage.getItem('base'))
    const  { deviceId } = base

    regToday({
      MR_NO: mr_no,
      scheduleId: doctorInfo.id,
      deviceId,
      paidType: 4,
      cardTypeCode: currentCard.id,
      cardTypeName: currentCard.name,
    })
    .then(od => {
      if(od.status){
        notification.success({
          message: `成功`,
          description: od.message,
        })

        let payMentObj = Object.assign(this.props.payMent || {}, {
          "businessName": "",
          "businessType": "takeNo",
          "printTitle": "",
          "amount": "",
          "takeNo": od.data,
        })
        this.props.addPayment(payMentObj)
        that.props.history.push('/payPrint');
      }
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 0元也需要调挂号接口
  registerPay(){
    const that = this
    const { mr_no, currentCard } = this.props.cardInfo
    const { doctorInfo } = this.props.register
    const base = JSON.parse(localStorage.getItem('base'))
    const  { deviceId } = base

    regToday({
      MR_NO: mr_no,
      scheduleId: doctorInfo.id,
      deviceId,
      cardTypeCode: currentCard.id,
      cardTypeName: currentCard.name,
    })
    .then(od => {
      if(od.status){
        notification.success({
          message: `成功`,
          description: od.message,
        })

        let payMentObj = Object.assign(this.props.payMent || {}, {
          "businessName": "",
          "businessType": "takeNo",
          "printTitle": "",
          "amount": "",
          "takeNo": od.data,
        })
        this.props.addPayment(payMentObj)
        that.props.history.push('/payPrint');
      }
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  render() {
    const quitTime = businessQuitTime.todayRegister.trPatientInfo
    const { balance, amount, cardPayDialog } = this.state
    const { userInfo='' } = this.props.cardInfo || {}
    const { doctorInfo, selectedDate, selectedDept, regBase } = this.props.register
    const typeId = doctorInfo.typeId-1
    const doctorName = doctorInfo.doctor ? doctorInfo.doctor.name : ''

    let fee = ''
    const feeType = regBase.feeType[typeId]
    let feeItem = regBase.feeType.filter((fee) => {
      return fee.id == doctorInfo.typeId
    })
    if(feeItem){
      fee = feeItem[0].fee
    }

    return (
      <div>
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <h3 className="business-name">当日挂号 - 确认挂号信息</h3>
          <div className="par-info">
            <p>主治医生: <span>{ doctorName }</span></p>
            <p>科室: <span>{ selectedDept.name }</span></p>
            <p>就诊时间: <span>{ selectedDate } { regBase.time[doctorInfo.acceptsTimeBucket] }</span></p>
            <p>就诊人: <span>{ userInfo.NAME }</span></p>
            <p>费用: <span>{ fee }元</span></p>
            <Button type="primary" size="large" onClick={this.payRegister} style={{width: '100%', backgroundColor: "#00bd58", border: "1px #00bd58 solid", marginTop: "20px"}}>确认挂号</Button>
          </div>
        </div>

        <Modal
          title="提示"
          visible={cardPayDialog}
          onOk={() => this.sendCardPay()}
          onCancel={() => this.setState({ cardPayDialog: false })}
          cancelText="取消"
          okText="确定">
          <p>请确认是否支付：</p>
          <p>就诊卡余额：{balance}元</p>
          <p>支付金额：{amount}元</p>
        </Modal>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { register, cardInfo, payMent, base } = state
  return {
    register,
    cardInfo,
    payMent,
    base
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addRegister: (data) => {
      dispatch(registerAction.addRegister(data))
    },
    addPayment: (data) => {
      dispatch(payMentAction.addPayment(data))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrPatientInfo)