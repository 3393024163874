import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Head from '../../components/head/'
import { Button, Input, Table, notification } from 'antd';
import { connect } from 'react-redux';
import * as cardInfoAction from '../../store/actions/cardInfo'
import * as baseAction from '../../store/actions/base'
import businessQuitTime from '../../utils/businessQuitTime'
import LetterKeyboard from '../../components/LetterKeyboard'
import { queryItems, queryItemPrice } from '@/utils/request';
import './index.scss'
import leftBtn from "../../assets/icons/DoubleLeftOutlined.svg";

// 磁县code
const cixanHospCode = '13010033'

let cixanColumns = [
  {
    title: '项目编码',
    dataIndex: 'M_CODE',
  },
  {
    title: '项目名称',
    dataIndex: 'M_NAME',
  },
  {
    title: '医保类别',
    dataIndex: 'INSUR_LEVEL_NAME',
  },
  {
    title: '单位',
    dataIndex: 'M_UNIT',
  },
  {
    title: '价格',
    dataIndex: 'M_R_PRICE',
  }
];

let pubColumns = [
  {
    title: '项目名称',
    dataIndex: '项目名称',
  },
  {
    title: '项目单价',
    dataIndex: '项目单价',
    align: 'center',
    width: '120px'
  },
  {
    title: '门诊类别',
    dataIndex: '门诊类别',
    align: 'center',
    width: '120px'
  },
  {
    title: '住院类别',
    dataIndex: '住院类别',
    align: 'center',
    width: '120px'
  },
  {
    title: '拼音缩写',
    dataIndex: '拼音缩写',
  },
  {
    title: '项目单位',
    dataIndex: '项目单位',
    align: 'center',
    width: '120px'
  },
]

/*
 * 收费项目查询
 */
class QueryPaymentItems extends Component {
  constructor(props){
    super(props);
    this.state = {
      paymentItemsName: '',
      paymentItemsList: [],
      pageSize: 7,
      loading: false,
      columns: [
        {
          title: '项目名称',
          dataIndex: '项目名称',
        },
        {
          title: '项目单价',
          dataIndex: '项目单价',
          align: 'center',
          width: '120px'
        },
        {
          title: '门诊类别',
          dataIndex: '门诊类别',
          align: 'center',
          width: '120px'
        },
        {
          title: '住院类别',
          dataIndex: '住院类别',
          align: 'center',
          width: '120px'
        },
        {
          title: '拼音缩写',
          dataIndex: '拼音缩写',
        },
        {
          title: '项目单位',
          dataIndex: '项目单位',
          align: 'center',
          width: '120px'
        },
      ],
    };

    this.goUpPage = this.goUpPage.bind(this);
    this.goHomePage = this.goHomePage.bind(this);
  }

  componentDidMount(){
    const { base = {} } = this.props
    const { hospitalInfo={} }= base
    this.setState({
      hospCode: hospitalInfo.hospCode,
      columns: hospitalInfo.hospCode === cixanHospCode ? cixanColumns: pubColumns       // 区分磁县
    })
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/')
  }

  insertPaymentItems(name) {
    this.setState({
      paymentItemsName: name
    })
  }

  searchPaymentItems() {
    const that = this
    const { paymentItemsName, hospCode } = this.state

    if(paymentItemsName === ''){
      notification.warning({
        message: '提示',
        description: '请输入要查询的关键词缩写字母',
        duration: 10,
      })
      return ;
    }

    if(hospCode === cixanHospCode){
      queryItemPrice({
        ITEMNAME: paymentItemsName.toLowerCase(),
      })
      .then(od => {
        if(od.status){
          that.setState({
            paymentItemsList: od.data,
          })
        }
      })
      .catch(err => {
        that.props.history.push({
          pathname : '/error',
          query : { messageTitle: '失败', messageContent: err.toString()}
        })
      })
    }
    else{
      queryItems({
        py: paymentItemsName,
        pagesize: 10000,
        current: 0
      })
      .then(od => {
        if(od.status){
          that.setState({
            paymentItemsList: od.data.data,
          })
        }
      })
      .catch(err => {
        that.props.history.push({
          pathname : '/error',
          query : { messageTitle: '失败', messageContent: err.toString()}
        })
      })
    }
  }

  render() {
    const quitTime = businessQuitTime.drugPrice.drugPrice
    const { paymentItemsList, columns, pageSize, loading } = this.state

    return (
      <div>
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <h2 className="crumbs">收费项目查询</h2>
          <div className="c-content-scroll">
            <div className="drugs-box">
              <div style={{width: "650px", overflow: "hidden", margin: "0 auto"}}>
                <div style={{ width:"500px", float: "left" }}><Input size="large" placeholder="" value={this.state.paymentItemsName} /></div>
                <div style={{ marginLeft: "20px", float: "left" }}><Button type="primary" size="large" onClick={() => this.searchPaymentItems()} className="left">查 询</Button></div>
              </div>
              <div className="pt-20">
                <LetterKeyboard letterText={this.insertPaymentItems.bind(this)} />
              </div>
            </div>

            <div className="drugs-table">
              <Table pagination={{ pageSize }}
                columns={columns}
                dataSource={paymentItemsList}
                loading={loading}
                className="mt-25"  />
            </div>
          </div>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage} className="left"
                  style={{ backgroundColor: "#CEE2EE", border: "1px #B3D5E1 solid", color: "#514F4F"}}
          >
            <img src={leftBtn} width={28} /> 上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage}
                  style={{ "backgroundColor": "#bfdfe9", color: "#8D2829", border: "1px #3976A9 solid", "float": "right"}}>
            退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { base, cardInfo } = state;
  return {
    base,
    cardInfo,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addBase: (data) => {
      dispatch(baseAction.addBase(data));
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(QueryPaymentItems)
