/* 
 * 存储读取诊卡的信息，退卡后清空数据
 * {
 *   card_no: '',      // 就诊卡号
 *   cardKey: '',      // keyb
 *   userInfo: {       // 患者信息
 *      
 *   }
 * }
 */
export const addCard = (data) => {
  return {
    type: "ADD_CARD",
    data
  }
}

export const clearCard = (data) => {
  return {
    type: "CLEAR_CARD",
    data
  }
}
