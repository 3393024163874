import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { sendExitCard } from '@/utils/request'
import { connect } from "react-redux";
import * as cardInfoAction from '../../store/actions/cardInfo'
import logo from '../../assets/img/logo.png'
import  './head.scss'

/*
 * 公共头用于统一计时退出
 */
class Head extends Component {
  constructor(props){
    super(props);
    this.state = {
      date: '',
      quitDateTime: 0,
      timer: null,
      quitTimer: null,
      defaultTime: ''
    }
  }

  componentDidMount(){
    clearInterval(this.state.timer);
    clearInterval(this.state.quitTimer);

    this.setState({
      date: this.formateDateTime()
    })

    this.startDateTime(this.props.quitTime);
    // this.formateDateTime()

    if(this.props.quitTime){
      this.setState({
        quitDateTime: this.props.quitTime,
        defaultTime: this.props.quitTime
      })
      this.startQuitTime(this.props.quitTime)
    }
  }

  componentDidUpdate(prevProps){
    const { defaultTime } = this.state
    // console.log('header Time:', prevProps.quitTime, defaultTime)
    if(prevProps.quitTime){
      if(prevProps.quitTime !== defaultTime){
        clearInterval(this.state.quitTimer);
        this.setState({
          quitDateTime: this.props.quitTime,
          defaultTime: this.props.quitTime
        })
        this.startQuitTime(this.props.quitTime)
      }
    }
  }

  componentWillUnmount(){
    clearInterval(this.state.timer);
    clearInterval(this.state.quitTimer);

    this.setState({
      quitDateTime: 0
    })
  }

  // 倒计时
  startQuitTime(timeNum){
    let that = this;
    timeNum = Number(timeNum);

    this.state.quitTimer = setInterval(function(){
      timeNum = timeNum -1;
      if(timeNum !== 0){
        that.setState({
          quitDateTime: timeNum
        })
      }
      else{
        clearInterval(that.state.quitTimer);
        that.signOutCard();
        that.context.router.history.push('/')
      }   
    }, 1000)
  }

  // 日期
  startDateTime(){
    let that = this;

    this.state.timer = setInterval(function(){
      let time = that.formateDateTime();
      that.setState({
        date: time
      })
    }, 1000)
  }

  formateDateTime(){
    const weekArr= ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
    const od = new Date(),
          year = od.getFullYear(),
          method = od.getMonth()+1,
          day = od.getDate(),
          week = weekArr[od.getDay()],
          hours = od.getHours() < 10 ? `0${od.getHours()}` : od.getHours(),
          minute = od.getMinutes()< 10 ? `0${od.getMinutes()}` : od.getMinutes(),
          second = od.getSeconds() < 10 ? `0${od.getSeconds()}` : od.getSeconds();

    return `${year}年${method}月${day}日 ${hours}:${minute}:${second} ${week}`
  }

  // formateDateTime(){
  //   const weekArr= ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
  //   const od = new Date(),
  //         year = od.getFullYear(),
  //         method = od.getMonth()+1,
  //         day = od.getDate(),
  //         week = weekArr[od.getDay()];

  //   this.setState({
  //     date: `${year}年${method}月${day} ${week}`
  //   })
  // }

  // 退卡
  signOutCard(){
    let that = this;
    const { currentCard={} } = this.props.cardInfo || {};

    sendExitCard({
      RESOURSE_ID: currentCard.id
    })
    .then(od => {
      that.props.clearCardInfoStore();
      that.context.router.history.push('/')
    })
    .catch(err => {
      that.props.clearCardInfoStore()
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
    that.props.clearCardInfoStore();
  }

  render() {
    const { date, quitDateTime } = this.state;
    let base = {};
    let deviceName = '';
    
    if(localStorage.getItem('base')){
      base = JSON.parse(localStorage.getItem('base'));
      deviceName = base.deviceName;
    }

    return (
      <div className="header">
        {/*  <h1 className="h-logo">香河安心卫康医院 - 自助服务机</h1> */}
        <h1 className="h-logo">
          <img src={logo} alt="" />
        </h1>
        <div className="h-date">
          <div className="mt-20">倒计时<span className="quit-time">{ quitDateTime }</span>秒</div>
          {/* <div className="mt-20">自助机编号: { deviceName }</div> */}
          <div className="h-text">{ date }</div>
        </div>
      </div>
    )
  }
}

Head.contextTypes = {
  router: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {
  const { base, cardInfo } = state;
  return {
    base,
    cardInfo
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    clearCardInfoStore: (data) => {
      dispatch(cardInfoAction.clearCard());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Head)