import React, { Component } from 'react'
import { Button, notification, Icon, Spin } from 'antd';
import Head from '../head/'
import businessQuitTime from '../../utils/businessQuitTime'
import {
  getGenerateQR,
  getQueryOrder,
  sendHisRecharge,
  depositRecharge,
  sendPayment,
  regToday,
  getPayDetails,
  printPayBill,
  refund,
  takeNo,
  insuSettle,
  printPayBillInsu
} from '@/utils/request'
import QRCode from 'qrcode.react';
import { connect } from 'react-redux';
import * as cardInfoAction from '../../store/actions/cardInfo'
import * as createCardAction from '../../store/actions/createCard'
import * as rechargeAction from '../../store/actions/recharge'
import * as paymentAction from '../../store/actions/payment'
import * as outpatientAction from '../../store/actions/outpatient'
import MedicalInsurPassword from '../MedicalInsurPassword'
import { getSession, payConfs } from '@/utils/common'
import './pay.scss'

/*
 * 农商聚合支付（用户操作流程与微信一样）
 */
class NsjhPay extends Component {
  constructor(props){
    super(props);
    this.state = {
      qr: '',
      orderId: '',
      transactionId: '',
      butDisabled: true,
      subject: '',
      desc: '',
      paySource: '',
      spinLoading: false,
      insPasswordCount: 1,  // 输入医保密码次数
    }
  }

  componentDidMount(){
    this.queryGenerateQR()

    const { cardInfo, payment } = this.props;
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/payList')
  }

  // 取支付二维码
  queryGenerateQR(){
    const that = this;
    const { payment, inpatient, cardInfo, createCard } = this.props;
    const base = JSON.parse(localStorage.getItem('base'));

    if(payment.amount === ''){
      this.props.history.push({
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: '充值金额不能为空'}
      })
      return false;
    }

    let userId = ''
    let addParam = {}
    let busiName = ''
    const hospitalName = base.hospitalInfo.value
    const cardFee = base.hospitalInfo.cardFee
    const fee = payment.amount

    switch(payment.businessType){
      case 'inpatient':
        userId = inpatient.inpatientNo;
        addParam = {
          subject: `${hospitalName}住院费充值`,
          desc: `${hospitalName}住院费充值，￥${fee}元。`
        }
        busiName = '住院押金'
        break;

      case 'recharge':    // 卡充值
        userId = cardInfo.mr_no;
        const cardName = getSession('rechargeName')
        addParam = {
          subject: `${hospitalName}${cardName}充值`,
          desc: `${hospitalName}${cardName}充值，￥${fee}元。`
        }
        busiName = '卡充值'
        break;

      case 'createCard':
        userId = createCard.userInfo.NUMBER;
        addParam = {
          subject: `${hospitalName}就诊卡建卡`,
          desc: `${hospitalName}就诊卡建卡，收费￥${cardFee}元。`
        }
        busiName = '卡充值'
        break;

      case 'payBill':
        userId = cardInfo.mr_no;
        addParam = {
          subject: `${hospitalName}门诊缴费`,
          desc: `${hospitalName}门诊缴费，收费￥${fee}元。`
        }
        busiName = '门诊收费'
        break;

      case 'register':
        userId = cardInfo.mr_no;
        addParam = {
          subject: `${hospitalName}挂号缴费`,
          desc: `${hospitalName}挂号缴费，收费￥${fee}元。`
        }
        busiName = '门诊挂号'
        break;

      case 'takeNo':
        userId = cardInfo.mr_no;
        addParam = {
          subject: `${hospitalName}取号缴费`,
          desc: `${hospitalName}取号缴费，收费￥${fee}元。`
        }
        busiName = '门诊挂号'
        break;

      default:  break;
    }

    // 取住院/门诊患者全名
    const patName = payment.businessType === 'inpatient' ? inpatient.patientInfo.COMP_NAME : cardInfo.userInfo.COMP_NAME

    let params = {
      platform: payConfs.nshPay.platForm,
      subject: '',
      desc: '',
      deviceId: base.deviceId,
      fee,
      userId,
      patName,
      payFor: busiName,
    }
    console.log('getGenerateQR req：', JSON.stringify(params))

    that.setState({
      subject: addParam.subject,
      desc: addParam.desc,
    }, function(){
      Object.assign(params, addParam);
      getGenerateQR(params)
      .then(od => {
        if(od.status){
          if(!od.data.qr){
            notification.error({
              message: `错误`,
              description: '二维码获取失败',
              duration: 10,
            });

            that.setState({
              qr: od.data[0].QR_CODE,
              orderId: od.data[0].MER_ORDER_NO,
              butDisabled: false,
            })
          }
          else {
            that.setState({
              qr: od.data[0].QR_CODE,
              orderId: od.data[0].MER_ORDER_NO,
              butDisabled: true,
            },
            () => {
              that.queryOrder()
            })
          }
        }
      })
      .catch(err => {
        that.props.history.push({
          pathname : '/error',
          query : { messageTitle: '失败', messageContent: err.toString()}
        })
      })
    })
  }

  // 查看订单详情
  queryOrder(orderId){
    const that = this
    const { payment } = this.props
    const base = JSON.parse(localStorage.getItem('base'))
    getQueryOrder({
      orderId: this.state.orderId
    })
    .then(od => {
      if(od.status){
        const orderId = od.data.orderId
        const transactionId = od.data.transaction_id
        const paySource = od.data.PAY_SOURCE

        that.setState({
          orderId,
          transactionId,
          paySource,
        },
        () => {
          let payObj = Object.assign(payment, {
            orderId,
            transactionId,
            payType: payConfs.nshPay.name
          })

          that.props.addPayment(payObj)

          // 特殊处理，建卡时的卡费支付，就不需要在提交到his，直接跳回到发卡route
          if(payment.businessType === 'createCard'){
            if(payment.cardFee == 0){
              that.createCardPay()
            }
            else{
              const hospitalName = base.hospitalInfo.value
              const fee = payment.amount
              let createCardData = Object.assign(that.props.createCard, {
                orderId,
                transactionId,
                Cz_type: payConfs.nshPay.czType,
                subject: `${hospitalName}就诊卡建卡`,
                desc: `${hospitalName}就诊卡充值，￥${fee}元。`,
                platform: payConfs.nshPay.platForm,
              })
              that.props.addCardData(createCardData)
              base.hospitalInfo.isFeePay = true
              localStorage.setItem('base', JSON.stringify(base))
              that.props.history.push('/payPrint')
            }
          }
          else{
            // 如果是门诊缴费需要先打印支付账单
            if(payment.businessType === 'payBill' || payment.businessType === 'register'){
              that.printOutpatientPaymentOrder()
            }
            that.sendRecharge()
          }
        })
      }
      else{
        that.setState({
          butDisabled: false,
        })
      }
    })
    .catch(err => {
      that.props.history.push({
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 门诊缴费支付成功需要先打印支付账单
  printOutpatientPaymentOrder(){
    const { orderId } = this.state
    const { payment, cardInfo, base } = this.props
    const { userInfo, display_no } = cardInfo
    const { deviceId, hospitalInfo } = base
    const deviceNum = (Number(deviceId) < 10) ? `0${deviceId}` : deviceId
    let sendParams = {
      HOS_NAME: hospitalInfo.value,
      P_NAME: userInfo.NAME,
      ORDER_ID: orderId,
      CZ_TYPE: payConfs.nshPay.name,
      COST: payment.amount,
      BALANCE: cardInfo.balance,
      OP_CODE: `自助${deviceNum}`,
    }
    sendParams['FEE_TYPE'] = payment.businessType === 'payBill' ? '门诊收费' : '诊挂费'

    if(cardInfo.currentCard.id === 'jzcard'){
      sendParams['DISPLAY_NO'] = display_no
    }

    /*
    * 1、挂号支付不打印支付凭条
    * 2、医保支付的需要在 insuSettle 医保结算后在 打印支付凭条
    */
    if(payment.businessType !== 'register' && !userInfo.PSN_NO){
      printPayBill(sendParams)
    }
  }

  // 根据业务不同选择充值接口
  sendRecharge(){
    const that = this;
    const { payment } = this.props;

    switch(payment.businessType){
      case 'recharge':
        that.rechargePay();
        break;

      case 'inpatient':
        that.inpatientPay();
        break;

      case 'payBill':
        // 门诊缴费是医保缴费还是非医保缴费
        if(base.isMedicalSettle) {
          const { preSettle } = payment

          // 没有输入过医保密码
          if(preSettle.feeType === '医保' && !preSettle.insurPassword){
            this.openInsurPassword()
            return ;
          }
          that.outpatientInsurPayment()
        }
        else{
          that.outpatientPayment();
        }
        break;

      case 'register':
        that.registerPay();
        break;

      case 'takeNo':
        that.sendTakeNo()
        break;

      default:
        that.props.history.push({
          pathname : '/error',
          query : { messageTitle: '错误', messageContent: '没有找到所充值的业务类型'}
        })
        break;
    }
  }

  // 建卡充值 - 无卡费情况给卡充值
  createCardPay(){
    const that = this;
    const { transactionId, orderId, paySource } = this.state;
    const { payment } = this.props;
    const base = JSON.parse(localStorage.getItem('base'));
    const { deviceId } = base;
    const deviceNum = (Number(deviceId) < 10) ? `0${deviceId}` : deviceId;

    sendHisRecharge({
      mr_no: payment.mr_no,
      Balance: payment.amount,
      Pwd: '',
      Cz_type: payConfs.nshPay.czType,
      Cz_serial: transactionId,
      Zz_serial: orderId,
      op_code: deviceNum,
      platform: 'ns',
      pay_source: paySource,
    })
    .then(od => {
      if(od.status){
        notification.success({
          message: `成功`,
          description: od.message,
        });
        // 调用打印接口
        that.props.history.push('/payPrint');
      }
      else{
        // that.refund()
        notification.error({
          message: '提示',
          description: '业务办理失败，请去窗口处理!',
        });
      }
    })
    .catch(err => {
      // that.refund()
      notification.error({
        message: '提示',
        description: '业务办理失败，请去窗口处理!',
      });
      that.props.history.push({
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
      // 调用打印接口
      that.props.history.push('/payPrint');
    })
  }

  // 卡充值
  rechargePay(){
    const that = this;
    const { transactionId, orderId, paySource } = this.state;
    const { cardInfo, payment } = this.props;
    const base = JSON.parse(localStorage.getItem('base'));
    const { deviceId } = base;
    const deviceNum = (Number(deviceId) < 10) ? `0${deviceId}` : deviceId;

    sendHisRecharge({
      mr_no: cardInfo.mr_no,
      Balance: payment.amount,
      Pwd: '',
      Cz_type: payConfs.nshPay.czType,
      Cz_serial: transactionId,
      Zz_serial: orderId,
      op_code: deviceNum,
      platform: 'ns',
      pay_source: paySource,
    })
    .then(od => {
      if(od.status){
        cardInfo.balance = od.data[0].BALANCE;
        notification.success({
          message: `成功`,
          description: od.message,
        });

        // 如果来源是缴费充值在跳回缴费列表
        if(payment.originType == '门诊缴费充值'){
          this.props.history.push('/payMentList')
          return ;
        }

        // 调用打印接口
        that.props.history.push('/payPrint');
      }
      else{
        // that.refund()
        notification.error({
          message: '提示',
          description: '业务办理失败，请去窗口处理!',
        });
      }
    })
    .catch(err => {
      // that.refund()
      notification.error({
        message: '提示',
        description: '业务办理失败，请去窗口处理!',
      });
      that.props.history.push({
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
      // 调用打印接口
      that.props.history.push('/payPrint');
    })
  }

  // 住院充值
  inpatientPay(){
    const that = this;
    const { transactionId, orderId, paySource } = this.state;
    const { payment, inpatient } = this.props;
    const base = JSON.parse(localStorage.getItem('base'));
    const { deviceId } = base;
    const deviceNum = (Number(deviceId) < 10) ? `0${deviceId}` : deviceId;

    depositRecharge({
      in_no: inpatient.inpatientNo,
      Balance: payment.amount,
      Cz_type: payConfs.nshPay.czType,
      Cz_serial: transactionId,
      Zz_serial: orderId,
      op_code: deviceNum,
      platform: 'ns',
      pay_source: paySource,
    })
    .then(od => {
      if(od.status){
        that.props.inpatient.balance = od.data[0].COST;

        notification.success({
          message: `成功`,
          description: od.message,
        });
        // 调用打印接口
        that.props.history.push('/payPrint');
      }
      else{
        // that.refund()
        notification.error({
          message: '提示',
          description: '业务办理失败，请去窗口处理!',
        });
      }
    })
    .catch(err => {
      // that.refund()
      notification.error({
        message: '提示',
        description: '业务办理失败，请去窗口处理!',
      });
      that.props.history.push({
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
      // 调用打印接口
      that.props.history.push('/payPrint');
    })
  }

  // 挂号
  registerPay(){
    const that = this;
    const { orderId, pay_source } = this.state;
    const { mr_no, currentCard } = this.props.cardInfo;
    const { doctorInfo } = this.props.register;
    const base = JSON.parse(localStorage.getItem('base'));
    const  { deviceId } = base;

    regToday({
      MR_NO: mr_no,               // 挂号患者的 MR_NO
      scheduleId: doctorInfo.id,  // 获取排期编号
      deviceId,
      orderId,
      paidType: 2,
      platform: 'ns',
      pay_source: paySource,
      cardTypeCode: currentCard.id,
      cardTypeName: currentCard.name,
    })
    .then(od => {
      if(od.status){
        notification.success({
          message: `成功`,
          description: od.message,
        });

        let payMentObj = Object.assign(this.props.payMent || {}, {
          "businessName": "",
          "businessType": "takeNo",
          "printTitle": "",
          "amount": "",
          "takeNo": od.data,
        })
        this.props.addPayment(payMentObj)
        that.props.history.push('/payPrint');
      }
      else{
        // that.refund()
        notification.error({
          message: '提示',
          description: '业务办理失败，请去窗口处理!',
        });
      }
    })
    .catch(err => {
      that.props.history.push({
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 取号支付
  sendTakeNo(){
    const that = this
    const base = JSON.parse(localStorage.getItem('base'));
    const { paySource } = this.state
    const { deviceId } = base;
    const takeNoData = that.props.payment

    takeNo({
      regId: takeNoData.regId,
      deviceId,
      platform: 'ns',
      pay_source: paySource,
    })
    .then(od => {
      if(od.status){
        let takeNoData = od.data;
        let newPayment = Object.assign(that.props.payment || {}, {
          businessName: '',
          businessType: 'takeNo',
          printTitle: '',
          amount: takeNoData.amount,
          takeNo: takeNoData,
        })
        that.props.addPayment(newPayment)

        // 调用打印接口
        that.props.history.push('/payPrint');
      }
    })
    .catch(err => {
      that.props.history.push({
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  /************** 门诊缴费 **************/
  // 打开医保输入密码
  openInsurPassword(){
    this.refs.isurPsRef.openModal()
  }

  confirmInsurPasswrodpsText(passwordText){
    const { payment } = this.props
    const { insPasswordCount } = this.state

    if(insPasswordCount > 3) {
      notification.error({
        message: `提示`,
        description: '医保密码输入次数已超过3次',
        duration: 10,
      });

      // 退费
      this.refund()
      return ;
    }

    // console.log('医保密码为', passwordText)
    if(passwordText !== ''){
      const { preSettle } = payment

      // 门诊缴费	      // 将医保密码重新存储
      this.props.addPayment({
        ...payment,
        preSettle:{
          ...preSettle,
          insurPassword: passwordText
        }
      })

      this.outpatientInsurPayment()
    }
  }
  
  // 门诊缴费（医保）
  outpatientInsurPayment(){
    const that = this
    const { orderId, transactionId, insPasswordCount } = this.state
    const { payment } = this.props
    const { cardInfo, preSettle } = payment
    const base = JSON.parse(localStorage.getItem('base'))
    const { deviceId, hospitalInfo } = base
    const deviceNum = (Number(deviceId) < 10) ? `0${deviceId}` : deviceId
    const {
      batchNo='',    // 预结算批次号
      feeType='',    // 费用类别（医保、自费）
      costOrder='',  // 费用总额
      fundPay='',    // 医保支付金额
      accPay='',     // 医保个人账户支付金额
      cashPay='',    // 应付现金
      mrPay='',      // 院内账户支付
      onlinePay='',  // 线上支付
      insurPassword='',  // 医保密码
    } = preSettle

    const params = {
      batch_no: batchNo,
      pwd: insurPassword,
      order_id: orderId,
      trans_id: transactionId,
      pay_lb: 5,
      pay_type: hospitalInfo.hospCode === '13010033' ? '05' : '01',   // 晋州康华 传 农商行05
      cost: onlinePay,
      op_code: deviceNum,
      mr_no: cardInfo.mr_no,
    }
    console.log('农行聚合支付 InsuSettle 请求：', params)

    that.setState({spinLoading: true})

    insuSettle(params)
      .then(res => {
        console.log('农行聚合支付 InsuSettle 响应：', res)

        that.setState({spinLoading: false})

        if(res.status && res.data){

          // 存储缴费信息
          const payObj = {
            businessName: '门诊缴费',
            businessType: 'payBill',
            printTitle: '门诊缴费',
            amount: onlinePay,   // cashPay,
            preSettle,
            einvInfo: res.data[0].einv_info || '',    // 电子发票使用
          }
          that.props.addPayment(payObj)

          notification.success({
            message: `成功`,
            description: res.message,
          });
          // 打印医保支付凭条
          that.printSettlePayBillInsu(res.data[0])
        }
        else{
           // 打开医保密码窗口，记录医保密码输入次数
           that.setState({
            insPasswordCount: insPasswordCount+1
          })
          that.refs.isurPsRef.openModal()
        }
      })
      .catch(err => {
        that.setState({spinLoading: true})
        that.props.history.push({
          pathname : '/error',
          query : { messageTitle: '失败', messageContent: err.toString()}
        })
      })
  }

  // 打印（医保）门诊缴费凭条
  printSettlePayBillInsu(od){
    const that = this
    const { userInfo } = this.props.cardInfo
    const base = JSON.parse(localStorage.getItem('base'))
    const { deviceId, hospitalInfo } = base
    const deviceNum = (Number(deviceId) < 10) ? `0${deviceId}` : deviceId

    const {
      PAT_NAME = '',
      MR_NO = '',
      FUND_PAY = '',
      ACC_PAY = '',
      BALC_INS = '',
      MR_PAY = '',
      ONLINE_PAY = '',
      CHARGE_TIME = '',
      COST_ORDER = '',
      FEE_TYPE = '',
    } = od
    
    const params = {
      HOS_NAME: hospitalInfo.value,
      P_NAME: PAT_NAME,
      ORDER_COST: COST_ORDER,
      FEE_TYPE,
      FUND_PAY,
      ACC_PAY,
      BALC_INS,
      MR_PAY,
      BALC_MR: userInfo.BALC_MR,
      ONLINE_PAY,
      OP_CODE: `自助${deviceNum}`,
      CHARGE_TIME,
    }
    console.log('农商聚合-门诊缴费（医保）缴费凭条打印 printPayBillInsu 请求参数：', params)

    printPayBillInsu(params)
      .then(res => {
        console.log('农商聚合-门诊缴费（医保）缴费凭条打印 printPayBillInsu 响应参数：', res)

        if(res.status){
          // 打印缴费明细
          that.getPayDetailList()
        }
      })
      .catch(err => {
        that.props.history.push({
          pathname : '/error',
          query : { messageTitle: '失败', messageContent: err.toString()}
        })
      })
  }

  // 门诊缴费（非医保）
  outpatientPayment(){
    const that = this
    const { orderId, transactionId, paySource, spinLoading } = this.state
    const { payment } = this.props
    const { paymentList } = payment
    const base = JSON.parse(localStorage.getItem('base'))
    const { deviceId } = base
    const deviceNum = (Number(deviceId) < 10) ? `0${deviceId}` : deviceId

    paymentList.map((payItem) => {
      if(payItem.ITEMS) {
        delete payItem.ITEMS
      }
      payItem['pay_lb'] = 2    // 自费支付类别	0现金 1银行卡 2微信 3支付宝 4消费卡账户 5 医保金融账户 9其他
      payItem['Cz_serial'] = transactionId
      payItem['Zz_serial'] = orderId
      payItem['op_code'] = deviceNum
      payItem['platform'] = 'ns'
      payItem['pay_source'] = paySource
    })

    that.setState({spinLoading: true})

    sendPayment(paymentList)
    .then(od => {
      that.setState({spinLoading: false})

      if(od.status){
        notification.success({
          message: `成功`,
          description: od.message,
        });
        that.getPayDetailList()
      }
      else{
        // that.refund()
        notification.error({
          message: '支付失败',
          description: '请到窗口缴费',
        });
      }
    })
    .catch(err => {
      that.setState({spinLoading: false})
      that.props.history.push({
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 获取门诊缴费费用详情数据
  getPayDetailList(){
    const that = this;
    const { records, amount } = this.props.outpatient;

    getPayDetails(records)
    .then(od => {
      that.props.addOutpatient({
        amount,
        records,
        recordDetail: od.data
      })
      that.props.addPayment({
        businessType: 'payBill',
        printTitle: '门诊缴费',
        amount,
        ...payment,
      })

      setTimeout(() => {
        // 打印缴费详情数据
        that.props.history.push('/payPrint')
      }, 500)
    })
    .catch(err => {
      that.props.history.push({
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }
  /************** 门诊缴费 End **************/

  // 退费
  refund(){
    const that = this;
    const { orderId, subject, desc } = this.state;
    const { payment, base } = this.props;
    const fee = payment.amount;
    const refundId = `${new Date().getTime()}_${base.deviceId}`

    const params = {
      platform: payConfs.nshPay.platForm,
      order_id: orderId,
      refund_request_id: refundId,
      refund_fee: fee,
      subject,
      desc,
      operator_id: '0',
      windows_id: '0',
    }

    refund(params)
    .then(od => {
      if(od.status){
        notification.success({
          message: `成功`,
          description: od.message,
          duration: 10,
        });

        // 退费成功跳转订单列表 - 2024.04.16 医保密码三次错误需求，提出
        if(payment.businessType == 'payBill'){
          this.props.history.push('/payMentList');
        }
      }
    })
    .catch(err => {
      that.props.history.push({
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  render() {
    const quitTime = businessQuitTime.pay.wxPay;
    const { payment } = this.props;
    const { qr, butDisabled, spinLoading } = this.state;
    const renderRq = () => {
      if(qr){
        return <QRCode value={ qr } size="140" />
      }
    }

    return (
      <Spin spinning={spinLoading} tip="正在处理中请稍后...">
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <div className="pay-info">
            <h2>农商银行聚合支付</h2>
            <p>请使用微信、支付宝、银联App（手机客户端）扫码支付</p>
            <p className="size40">您需要支付: <span><i className="col-warning">{ payment.amount }</i>元</span></p>
            <div className="qr-cont clearfix">
              <div className="qr-code">
                { renderRq() }
              </div>
              <div className="qr-explain size30">
                <p>1、打开微信、支付宝、银联App</p>
                <p>2、点击右上角"扫一扫"</p>
                <p>3、扫完二维码进行付款</p>
                <p>4、手机完成支付后等待支付结果</p>
              </div>
            </div>
          </div>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={() => this.goUpPage()} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={() => this.goHomePage()} disabled={butDisabled}
                  style={{ "backgroundColor": "#bfdfe9", color: "#8D2829", border: "1px #3976A9 solid", "float": "right"}}>
            退 出</Button>
        </div>

         {/* 医保密码输入 */}
         <MedicalInsurPassword 
          ref="isurPsRef"
          cancelText="放弃"
          cancelClick={() => this.refund()}
          confirmInsurPasswrod={(psText) => this.confirmInsurPasswrodpsText(psText)} 
        />
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { createCard, cardInfo, recharge, base, payment, inpatient, register, outpatient } = state
  return {
    createCard,
    cardInfo,
    recharge,
    base,
    payment,
    inpatient,
    register,
    outpatient,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addCardData: (data) => {
      dispatch(createCardAction.addCardData(data));
    },
    addCard: (data) => {
      dispatch(cardInfoAction.addCard(data));
    },
    addRecharge: (data) => {
      dispatch(rechargeAction.addRecharge(data))
    },
    addPayment: (data) => {
      dispatch(paymentAction.addPayment(data))
    },
    addOutpatient: (data) => {
      dispatch(outpatientAction.addOutpatient(data));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NsjhPay)
