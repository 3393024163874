import React, { Component } from 'react'
import { Button, notification, Icon, Row, Col, Spin } from 'antd'
import Head from '../head'
import businessQuitTime from '../../utils/businessQuitTime'
import { 
  sendHisRecharge,
  depositRecharge,
  transPay,
  regToday,
  sendPayment,
  getPayDetails,
  printPayBill,
  takeNo,
  newOrderFromHIS,
  saveOrdersToHIS,
  bankRefund,
  insuSettle,
  printPayBillInsu
 } from '@/utils/request'
import { connect } from 'react-redux'
import { payConfs } from '@/utils/common'
import MedicalInsurPassword from '../MedicalInsurPassword'
import * as cardInfoAction from '../../store/actions/cardInfo'
import * as rechargeAction from '../../store/actions/recharge'
import * as paymentAction from '../../store/actions/payment'
import * as outpatientAction from '../../store/actions/outpatient'
import jhsm from '../../assets/img/jhsm.png'
import './pay.scss'

/*
 * 银联聚合支付
 */
class YhkPay extends Component {
  constructor(props){
    super(props);
    this.state = {
      deviceId: '',
      orderId: '',
      subject: '',
      desc: '',
      moduleName: '',
      // 存储正常支付信息
      orderInfo: {
        orderId: '',
        INP_CS: '',
        RET_CS: '',
      },
      // 存储支付失败时使用
      refundOrder: {
        orderId: '',
        INP_CS: '',
        RET_CS: '',
      },
      authCode: '',     // 付款码
      isPaying: false,  // 是否当前流程支付中
      spinLoading: false,
      insPasswordCount: 1,  // 输入医保密码次数
    }

    this.inputRef = React.createRef()
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/payList')
  }

  componentDidMount(){
    const that = this
    setTimeout(function(){
      that.inputRef.current.focus()
    }, 1000)
  }

  addListenScanCode(evt){
    const { isPaying } = this.state
    const { payment } = this.props
    const baseInfo = JSON.parse(localStorage.getItem('base'));
    const deviceId = baseInfo.deviceId < 10 ? `0${baseInfo.deviceId}` : baseInfo.deviceId
    
    if(evt.keyCode === 13 || evt.keyCode === 108 || evt.keyCode === 32){
      let moduleName = ''
      const authCode = evt.target.value

      // 判断是否取到付款码
      if(authCode === ''){
        notification.error({
          message: '提示',
          description: '获取到的付款码为空',
        });
        return false
      }

      // 防止重复提交
      if(isPaying){
        notification.error({
          message: '提示',
          description: '支付未处理完请稍后在试',
        });
        return false
      }

      switch(payment.businessType){
        case 'payBill':
          moduleName = '门诊收费'
          break;

        case 'recharge':
        case 'createCard': 
          moduleName = '发卡管理'
          break;

        case 'register':
          moduleName = '挂号'
          break;

        case 'inpatient':
          moduleName = '住院押金'
          break;

        default:  break;
      }

      this.setState({
        deviceId,
        moduleName,
        authCode,
      }, () => {
        this.getNewOrderFromBank()
      }) 
    }
  }

  getNewOrderFromBank(errorPay){
    const { deviceId, moduleName, orderInfo } = this.state;
    const { payment } = this.props;

    let ORI_ORDERID = ''
    if(errorPay){
      ORI_ORDERID = orderInfo.orderId
    }

    newOrderFromHIS({
      MODULE: moduleName,
      deviceId,
      ZF_TYPE: 'U',
      FEE: payment.amount,
      ORI_ORDERID,
    })
    .then(res => {
      if(res.status){
        const orderId = res.data[0].ORDERID
        
        // 如果是退费生成订单
        if(errorPay){
          this.setState({
            refundOrder: {
              orderId,
              INP_CS: '',
              RET_CS: '',
            }
          }, () => {
            this.sendBankRefund()
          })
        }
        else{
          this.setState({
            orderId,
            orderInfo: {
              orderId,
              INP_CS: '',
              RET_CS: '',
            },
          }, () => {
            this.getTransPay()
          })
        }
      }
    })
    .catch(err => {
      notification.error({
        message: '失败',
        description: err.toString(),
      });
    })
  }

  getTransPay(){
    const { payment } = this.props;
    const { orderId, deviceId, authCode } = this.state;

    transPay({
      deviceId,
      FEE: payment.amount,
      orderId,
      AUTH_CODE: authCode,
      ZF_TYPE: "U"
    })
    .then(res => {
      if(res.status){
        const { INP_CS, RET_CS } = res.data
        // 保存orderInfo支付信息，也保存到refundOrder信息
        this.setState({
          orderInfo: {
            orderId,
            INP_CS,
            RET_CS,
          },
          refundOrder: {
            orderId,
            INP_CS,
            RET_CS,
          }
        }, () => {
          this.sendSaveOrdersToHIS();
        })
      }
      else{
        notification.error({
          message: '提示',
          description: res.message,
        });
      }
    })
    .catch(err => {
      notification.error({
        message: '失败',
        description: err.toString(),
      });
    })
  }

  sendSaveOrdersToHIS(errorPay){
    const that = this;
    let base = JSON.parse(localStorage.getItem('base'));
    const { orderInfo } = this.state
    const { orderId } = orderInfo;
    const { payment } = this.props;

    if(payment.amount === ''){
      notification.error({
        message: '提示',
        description: '充值金额不能为空',
      });
      return false;
    }

    // 提交参数trans
    let params = orderInfo
    if(errorPay){
      const { refundOrder } = this.state
      params = refundOrder
    }
    saveOrdersToHIS(params)
    .then(od => {
      if(od.status){
        // 如果支付错误情况结束支付
        if(!errorPay){
          let payObj = Object.assign(payment, {
            orderId: params.orderId,
            transactionId: params.orderId,
            payType: '银联聚合支付'
          });
          that.props.addPayment(payObj);

          // 特殊处理，建卡时的卡费支付，就不需要在提交到his，直接跳回到发卡route
          if(payment.businessType === 'createCard'){
            if(payment.cardFee == 0){
              that.createCardPay()
            }
            else{
              const hospitalName = base.hospitalInfo.value
              const fee = payment.amount
              let createCardData = Object.assign(that.props.createCard, {
                orderId,
                transactionId: params.orderId,
                Cz_type: payConfs.yhk.czType,
                subject: `${hospitalName}就诊卡建卡`,
                desc: `${hospitalName}就诊卡充值，￥${fee}元。`,
                platform: payConfs.yhk.platForm
              })
              that.props.addCard(createCardData)
              base.hospitalInfo.isFeePay = true
              localStorage.setItem('base', JSON.stringify(base))
              that.props.history.push('/payPrint')
            }
          }
          else{
            // 如果是门诊缴费需要先打印支付账单
            if(payment.businessType === 'payBill' || payment.businessType === 'register'){
              that.printOutpatientPaymentOrder()
            }
            that.sendRecharge();
          }
        }
      }
      else{
        // 重置支付状态
        this.setState({
          isPaying: false,
          authCode: '',
        }, () => {
          // 门诊缴费、挂号跳错误页，其它支付跳打印
          if(payment.businessType == 'inpatient' || payment.businessType == 'register'){
            notification.error({
              message: '提示',
              description: res.message,
            });
          }
          else{
            this.props.history.push('/payPrint');
          }
        })
      }
    })
    .catch(err => {
      notification.error({
        message: '提示',
        description: err.toString(),
      });
    })
  }

  // 建卡充值 - 无卡费情况给卡充值
  createCardPay(){
    const that = this;
    const { orderId } = this.state;
    const { payment } = this.props;
    const base = JSON.parse(localStorage.getItem('base'));
    const { deviceId } = base;
    const deviceNum = (Number(deviceId) < 10) ? `0${deviceId}` : deviceId;
    
    sendHisRecharge({
      mr_no: payment.mr_no,
      Balance: payment.amount,
      Pwd: '',
      Cz_type: payConfs.yhk.czType,
      Cz_serial: orderId,
      Zz_serial: orderId,
      op_code: deviceNum,
    })
    .then(res => {
      if(res.status){       
        notification.success({
          message: `成功`,
          description: res.message,
        });
        // 调用打印接口
        that.props.history.push('/payPrint');
      }
      else{
        this.getNewOrderFromBank(true)
      }
    })
    .catch(err => {
      this.getNewOrderFromBank(true)
      notification.error({
        message: '提示',
        description: err.toString(),
      });
      // 调用打印接口
      // that.props.history.push('/payPrint');
    })
  }

  // 门诊缴费支付成功需要先打印支付账单
  printOutpatientPaymentOrder(){
    const { orderId } = this.state
    const { payment, cardInfo, base } = this.props
    const { userInfo, display_no } = cardInfo
    const { deviceId, hospitalInfo } = base
    const deviceNum = (Number(deviceId) < 10) ? `0${deviceId}` : deviceId
    let sendParams = {
      HOS_NAME: hospitalInfo.value,
      P_NAME: userInfo.NAME,
      ORDER_ID: orderId,
      CZ_TYPE: '银联聚合支付',
      COST: payment.amount,
      BALANCE: cardInfo.balance,
      OP_CODE: `自助${deviceNum}`
    }
    sendParams['FEE_TYPE'] = payment.businessType === 'payBill' ? '门诊收费' : '诊挂费'

    if(cardInfo.currentCard.id === 'jzcard'){
      sendParams['DISPLAY_NO'] = display_no
    }

    /*
    * 1、挂号支付不打印支付凭条
    * 2、医保支付的需要在 insuSettle 医保结算后在 打印支付凭条
    */
     if(payment.businessType !== 'register' && !userInfo.PSN_NO){
      printPayBill(sendParams)
    }
  }
  
  // 提交到HIS充值
  sendRecharge(){
    const that = this
    const base = JSON.parse(localStorage.getItem('base'))
    const { payment } = this.props

    switch(payment.businessType){
      case 'recharge':
        that.rechargePay();
        break;

      case 'inpatient':
        that.inpatientPay();
        break;

      case 'payBill':
        // 门诊缴费是医保缴费还是非医保缴费
        if(base.isMedicalSettle) {
          const { preSettle } = payment

          // 没有输入过医保密码
          if(preSettle.feeType === '医保' && !preSettle.insurPassword){
            this.openInsurPassword()
            return ;
          }
          that.outpatientInsurPayment()
        }
        else{
          that.outpatientPayment();
        }
        break;

      case 'register':
        that.registerPay();
        break;

      case 'takeNo':
        that.sendTakeNo()
        break;

      default: 
        notification.error({
          message: '提示',
          description: '没有找到所充值的业务类型',
        });
        break;
    }
  }

  // 就诊卡充值
  rechargePay(){
    const that = this;
    const { orderId } = this.state;
    const { cardInfo, payment } = this.props;
    const base = JSON.parse(localStorage.getItem('base'));
    const { deviceId } = base;
    const deviceNum = (Number(deviceId) < 10) ? `0${deviceId}` : deviceId;

    sendHisRecharge({
      mr_no: cardInfo.mr_no,
      Balance: payment.amount,
      Pwd: '',
      Cz_type: payConfs.yhk.czType,
      Cz_serial: orderId,
      Zz_serial: orderId,
      op_code: deviceNum,
    })
    .then(od => {
      if(od.status){
        cardInfo.balance = od.data[0].BALANCE;
        
        notification.success({
          message: `成功`,
          description: od.message,
        });
        // 调用打印接口
        that.props.history.push('/payPrint');
      }
      else{
        this.getNewOrderFromBank(true)
      }
    })
    .catch(err => {
      this.getNewOrderFromBank(true)
      // that.props.history.push({ 
      //   pathname : '/error',
      //   query : { messageTitle: '失败', messageContent: err.toString()}
      // })
      // // 调用打印接口
      // that.props.history.push('/payPrint');
    })
  }

  // 住院充值
  inpatientPay(){
    const that = this;
    const { orderId } = this.state;
    const { payment, inpatient } = this.props;
    const base = JSON.parse(localStorage.getItem('base'));
    const { deviceId } = base;
    const deviceNum = (Number(deviceId) < 10) ? `0${deviceId}` : deviceId

    depositRecharge({
      in_no: inpatient.inpatientNo,
      Balance: payment.amount,
      Cz_type: payConfs.yhk.czType,
      Cz_serial: orderId,
      Zz_serial: orderId,
      op_code: deviceNum,
    })
    .then(res => {
      if(res.status){
        that.props.inpatient.balance = res.data[0].COST;

        notification.success({
          message: `成功`,
          description: res.message,
        });
        // 调用打印接口
        that.props.history.push('/payPrint');
      }
      else{
        this.getNewOrderFromBank(true)
      }
    })
    .catch(err => {
      this.getNewOrderFromBank(true)
      // 调用打印接口
      // that.props.history.push('/payPrint');
    })
  }

  // 挂号
  registerPay(){
    const that = this;
    const { orderId } = this.state;
    const { mr_no, currentCard } = this.props.cardInfo;
    const { doctorInfo } = this.props.register;
    const base = JSON.parse(localStorage.getItem('base'));
    const  { deviceId } = base;

    regToday({
      MR_NO: mr_no,               // 挂号患者的 MR_NO
      scheduleId: doctorInfo.id,  // 获取排期编号
      deviceId,
      orderId,
      paidType: 1,
      cardTypeCode: currentCard.id,
      cardTypeName: currentCard.name,
    })
    .then(od => {
      if(od.status){
        notification.success({
          message: `成功`,
          description: od.message,
        });

        let payMentObj = Object.assign(that.props.payMent || {}, {
          "businessName": "",
          "businessType": "takeNo",
          "printTitle": "",
          "amount": "",
          "takeNo": od.data,
        })
        that.props.addPayment(payMentObj)
        that.props.history.push('/payPrint');
      }
      else{
        that.getNewOrderFromBank(true)
      }
    })
    .catch(err => {
      that.getNewOrderFromBank(true)
      // that.props.history.push({ 
      //   pathname : '/error',
      //   query : { messageTitle: '失败', messageContent: err.toString()}
      // })
    })
  }

  // 取号支付
  sendTakeNo(){
    const that = this
    const base = JSON.parse(localStorage.getItem('base'));
    const { deviceId } = base;
    const takeNoData = that.props.payment

    takeNo({
      regId: takeNoData.regId,
      deviceId,
    })
    .then(od => {
      if(od.status){
        let takeNoData = od.data;
        let newPayment = Object.assign(that.props.payment || {}, {
          businessName: '',
          businessType: 'takeNo',
          printTitle: '',
          amount: takeNoData.amount,
          takeNo: takeNoData,
        })
        that.props.addPayment(newPayment)

        // 调用打印接口
        that.props.history.push('/payPrint');
      }
      else{
        that.getNewOrderFromBank(true)
      }
    })
    .catch(err => {
      that.getNewOrderFromBank(true)
      // that.props.history.push({ 
      //   pathname : '/error',
      //   query : { messageTitle: '失败', messageContent: err.toString()}
      // })
    })
  }

  /************** 门诊缴费 **************/
  // 打开医保输入密码
  openInsurPassword(){
    this.refs.isurPsRef.openModal()
  }

  confirmInsurPasswrodpsText(passwordText){
    const { payment } = this.props
    const { insPasswordCount } = this.state

    if(insPasswordCount > 3) {
      notification.error({
        message: `提示`,
        description: '医保密码输入次数已超过3次',
        duration: 10,
      });

      // 退费
      this.sendBankRefund()
      return ;
    }

    // console.log('医保密码为', passwordText)
    if(passwordText !== ''){
      const { preSettle } = payment

      // 将医保密码重新存储
      this.props.addPayment({
        ...payment,
        preSettle:{
          ...preSettle,
          insurPassword: passwordText
        }
      })

      this.outpatientInsurPayment()
    }
  }
  
  // 门诊缴费（医保）
  outpatientInsurPayment(){
    const that = this
    const { orderId, transactionId, insPasswordCount } = this.state
    const { payment } = this.props
    const { cardInfo, preSettle } = payment
    const base = JSON.parse(localStorage.getItem('base'))
    const { deviceId, hospitalInfo } = base
    const deviceNum = (Number(deviceId) < 10) ? `0${deviceId}` : deviceId
    const {
      batchNo='',    // 预结算批次号
      feeType='',    // 费用类别（医保、自费）
      costOrder='',  // 费用总额
      fundPay='',    // 医保支付金额
      accPay='',     // 医保个人账户支付金额
      cashPay='',    // 应付现金
      mrPay='',      // 院内账户支付
      onlinePay='',  // 线上支付
      insurPassword='',  // 医保密码
    } = preSettle

    const params = {
      batch_no: batchNo,
      pwd: insurPassword,
      order_id: orderId,
      trans_id: transactionId,
      pay_lb: 5,
      pay_type: '01',   // 农商行05、其它传01
      cost: onlinePay,
      op_code: deviceNum,
      mr_no: cardInfo.mr_no,
    }
    console.log('聚合支付 InsuSettle 请求：', params)

    that.setState({spinLoading: true})

    insuSettle(params)
      .then(res => {
        console.log('聚合支付 InsuSettle 响应：', res)

        that.setState({spinLoading: false})

        if(res.status && res.data){
          // 存储缴费信息
          const payObj = {
            businessName: '门诊缴费',
            businessType: 'payBill',
            printTitle: '门诊缴费',
            amount: onlinePay,   // cashPay,
            preSettle,
            einvInfo: einv_info,    // 电子发票使用
          }
          that.props.addPayment(payObj)

          notification.success({
            message: `成功`,
            description: res.message,
          });

          // 打印医保支付凭条
          that.printSettlePayBillInsu(res.data[0])
        }
        else{
          // 打开医保密码窗口，记录医保密码输入次数
          that.setState({
            insPasswordCount: insPasswordCount+1
          })
          that.refs.isurPsRef.openModal()
        }
      })
      .catch(err => {
        that.setState({spinLoading: false})
        that.props.history.push({
          pathname : '/error',
          query : { messageTitle: '失败', messageContent: err.toString()}
        })
      })
  }

  // 打印（医保）门诊缴费凭条
  printSettlePayBillInsu(od){
    const that = this
    const { userInfo } = this.props.cardInfo
    const base = JSON.parse(localStorage.getItem('base'))
    const { deviceId, hospitalInfo } = base
    const deviceNum = (Number(deviceId) < 10) ? `0${deviceId}` : deviceId

    const {
      PAT_NAME = '',
      MR_NO = '',
      FUND_PAY = '',
      ACC_PAY = '',
      BALC_INS = '',
      MR_PAY = '',
      ONLINE_PAY = '',
      CHARGE_TIME = '',
      COST_ORDER = '',
      FEE_TYPE = '',
    } = od
    
    const params = {
      HOS_NAME: hospitalInfo.value,
      P_NAME: PAT_NAME,
      ORDER_COST: COST_ORDER,
      FEE_TYPE,
      FUND_PAY,
      ACC_PAY,
      BALC_INS,
      MR_PAY,
      BALC_MR: userInfo.BALC_MR,
      ONLINE_PAY,
      OP_CODE: `自助${deviceNum}`,
      CHARGE_TIME,
    }
    console.log('聚合支付-门诊缴费（医保）缴费凭条打印 printPayBillInsu 请求参数：', params)

    printPayBillInsu(params)
      .then(res => {
        console.log('聚合支付-门诊缴费（医保）缴费凭条打印 printPayBillInsu 响应参数：', res)
        if(res.status){
          // 打印缴费明细
          that.getPayDetailList()
        }
      })
      .catch(err => {
        that.props.history.push({
          pathname : '/error',
          query : { messageTitle: '失败', messageContent: err.toString()}
        })
      })
  }

  // 门诊缴费（非医保）
  outpatientPayment(){
    const that = this
    const { orderId, spinLoading } = this.state
    const { payment } = this.props
    const { paymentList } = payment
    const base = JSON.parse(localStorage.getItem('base'))
    const { deviceId } = base
    const deviceNum = (Number(deviceId) < 10) ? `0${deviceId}` : deviceId

    paymentList.map((payItem) => {
      if(payItem.ITEMS) {
        delete payItem.ITEMS
      }
      payItem['pay_lb'] = 1    // 自费支付类别	0现金 1银行卡 2微信 3支付宝 4消费卡账户 5 医保金融账户 9其他
      payItem['Cz_serial'] = orderId
      payItem['Zz_serial'] = orderId
      payItem['op_code'] = deviceNum
    })
    
    that.setState({spinLoading: true})

    sendPayment(paymentList)
    .then(od => {
      that.setState({spinLoading: false})
      if(od.status){
        notification.success({
          message: `成功`,
          description: od.message,
        });
        that.getPayDetailList()
      }
      else{
        notification.error({
          message: '支付失败',
          description: '请到窗口缴费',
          duration: 10,
        })
        that.getNewOrderFromBank(true)
      }
    })
    .catch(err => {
      that.setState({spinLoading: false})
      that.getNewOrderFromBank(true)
      // that.props.history.push({ 
      //   pathname : '/error',
      //   query : { messageTitle: '失败', messageContent: err.toString()}
      // })
    })
  }

  // 获取门诊缴费费用详情数据
  getPayDetailList(){
    const that = this;
    const { records, amount } = this.props.outpatient;
    const { payment } = this.props

    getPayDetails(records)
    .then(res => {
      if(res.status){
        that.props.addOutpatient({
          amount,
          recordDetail: res.data
        })
        that.props.addPayment({
          businessType: 'payBill',
          printTitle: '门诊缴费',
          amount,
          ...payment,
        })
  
        setTimeout(() => {
          // 打印缴费详情数据
          that.props.history.push('/payPrint')
        }, 500)
      }
      else{
        that.getNewOrderFromBank(true)
      }
    })
    .catch(err => {
      that.getNewOrderFromBank(true)
    })
  }
  /************** 门诊缴费 End **************/

  // 银联聚合支付退费
  sendBankRefund(){
    const { orderId, refundOrder } = this.state

    bankRefund({
      ZF_TYPE: 'U',
      REFUND_ID: orderId,
      ORI_RET_CS: refundOrder.orderId,
    })
    .then(res => {
      if(res.status){
        this.setState({
          refundOrder: {
            ...refundOrder,
            INP_CS: res.data.INP_CS,
            RET_CS: res.data.RET_CS
          }
        }, () => {
          this.sendSaveOrdersToHIS(true)

          notification.error({
            message: '提示',
            description: '业务办理失败，请去窗口处理!',
            duration: 10,
          })
        })
      }
    })
    .catch(err => {
      notification.error({
        message: '提示',
        description: err.toString(),
      });
    })
  }

  render() {
    const quitTime = businessQuitTime.pay.yhkPay;
    const { payment } = this.props;
    const { spinLoading } = this.state

    return (
      <Spin spinning={spinLoading} tip="正在处理中请稍后...">
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <div className="pay-info">
            <h2>银联聚合支付</h2>
            <p>请将手机付款码放置到指示区域扫码</p>
            <Row gutter={16} className="mt-20">
              <Col className="gutter-row" span={24}>
                <div style={{width: '100%', margin: '10px auto 0'}}>
                  <input type="text"
                    ref={this.inputRef}
                    onKeyUp={(evt) => this.addListenScanCode(evt)}
                    style={{width: '500px',
                    height: '50px',
                    marginTop: '20px',
                    border: '1px #ccc solid',
                    padding: '0 15px',
                    fontSize: '31px'}} />
                </div>
                <div className="mt-25">
                  <img src={jhsm} alt="" />
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={() => this.goUpPage()} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={() => this.goHomePage()}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
        </div>

        {/* 医保密码输入 */}
        <MedicalInsurPassword 
          ref="isurPsRef"
          cancelText="放弃"
          cancelClick={() => this.getNewOrderFromBank(true)}
          confirmInsurPasswrod={(psText) => this.confirmInsurPasswrodpsText(psText)} 
        />
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { createCard, cardInfo, recharge, base, payment, inpatient, register, outpatient } = state
  return {
    createCard,
    cardInfo,
    recharge,
    base,
    payment,
    inpatient,
    register,
    outpatient,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addCard: (data) => {
      dispatch(cardInfoAction.addCard(data));
    },
    addRecharge: (data) => {
      dispatch(rechargeAction.addRecharge(data))
    },
    addPayment: (data) => {
      dispatch(paymentAction.addPayment(data))
    },
    addOutpatient: (data) => {
      dispatch(outpatientAction.addOutpatient(data));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(YhkPay)