import React, { Component } from 'react'
import Head from '../../components/head/'
import { Button, Icon } from 'antd';
import businessQuitTime from '../../utils/businessQuitTime'
import styles from './department.scss'

export default class DepartmentIntro extends Component {
  constructor(props){
    super(props);
    this.state = {
      detailContent: {}
    }
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/departmentIntro')
  }

  componentDidMount(){
    const detailData = {
      pediatrics: {
        name: '儿科',
        info: `<p>鸡泽县中医院儿科始建于1997年，经过二十余年的发展，年门诊量平均3000余人，年收治病人一千五百余人。一楼设有儿科门诊，三楼设有儿科病房，开放病床50余张，设有感染病区和非感染病区。病室宽敞、明亮、整洁、设放完善，配置了中央空调、集中供氧、负压吸引器、微波治疗仪，红光治疗机，新生儿保温箱、微量注射泵、多参数婴儿监护仪、红光治疗仪、小儿呼吸机等先进医疗设备。   全体医护人员均于省市级医院进修培训后上岗，是一支富有经验、朝气蓬勃、技术过硬、服务一流的医疗护理团队。对治疗小儿发热、腹泻、脑炎、重症肺炎，紫癜、小儿遗尿等常见病症疗效显著。同时对治疗新生儿缺氧缺血性脑病，早产儿、新生儿黄疸、新生儿重症肺炎等具有丰富经验。为提高科室医护人员技术水平，采取走出去请进来的办法定期安排人员在河北省儿童医院、邯郸市中心医院、市第一医院进修深造并请上述医院儿科专家在我院坐诊讲学，为我科提供技术指导，同时我们坚持走中西医结合的道路，在儿科领域开辟了一片治疗新天地。</p><p>团结协作，无私奉献，为患儿撑起一片蓝天</p><p>地址：鸡泽县自荐西路(鸡泽县城南转盘往西)</p><p>西三楼儿科医办室电话：7513162</p><p>门诊一楼西侧儿科门诊电话：7513136</p>`,
      },
      anorectal: {
        name: '肛肠科',
        info: `<p>我们的理念是:以科技为先导,放眼世界,以专家为核心,服务全县,深度关注病人生理、心理需求,提供专业、温馨个性化服务,秉承质量第一,诚信行医,发扬医院良好传统,竭尽全力地满足患者的医疗康复需要!        肛肠科以“专业技能扎实,临床经验丰富,为患者解除痛苦”为己任,以“技术与服务”为核心竞争力,凭借“执着医志,诚信医德,精湛医术,严谨医风”致力于专业研究。并与全市每位著名专家建立技术合作,突破专项技术难题,成功治愈了各种复杂、疑难肛肠病,并运用中医、中药、中药熏洗、灌肠等中西结合方式治疗肛门疾病,个体化的诊疗施治方案,保障了疗效,专业轻松的健康宣教。使患者受益非浅,赢受爱戴。       </p>
        <p>我科具备一流完善的专科病房,以最有效的治疗方案为保障,专业化、规范化的治疗,注重自我人才培养,促进学术对外交流,特别采用无创伤、无痛苦、不开刀采用注射疗法,套扎疗法,PPH等治疗,一次性治愈痔病及直肠脱垂顽疾,在本县起到领先作用,对环状混合痔、高位复杂性肛瘘具有较高的手术技术水准,为我县和周边地区广大医疗患者带来最大福音。        我们发扬“以人为本,病患至上”服务理念,用细致入微体现全心全意的人文关怀,竭尽全力奉献给社会,回报患者一个健康体魄,衷心欢迎广大患者前来垂询就诊！</p>`,
      },
      otorhinolaryngology: {
        name: '耳鼻喉科',
        info: `<p>鸡泽县中医院耳鼻喉科专业设置齐全，技术一流，自创建以来，深受广大患者好评，该科室拥有耳鼻喉大型综合检查治疗台及先进的内镜消毒系统，常规开展儿童张口呼吸的多学科联合治疗，鼻内镜下鼻窦炎鼻内窦开放、鼻中隔偏曲矫正、鼻中隔穿孔修复、鼻息肉摘除、过敏性鼻炎及鼻部肿瘤的手术，鼻中隔偏曲畸形同期矫治，内镜辅助陈旧性鼻骨骨折整复，声带微创治疗声带息肉、声带小结、声带白斑、慢性扁桃体炎、腺样体肥大规范治疗，鼓室成型，乳突 根治，听力重建，耳鸣、耳聋规范化治疗，疗效好，痛苦小，恢复快。近年来科室先开展了鼻内镜下鼻腔微创手术逐步代替传统的鼻腔手术方式，成人及儿童阻塞性睡眠呼吸暂停低通气综合征（OSAHS）的多学科联合治疗，耳鼻咽喉部癌前癌前病变的早期复查等。我们会用我们的耐心、细心、用我们的专业知识，热情的为患者服务！我们将充分利用现代医学技术，竭尽所能让所有人健康！</p>
        <p>健康热线：0310-7513187 </p>
        <p>13673204848</p>
        `,
      },
      ultrasonic: {
        name: '超声科',
        info:  `<p>鸡泽县中医院超声科实力雄厚，设备先进，拥有2台高档彩色多普勒超声仪。美国LOGIQE9是一部妇科、产科、腹部、心脏及浅表检查于一身的全能彩超机。美国GE730是一部四维妇产科彩超机。科室采用先进的超声图文工作站系统对报告及档案实行电脑化管理。可开展多种超声诊疗项目。全科人员以“科学严谨、求真务实”的服务理念贯穿到实际工作中，实行24小时全天候开诊，为全县人民提供专业、精湛的诊疗服务。</p><p>科室品牌：“准确源自精细”　</p>
        <p>服务宗旨：“一切以病人为中心”　</p>
        <p>服务理念：“科学严谨、求真务实”</p>
        <p>服务目标：“为临床、为病人提供标准化服务”</p>
        <p>业务范畴</p>
        <p>1.常规超声检查：超声在腹部、泌尿、心脏、血管、妇科、产科、甲状腺、乳腺、浅表器官等各系统多脏器疾病的诊断及鉴别诊断</p>
        <p>2.经阴道超声检查：各种妇科疾病，如子宫肌瘤、子宫肌腺病、卵巢肿瘤、宫外孕等。</p>
        <p>3.经直肠超声检查：前列腺病变。</p>
        <p>4.胎儿产前超声检查：四维超声检查。</p>
        <p>5.超声引导下的各种穿刺抽液。</p>
        <p>6.承接单位及个人健康体检。</p>`,
      },
      maternity: {
        name: '妇产科',
        info: `<p>鸡泽县中医院妇产科拥有一支业务水平高、技术精湛、作风踏实、服务一流的医疗护理队伍。科室坚持走中西医结合发展之路开展多种疾病的中西医结合治疗，如：不孕症、子宫异常出血、宫颈炎、盆腔炎、阴道炎及围产期疾病的治疗积累了丰富的临床经验。病房环境温馨，舒适，有价廉整洁的多人病房，也有配套齐全的单人病房，让患者真正体会到宾至如归的感觉。 　</p>　
        <p>科室不断开发和应用各种新技术、新项目，拓宽服务范围。开展了宫、腹腔镜辅助子宫内膜息肉切除术、粘膜下肌瘤剔除术、子宫切除术、子宫肌瘤剔除术、附件切除术、卵巢囊肿剥出术、卵巢楔形切除术、子宫内膜异位病灶去除术、输卵管妊娠去除术、盆腔粘连松解术等妇科手术，取得了满意的疗效。利用阴道镜、TCT、HPV检测、LEEP刀等手段，对宫颈疾病特别是宫颈恶性肿瘤进行规范化的筛查、诊疗，避免了宫颈疾病的过度治疗，使宫颈癌能够早诊断、早治疗。</p>
        <p>科室建有规范的高危妊娠治疗管理系统，保护高危孕产妇的健康，大力开展优生优育、母婴监护、胎儿监护、无痛人流、无痛分娩、新生儿洗澡等服务，使产妇和新生儿更加安全、舒适，怀孕和分娩不再是一种危险、痛苦的体验。 　</p>
        <p>重视人才培养及学习，安排多名医护人员到上级知名医院进修、深造，学习了先进的理念和技术，治愈了大批患者，赢得良好的口碑。科室秉承以病人为中心、以质量为核心，精心为病人制定个体化治疗方案，妇产科全体医护人员坚守“以我的真心换你的信任，以我的用心换你的健康”竭诚为每一位患者提供最优质、最专业的医疗服务。</p>
        `,
      },
      recovery: {
        name: '康复科',
        info: `<p>鸡泽县中医院康复医学科包括康复门诊及康复住院病区。科室面积1000余平方米，病床30张，设备资产500余万元。现有康复医师5人、康复治疗师6人、康复护士4人。         
科室设有:康复诊室、康复评定室、言语吞咽治疗室、运动治疗大厅、作业治疗室、物理因子治疗大厅、针灸治疗室、推拿治疗室、牵引室、儿童康复室、康复病房。 </p>        
        <p>科室拥有国内外各类先进康复设备和仪器：如上肢机器人、重复经颅磁刺激器（TMS）、体外冲击波治疗仪、言语及认知评估治疗仪、泥疗系统、悬吊系统、生物刺激反馈仪、上下肢主被动康复训练器、各关节被动活动器（CPM）、电动起立床、脑电放生电刺激、数字OT训练系统、干扰电治疗仪、微波、超短波、电脑中频治疗仪、骨质增生治疗仪、激光治疗仪、空气波压力治疗仪、痉挛肌治疗仪、低频脉冲治疗仪、多功能牵引系统等。   </p>       
        <p>科室坚持“早期介入、齐头并举”的治疗原则，对中风偏瘫、脑外伤后遗症、小儿脑瘫、脊髓损伤所致截瘫和四肢瘫、全身各部位骨折术后、人工关节置换术后、手外伤术后、周围神经损伤、颈椎病、膝关节退行性病变、股骨头坏死、骨关节炎、面神经麻痹、肩周炎、腰间盘突出、骨质增生、各种肌肉韧带损伤、运动系统损伤等康复科常见疾病开展早期康复，团队协作，规范化治疗，最大限度改善患者功能，提高生活质量，重返社会。</p>
        <p>咨询热线：18303213127 中医院西二楼就诊（急诊楼上）      </p>            
        <p>康复科宣传用词         鸡泽县中医院康复科全体人员曾学习于具有“北协和南湘雅”之美誉的中南大学湘雅二医院康复科。全面的把全国一流的康复技术带回了鸡泽，让您足不出县，就能享受全国一流的<p>康复治疗。做康复就到鸡泽县中医院，做康复我们是专业的。     </p>    
        <p>腰间盘突出,颈椎病不想做手术，不想打封闭，就到鸡泽县中医院康复科，采用国际上最先进的挪威悬吊技术配合传统的针灸牵引高频等治疗方法。治疗无痛苦，治疗无副作用，让您轻轻松松告别疾病困扰。   </p>     
        <p>网球肘，高尔夫球肘，足底筋膜炎，腱鞘炎，足跟骨刺，膝关节病变，肩周炎你还在忍受疼痛吗？你还在打激素吗？你还在吃止疼药吗？现在都不需要了，您只需要到鸡泽县中医院康复科，本科引进国内最先进的冲击波配合超声波治疗。绝无副作用 ，疗效显著！   </p>      
        <p>偏瘫，脑瘫，截瘫，如果您不想亲人终生躺在床上，不想让亲人人生灰暗，就到鸡泽县中医院康复科。给您亲人独立生活的能力，给您亲人幸福的人生。康复一人，幸福全家。  </p>       
        <p>做完手术，遭了！关节活动障碍了！东奔西走都没有好办法。不用担心，就到鸡泽县中医院康复科，本科采用来自澳大利亚的关节松动技术配合泥疗以及CPM，让您恢复正常的生活。</p>
        `,
      },
      intrarenal: {
        name: '肾内科',
        info: `<p>肾脏病是常见病和多发病，如果继续恶化可以发展成尿毒症，严重危害人们的健康，慢性肾病已经发展成继心脑血管病、肿瘤、糖尿病之后威胁人类健康的重要疾病，目前世界已超过5亿人患有各种肾脏病，成为全球公共卫生问题。        肾内科是为了诊疗肾脏病设置的一个临床科室，常见病有：急性肾小球肾炎、慢性肾小球肾炎、肾病综合症、急、慢性肾衰竭、高血压肾病、糖尿病肾病等。常见肾脏替代治疗有肾移植、血液透析和腹膜透析。        我科是我县唯一一家经市、县卫生局审批通过的正规血液透析中心，成立于2012年8月，在鸡泽县中医院病房楼一楼，透析室严格按照国家法规要求建设，布局合理，分区明确，环境温馨舒适。我科自建科以来坚持以“救死扶伤、服务人民”为宗旨，倡导“智慧透析，个体化透析”为目标，我中心现拥有血液透析机10余台，德国费森尤斯血液滤过机2台，并配有除颤仪、心电监护仪、血压仪、吸氧装置等急救设施，另配有轮椅、平车等工具为患者提供方便。现拥有专科医护人员10余名，均于北京武警总医院、邯郸市第一医院血液净化中心进行专业规范化培训，并考试合格。现已开展血液透析、血液透析滤过、血液灌流等技术，能救治各种急慢性肾衰竭、各种脓毒血症，为鸡泽县及周边地区肾病患者提供便利。</p>`,
      },
      oralCavity: {
        name: '口腔科',
        info: `<p>我院口腔科以临床医疗为主，引进先进的数字诊断技术，对多种口腔科疾病实施全面的普查、检查、诊疗及修复。   口腔科现拥有进口综合口腔治疗台、口腔科专用高压消毒机、荷兰牙钻注油机等多种先进设备。为降低就医风险，口腔科严把消毒质量关，所有用物均“一人一用一消毒/灭菌”，牙科手机“一人一机”，从而有效地控制医源性交叉感染。　科室口腔专业开展全面，主要诊疗项目：   1、治疗牙齿龋病、牙髓病、根尖周病、牙周病、口腔粘膜病及儿童牙病；   2、对牙体缺损进行烤瓷牙及铸造牙的修复。能够对牙列缺损进行活动义齿、固定义齿及隐形义齿的修复；   3、超声洁牙(洗牙)、对牙列缺损进行半口或全口义齿的修复；   4、对牙列畸形进行固定矫正，如，牙列不齐、牙列拥挤、错位等，使牙齿恢复美观和咀嚼功能；　5、对各类没有保留价值的牙齿进行拔除；   6、做牙槽外科小手术及口腔软组织小肿物摘除术。   另：我院长期聘请邯郸市口腔专家每周一全天坐诊。   口腔科全体医护人员将以我们的热心、关心、细心、耐心，换您的舒心、放心、安心、欢心。</p>`,
      },
      surgery: {
        name: '外二科',
        info: `<p>外科是一个专业梯队合理，诊疗规范，技术精湛，医德高尚，既有中医特色又能适应现代化医院发展要求，进行各种外科常规及复杂手术的专科。</p>
诊疗范围：</p>
        <p>各种烧伤烫伤，化学伤电烧伤，烧伤整形，疤痕去除术，褥疮，长期伤口不愈合，腰椎间盘突出，腰椎管狭窄症，腰椎滑脱，颈椎病等脊柱退变性疾病，骨性关节炎，类风湿性关节炎，骨坏死等骨与关节非化脓性炎症,人工髋、膝关节置换术，四肢骨折，关节镜微创手术（半月板缝合及修整术、关节清理术、关节内游离体及异物取出术、关节内骨折复位内固定术、滑膜切除术、韧带损伤的修复和重建术）；肝胆胰腹腔镜，前列腺肥大电切及下肢静脉曲张、疝气、胃穿孔、阑尾炎、痔疮、肛瘘、肛周脓肿等外科常见病多发病。</p>
        <p>服务宗旨：</p>
        <p>病友及家属担心的问题就是我们要具体解决的问题，我们将集中我们全科室医护工作者的智慧，看准病、用对药、开好刀，全力以赴尽早使病友恢复健康。</p>
        <p>目标追求：</p>
        <p>我们将以最好的服务理念和专业技能为广大病友的健康保驾护航。</p>
        `,
      },
      operation: {
        name: '中医院手术室',
        info: `<p>手术室是医院对病人实施手术、治疗、检查、诊断并担负抢救工作的重要场所。   我们的服务宗旨:让人性化护理模式在手术中广泛推广，要求全体医护人员以全方位的视角，以最佳的心理护理措施，用爱心、同情心和高度地责任心，确保病人的安全，保证手术的顺利进行。     手术室设立在住院楼六楼，现有手术间4个。其中百级层流手术间1个，千级层流手术间1个，万级层流手术间2个。麻醉苏醒室1间，每个手术间均配有先进的麻醉机、监护仪及抢救设备,并配有高频电刀，腹腔镜、可移动式C型臂X光机、可视喉镜等大批先进仪器设备，并有内镜清洗工作站。     “外科医生治病，麻醉医生保命”，这句话很形象地描述了麻醉医生在一台外科手术中的重要作用。很多人简单以为麻醉就是“打一针，睡一觉”，麻醉被视为一项“让人睡着的艺术”。作为常人的朴素看法，这虽平和易懂，但并不确切。麻醉远非那么简单。     中医院麻醉科以高质量开展全身麻醉（静脉麻醉、吸入麻醉、静吸复合麻醉）、硬膜外麻醉、腰硬联合麻醉、神经阻滞麻醉等麻醉技术。     近年来积极开展术后镇痛及癌症疼痛治疗，为病人提供舒适无痛的诊疗技术，及人性化的术后镇痛服务。    随着现代医学技术的不断发展，我科全体医务人员团结协作，大力倡导“安全、优质、高效”的围术期医学，为患者生命安全保驾护航。</p>
        `,
      }
    }

    const code =  this.props.match.params.code;

    this.setState({
      detailContent: detailData[code]
    })
  }

  render() {
    const quitTime = businessQuitTime.microService.departmentDetail;
    const { detailContent } = this.state;
    const { name, info } = detailContent;

    return (
      <div>
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <h2 className="crumbs">科室介绍 - { name }</h2>
          <div className="c-content-scroll">
            <div className="department-detail" dangerouslySetInnerHTML={{__html: info}}>
            </div>
          </div>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage.bind(this)} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage.bind(this)}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}
