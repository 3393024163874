import React, { Component } from 'react'
import Head from '../../components/head/'
import businessQuitTime from '../../utils/businessQuitTime'
import DigitalKeyboard from '../../components/digitalKeyboard/'
import { Button, Input, Icon, notification } from 'antd'
import { connect } from 'react-redux'
import * as Action from '@/store/actions/createCard'
import { makePatient } from '@/utils/request'
import './record.scss'
import { lisPrintFinish } from '../../utils/request'

/*
 * 输入手机号
 */
class InsertPhone extends Component {
  constructor(props){
    super(props);
    this.state = {
      phone: ''
    };

    this.getPhoneNumber = this.getPhoneNumber.bind(this);
    this.successInsPhone = this.successInsPhone.bind(this);
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/noCardConfirmIdCard')
  }

  getPhoneNumber(phone){
    this.setState({
      phone
    })
  }

  successInsPhone(){
    const phoneNum = this.state.phone;
    if(phoneNum.length === 0){
      notification.error({
        message: '提示',
        description: '请填写手机号码',
        duration: 10,
      });
      return false;
    }
    if(phoneNum.length+1 !== 12){
      notification.error({
        message: '提示',
        description: '请正确输入手机号码',
        duration: 10,
      });
      return false;
    }

    // 存储手机号码
    let createCardData = Object.assign(this.props.createCard, {phone: phoneNum});
    this.props.addCardData(createCardData);

    this.queryMakePatient()
  }

  queryMakePatient(){
    const that =  this
    const {phone, userInfo, idType='', nationality='', folk=''} = this.props.createCard
    const { COMP_NAME, NUMBER, SEX, BIRTHDAY, ADDRESS, PEOPLENATIONCODE } = userInfo
    let deviceId = localStorage.getItem('deviceId')
    if(deviceId){
      deviceId = Number(deviceId) < 10 ? `0${deviceId}` : deviceId
    }

    let sexs = SEX === '男' ? 1 : 2
    let params = {
      NAME: COMP_NAME,    // 患者姓名 
      BIRTHDAY,           // 患者生日
      SEX: sexs,          // 患者性别	字符串(1)	是	1代表男，2代表女,0未知，3女改男，4男改女
      CERTI_NO: NUMBER,   //	身份证号码
      H_TEL: phone,       //	患者手机号码
      H_ADDRESS: ADDRESS,
      FOLK: PEOPLENATIONCODE,
      op_code: deviceId,
      id_type: idType,
      nationality,
      folk,
    }

    makePatient(params)
    .then(od => {
      // data: [{MR_NO: "H159844061096603"}]
      if(od.status){
        debugger;
        params['MR_NO'] = od.data[0].MR_NO
        let createCardData = Object.assign(this.props.createCard, params);
        console.log('无卡建档 Store中createCard的值', createCardData)

        that.props.addCardData(createCardData);
        this.props.history.push('/printQrcode')
      }
      else{
        that.props.history.push({ 
          pathname : '/error',
          query : { messageTitle: '失败', messageContent: od.message}
        })
      }
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  render() {
    return (
      <div>
        <Head quitTime={businessQuitTime.createArchives.insertPhone} />

        <div className="context-container clearfix">
          <h2 className="crumbs">录入手机号</h2>
          <h3 className="business-name">请输入本人手机号码</h3>
          <div className="ins-phone">
            <Input size="large" placeholder="" value={this.state.phone} />
          </div>
          <DigitalKeyboard getKeyboardNumber={this.getPhoneNumber} confirmBtn={this.successInsPhone} />
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage.bind(this)} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage.bind(this)}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { base, createCard } = state
  return {
    base,
    createCard
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addCardData: (data) => {
      dispatch(Action.addCardData(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InsertPhone)
