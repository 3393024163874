import React, { Component } from 'react'
import { Button, Input, Icon, Modal, notification } from 'antd'
import Head from '../../components/head'
import DigitalKeyboard from '../../components/digitalKeyboard'
import businessQuitTime from '../../utils/businessQuitTime'
import { connect } from 'react-redux';
import * as rechargeAction from '../../store/actions/recharge'
import * as paymentAction from '../../store/actions/payment'
import './record.scss'

/*
 * 建档有卡费先充值
 */ 
class PayAmount extends Component {
  constructor(props){
    super(props);
    this.state = {
      amount: '',
      cardFee: '',
      amountDialog: false,
      userInfo: {},
    }

    this.goUpPage = this.goUpPage.bind(this);
    this.goHomePage = this.goHomePage.bind(this);
    this.sendPayAmount = this.sendPayAmount.bind(this);
    this.cancelAmountDialog = this.cancelAmountDialog.bind(this);
    this.getAmount = this.getAmount.bind(this);
    this.openAmountDialog = this.openAmountDialog.bind(this);
  }

  goHomePage(){
    this.props.history.push('/medicalCardRecharge')
  }
  goUpPage(){
    this.props.history.push('/')
  }

  componentDidMount(){
    const { cardFee } = this.props.payment
    this.setState({
      cardFee,
    })
  }

  // 获取金额
  getAmount(num){
    this.setState({
      amount: num
    })
  }

  openAmountDialog(){
    if(this.state.amount === ''){
      notification.error({
        message: '错误',
        description: '请输入充值金额',
        duration: 10
      });
      return false;
    }
    this.setState({
      amountDialog: true
    })
  }

  // 确认充值金额
  sendPayAmount(){
    const { amount, cardFee } = this.state
    let base = JSON.parse(localStorage.getItem('base'))
    let curPayment = {};
    if(this.props.payment !== null){
      curPayment = this.props.payment
    }
    // const totalAmount = parseFloat(amount) + parseFloat(cardFee)
    const payData = {
      amount: parseFloat(amount)
    }
    const payObj = Object.assign(curPayment, payData)
    this.props.addPayment(payObj);
    this.props.history.push('/payList');
  }

  cancelAmountDialog(){
    this.setState({
      amountDialog: false
    })
  }

  insertAmount(num){
    this.refs.amountKeyboard.clearInsertNumber(num)
  }

  render() {
    const quitTime = businessQuitTime.medicalCardRecharge.rechargeAmount;
    const { amount, amountDialog } = this.state;
    const { cardFee } = this.props.payment;
    const isDisableInput = cardFee > 0 ? true : false
    console.log('payAmount', isDisableInput)

    return (
      <div>
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <h3 className="business-name">充值 - 输入金额</h3>
          <div className="rec-amount-row clearfix">
            <div className="rec-amount-l">
              <Input size="large" prefix="￥" suffix="元" value={amount} />
              <div className="pay-group">
                <Button type="primary" size="large" onClick={() => this.insertAmount('10')}
                 style={{ "backgroundColor": "#ffc53d", border: "1px #ffc53d solid", "float": "left", "margin": "15px 12px 0 0"}}>10</Button>
                <Button type="primary" size="large" onClick={() => this.insertAmount('50')}
                 style={{ "backgroundColor": "#ffc53d", border: "1px #ffc53d solid", "float": "left", "margin": "15px 12px 0 0"}}>50</Button>
                <Button type="primary" size="large" onClick={() => this.insertAmount('100')}
                 style={{ "backgroundColor": "#ffc53d", border: "1px #ffc53d solid", "float": "left", "margin": "15px 12px 0 0"}}>100</Button>
                <Button type="primary" size="large" onClick={() => this.insertAmount('200')}
                 style={{ "backgroundColor": "#ffc53d", border: "1px #ffc53d solid", "float": "left", "margin": "15px 12px 0 0"}}>200</Button>
              </div>
            </div>
            <div className="rec-amount-r">
              <DigitalKeyboard getKeyboardNumber={this.getAmount} confirmBtn={this.openAmountDialog} ref="amountKeyboard" disableInput={isDisableInput} />
            </div>
          </div>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>     
        
        <Modal
          title="请确认充值金额"
          visible={amountDialog}
          onOk={this.sendPayAmount}
          onCancel={this.cancelAmountDialog}
          cancelText="取消"
          okText="确定">
          <p>充值金额为: { amount }元</p>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    recharge: state.recharge,
    cardInfo: state.cardInfo,
    payment: state.payment
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addRecharge: (data) => {
      dispatch(rechargeAction.addRecharge(data));
    },
    addPayment: (data) => {
      dispatch(paymentAction.addPayment(data))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayAmount)