import React, { Component } from 'react'
import Head from '../../components/head'
import { Button, Icon, DatePicker } from 'antd';
import businessQuitTime from '../../utils/businessQuitTime'
import locale from 'antd/lib/date-picker/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

export default class TodaySelectDate extends Component {
  constructor(props){
    super(props);
    this.state = {
      isOpen: true
    }
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/trPatientInfo')
  }

  onChangeDate(date, dateString){
  }

  getSelectDate(){
    this.props.history.push('/selectDoctor')
  }

  render() {
    const quitTime = businessQuitTime.todayRegister.todaySelectDate;

    return (
      <div>
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <h3 className="business-name">预约挂号 - 选择就诊日期</h3>
          <div className="select-data-cont">
            <DatePicker onChange={ this.onChangeDate.bind(this) } open={ this.state.isOpen } locale={locale} small="large" style={{marginTop: '20px'}} />
          </div>
          <div className="text-c">
            <Button type="primary" size="large" onClick={this.getSelectDate.bind(this)} style={{width: "400px", backgroundColor: "#00bd58", border: "1px #00bd58 solid", marginTop: "20px"}}>确 定</Button>
          </div>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage.bind(this)} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage.bind(this)}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}
