import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import Head from '../../components/head/'
import businessQuitTime from '../../utils/businessQuitTime'
import { Button, Icon, notification } from 'antd';
import { sendExitCard, cardDevStatus, getUserInfo } from '@/utils/request'
import ShowCardImg from '@/components/showCardImg'
import * as cardAction from '@/store/actions/createCard';
import * as baseAction from '../../store/actions/base'
import './record.scss'

class NoCard extends Component {
  constructor(props){
    super(props);
    this.state = {
    };
    this.goUpPage = this.goUpPage.bind(this);
    this.goHomePage = this.goHomePage.bind(this);
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/')
  }

  componentDidMount(){
    
  }

  // 获取设备信息
  queryCardDevStatus(){
    let that = this;

    cardDevStatus()
    .then(od => {
      let deviceInfo = null;
      let baseInfo = JSON.parse(localStorage.getItem('base'));
      
      if(od.status){
        deviceInfo = od.data;
      }

      baseInfo['device'] = deviceInfo;
        localStorage.setItem('base', JSON.stringify(baseInfo));
        that.isCardEmpty(deviceInfo);
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 查看卡箱是否有卡
  isCardEmpty(device){    
    if(device && device.BEMPTY === '1'){
      notification.error({
        message: `错误`,
        description: '卡箱已空请联系医院维护人员',
      });
      this.props.history.push('/');
    }
    else{
      this.isOperationArea(device);
    }
  }

  // 查看操作区是否有卡
  isOperationArea(device){
    if(device && device.BHANVECARD === '1'){
      notification.error({
        message: `错误`,
        description: '操作区有卡请退出卡片',
      });
      this.props.history.push('/')
    }
  }

  // 获取银行用户信息
  getBankInfo(){
    const that = this;
    let deviceId = JSON.parse(localStorage.getItem('deviceId'));

    getUserInfo({
      deviceId
    })
    .then(od => {
      if(od.status){
        that.props.addCardData(od.data);
        that.props.history.push(`/bankUserInfo`);
      }
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  render() {
    const { createArchives, readIdCard } = businessQuitTime.createArchives;
    const projectName = process.env.REACT_APP_PROJECT_CODE || '';
    console.log(`projectName: ${projectName}`)

    // 获取配置的卡列表
    const { base } = this.props;
    let createCardList = [];
    if(base){
      let newArchivesList = base.newArchivesList || [];
      newArchivesList.forEach((card) => {
        if(card.resourcesMode !== 0){
          createCardList.push(card)
        }
      })
    }

    return (
      <div>
        <Head quitTime={createArchives} />

        <div className="context-container clearfix">
          <h2 className="crumbs">患者管理 - 选择办卡介质</h2>
          <h3 className="business-name">请选择签约方式</h3>
          <div className="c-content">
            <div className="medium-List">
              {
                createCardList.map((medium) => {
                  return (
                    <Link to={`/readCard/noCardConfirmIdCard/${medium.id}/${createArchives}/103`} key={medium.id}>
                      <ShowCardImg cardId={medium.id} />
                      <i>{ medium.name }</i>
                    </Link>
                  )
                })
              }
            </div>
          </div>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    base: state.base,
    cardData: state.cardData
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addBase: (data) => {
      dispatch(baseAction.addBase(data));
    },
    addCardData: (data) => {
      dispatch(cardAction.addCardData(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NoCard);