import React, { Component } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Layout from '../views/layout'
import { createStore, compose } from 'redux'
import { Provider } from 'react-redux'
import reducer from '../store/reducers/'

const configState = {
  base: null,             // 全局基础配置信息
  cardInfo: null,         // 存储就诊卡相关信息
  payment: null,          // 支付公用
  createCard: null,       // 创建就诊卡业务
  recharge: null,         // 就诊卡充值业务
  inpatient: null,        // 住院业务
  outpatient: null,       // 门诊业务
  register: null,         // 挂号
  admin: null,            // 管理员后台
}

const storeEnhancers = compose(window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
const store = createStore(reducer, configState, storeEnhancers);

// 设置全局对象来接收Store
window.hisStore = store;

// 侦听store的变化
let listenerStore = function(){
  console.log(store.getState())
}
let unSubscribe = store.subscribe(listenerStore);
// unSubscribe();    // 取消侦听

class ConfigureRouter extends Component {
  constructor(props){
    super(props);
    this.state = {}
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <Switch>
            <React.Fragment>
              <div  className="main-container">
                <Layout />
              </div>
            </React.Fragment>
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default ConfigureRouter;