import React, { Component } from 'react'
import Head from '../../components/head'
import { Button, Icon } from 'antd';
import { connect } from 'react-redux';
import { printLisResult } from '@/utils/request'
import businessQuitTime from '../../utils/businessQuitTime'

/*
* 普通打印
*/ 
class OutpatientPrint extends Component {
  constructor(props){
    super(props);
    this.state = {
      resMessage: '正在打印中，请稍后',
      backNum: 3,
      timerBox: false,
    };
    this.goUpPage = this.goUpPage.bind(this);
    this.goHomePage = this.goHomePage.bind(this);
  }

  componentDidMount(){
    const that = this;
    const { currentCard, mr_no } = this.props.cardInfo;
    const params = {
      // para_type: currentCard.name,
      para_type: '门诊',
      pat_no: mr_no,
    }
    printLisResult(params)
    .then(od => {
      // 打印成功3秒后退出
      if(od.status){
        this.setState({timerBox: true}, ()=> this.timeoutBack())
      }
      that.setState({
        resMessage: od.message
      })
    })
    .catch(err => {
      this.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  timeoutBack(){
    let timer = setInterval(() => {
      const { backNum }= this.state
      let back = backNum-1

      this.setState({
        backNum: back
      })

      if(backNum === 0){
        clearInterval(timer)
        this.goHomePage()
      }
    }, 1000)
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/printOutpatientResult')
  }

  render() {
    const quitTime = businessQuitTime.printOutpatientResult.printOutpatientResult;
    const { resMessage, backNum, timerBox } = this.state;

    return (
      <div>
        <Head quitTime={quitTime} />
        <div className="context-container clearfix">
          <h2 className="crumbs">门诊检验结果查询打印</h2>
          <h1 className="f-col-11" style={{margin: "150px auto", textAlign: "center", fontSize: '50px'}}>
            { timerBox ? <h2 className="printOut">{backNum}秒后返回</h2> : '' }
            <p>{resMessage}</p>
          </h1>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    cardInfo: state.cardInfo,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OutpatientPrint)