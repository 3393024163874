import React, { Component } from 'react'
import { Button, Icon, notification } from 'antd';
import Head from '../../components/head'
import { connect } from 'react-redux';
import businessQuitTime from '../../utils/businessQuitTime'
import { printLisReport, lisPrintFinish } from '@/utils/request'
import './index.scss'

class nucleicAcidPrintResult extends Component {
  constructor(props){
    super(props);
    this.state = {
      printContext: '正在打印中请稍候...',
      quitTimer: 200,
    }
  }

  goHomePage(){
    this.props.history.push('/')
  }

  componentDidMount(){
    this.getPrintLisReport()
  }

  getPrintLisReport(){
    const that = this
    const cardInfo = this.props.createCard.NUMBER
    const sendParams = [
      {
        "病历号": cardInfo,
      }
    ]

    printLisReport(sendParams)
    .then(od => {
      if(od.status){
        that.sendLISPrintFinish(od)
      }
      else{
        that.setState({
          printContext: od.message,
          quitTimer: 3
        })
      }
    })
    .catch(err => {
      that.setState({
        quitTimer: 3
      })
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  sendLISPrintFinish(printData){
    const that = this
    if(printData.data.length > 0){
      printData.data.forEach(function(row){
        lisPrintFinish({
          "检验单号": row["检验单号"]
        })
        .then(od => {
          that.setState({
            printContext: printData.message,
            quitTimer: 3
          })
        })
        .catch(err => {
          that.setState({
            quitTimer: 3
          })
          that.props.history.push({ 
            pathname : '/error',
            query : { messageTitle: '失败', messageContent: err.toString()}
          })
        })
      })
    }
  }

  render() {
    const { printContext, quitTimer } = this.state

    return (
      <div>
        <Head quitTime={quitTimer} />

        <div className="context-container clearfix">
          <div className="status-box">
            <div className="print-ico">
              <i className="icon iconfont icon--tiaomadayin"></i>
            </div>
            <div className="print-text">
              <h1>{printContext}</h1>
            </div>
          </div>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goHomePage.bind(this)}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    base: state.base,
    createCard: state.createCard
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addCard: (data) => {
      dispatch(cardInfoAction.addCard(data));
    },
    addRecharge: (data) => {
      dispatch(rechargeAction.addRecharge(data))
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(nucleicAcidPrintResult)