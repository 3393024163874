import React, { Component } from 'react'
import Head from '../../components/head'
import businessQuitTime from '../../utils/businessQuitTime'
import {
  getHisPrescriptions, 
  getHisBalance, 
  sendHisPay, 
  getPayDetails, 
  printPayBill,
  insuPreSettle,
  insuSettle,
  printPayBillInsu,
} from '@/utils/request'
import { Button, Icon, Modal, Table, notification, Spin, Row, Col, Divider} from 'antd'
import { mapNames } from '@/utils/common'
import { connect } from 'react-redux'
import config from '@/config.js'
import { v4 as uuidv4 } from 'uuid';
import MedicalInsurPassword from '../../components/MedicalInsurPassword'
import * as cardInfoAction from '../../store/actions/cardInfo'
import * as outpatientAction from '../../store/actions/outpatient'
import * as paymentAction from '../../store/actions/payment'
import './outpatientPay.scss'

let isOpenPay = false

/*
 * 门诊处方列表
 */
class PayMentList extends Component {
  constructor(props){
    super(props)

    this.state = {
      payDialog: false,
      loading: false,
      spinLoading: false,
      selectedRowKeys: [],    // 存放选中的处方
      selectPrescribe: [],    // 存储诊挂费，缴费时必选
      totalAmount: 0,         // 总价格
      queryInfo: {
        pageSize: 7
      },
      payMentData: [],
      columns: [
        {
          title: '时间',
          width: 200,
          dataIndex: 'CL_DATE',
        }, 
        {
          title: '就诊号',
          width: 150,
          dataIndex: 'CL_NO',
        }, 
        {
          title: '处方号',
          width: 150,
          dataIndex: 'RE_NO',
        }, 
        {
          title: '姓名',
          width: 200,
          align: 'center',
          dataIndex: 'P_NAME',
        },
        {
          title: '处方类别',
          width: 100,
          align: 'center',
          dataIndex: 'RE_KIND',
        },
        {
          title: '处方金额',
          width: 110,
          align: 'right',
          dataIndex: 'RE_COST',
        },
        {
          title: '操作',
          width: 110,
          render:(text,record,index) => {
            return <Button type="primary" onClick={() => this.queryDetail(record)}>查看明细</Button>
          }
        }
      ],
      detalDialog: false,
      payDetailData: [],
      detailColumns: [
        {
          title: '序号',
          width: '30',
          render:(text,record,index)=> `${index+1}`,
        },
        {
          title: '项目名称',
          dataIndex: 'M_NAME',
        },
        {
          title: '项目数量',
          dataIndex: 'M_COUNT',
        },
        {
          title: '规格',
          dataIndex: 'M_SPEC',
        },
        {
          title: '金额',
          dataIndex: 'M_COST',
        },
      ],
      balance: '0',   // 卡余额

      /** 医保结算 begin **/
      insurDialog: false, // 医保弹窗
      preSettle: {
        batchNo: '',    // 预结算批次号
        feeType: '',    // 费用类别（医保、自费）
        costOrder: '',  // 费用总额
        fundPay: '',    // 医保支付金额
        accPay: '',     // 个人支付金额
        cashPay: '',    // 应付现金
        mrPay: '',      // 院内账户支付
        onlinePay: '',  // 线上支付
        insurPassword: '',  // 医保密码
      },
      insPasswordCount: 1,  // 输入医保密码次数
      /** 医保结算 end **/
    }

    this.sendPayAmount = this.sendPayAmount.bind(this)
    this.openPayDialog = this.openPayDialog.bind(this)
    this.goUpPage = this.goUpPage.bind(this)
    this.goHomePage = this.goHomePage.bind(this)
    this.closeDetailDialog = this.closeDetailDialog.bind(this)
  }

  componentDidMount() {
    isOpenPay = false
    this.getPayMentList()
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/opPatientInfo')
  }

  // 获取列表数据
  getPayMentList(){
    const that = this
    const mr_no = this.props.cardInfo.mr_no

    getHisPrescriptions({
      mr_no
    })
    .then(od => {
      if(od.status){
        let payMentData = []
        let selectPrescribe = []
        let selectedRowKeys = []
        let amount = 0

        od.data.forEach((item, idx, arr) => {
          item['RE_KIND'] = mapNames.prescription[item['RE_KIND']]
          item['key'] = idx
          payMentData.push(item)
          if(item.RE_TYPE === 'REG'){
            selectPrescribe.push(item)
            // amount += parseFloat(item.RE_COST)
          }

          item['isTrue'] = true

          // 默认全选
          amount += parseFloat(item.RE_COST)
          selectedRowKeys.push(idx)
        })
        
        that.setState({
          payMentData,
          totalAmount: amount.toFixed(2),
          selectPrescribe,
          selectedRowKeys
        })
      }
      that.setState({
        loading: false
      })
    })
    .catch(err => {
      that.setState({
        loading: false
      })
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 处理选中项
  onSelectChange = (selectedRowKeys, selectedRows) => {
    const { selectPrescribe, payMentData } = this.state
    let totalAmount = 0
    let rowKeys = []

    payMentData.forEach((item) => {
      item['isTrue'] = false
    })


    if(selectedRows.length === 0) {
      this.setState({
        selectedRowKeys: [],
        totalAmount: 0
      })
    }

    selectedRows.forEach((row) => {
      rowKeys.push(row.key)
    })

    // 存储非REG类型处方，CL_NO与REG类型的CL_NO相同，并且非选中状态
    if(selectPrescribe.length > 0){
      selectedRows.forEach((rowkey) => {
        selectPrescribe.forEach((pre) => {
          let result = rowKeys.some(function(rd) {
            return rd === pre.key
          })  
          if(pre.CL_NO === rowkey.CL_NO && !result){
            rowKeys.push(pre.key)
          }
        })
      })
    }

    // 计算总金额
    rowKeys.forEach((row) => {
      totalAmount += parseFloat(payMentData[row].RE_COST)     
    })
    totalAmount = this.twoDecimal(totalAmount)

    // 将选中项默认选中状态
    payMentData.forEach((payItem) => {
      let isTrue = rowKeys.some((keyItem) => {
        return payItem.key === keyItem
      })
      payItem.isTrue = isTrue
    })

    this.setState({
      selectedRowKeys: rowKeys,
      totalAmount,
      payMentData
    })
  }

  twoDecimal(num){
    return Math.round(num*100)/100
  }
  
  // 打开确认支付窗口
  openPayDialog(){
    const base = JSON.parse(localStorage.getItem('base'))
    const { currentCard, userInfo } = this.props.cardInfo
    const { selectedRowKeys, payMentData, totalAmount } = this.state

    if(selectedRowKeys.length === 0){
      notification.error({
        message: '提示',
        description: '请选择一项缴费处方',
        duration: 10,
      })
      return false
    }

    // 医院不使用医保结算，走老的缴费
    if(!base.isMedicalSettle) {
      // 如果有医保进行医保结算，无论是否使用读取医保卡
      this.outpNormalPay()
    }
    else{
      this.outpInsurPay()
    }
  }

  /**** 非医保支付 begin ****/
  // 非医保支付
  outpNormalPay(){
    const { selectedRowKeys, payMentData, totalAmount } = this.state

    let paymentList = []
    selectedRowKeys.map((idx) => {
      paymentList.push(payMentData[idx])
      return idx
    })
    this.props.addOutpatient({  // 详情
      amount: totalAmount,
      records: paymentList,
    })

    const { currentCard } = this.props.cardInfo
    if(currentCard.payMode !== 0){  // 就诊卡支付
      this.queryBalance(() => {
        // 打开支付窗口
        this.setState({
          payDialog: true
        })
      })
      this.setState({
        payDialog: true
      })
    }
    else{  // 外部支付
      let curPayment = {}
      const { totalAmount } = this.state

      if(this.props.payment !== null){
        curPayment = this.props.payment
      }
      const payObj = Object.assign(curPayment, {
        businessName: '门诊缴费',
        businessType: 'payBill',
        printTitle: '门诊缴费',
        amount: totalAmount,
        paymentList,
      })
      this.props.addPayment(payObj)
      this.props.history.push('/payList')
    }
  }

  // 提交支付（非医保）
  sendPayAmount() {
    const that = this
    const { totalAmount, balance } = this.state
    const base = JSON.parse(localStorage.getItem('base'))
    const { deviceId } = base
    const deviceNum = (Number(deviceId) < 10) ? `0${deviceId}` : deviceId

    let sendParams = [],
        mr_no = this.props.cardInfo.mr_no,
        payData = that.state.payMentData,
        selectTerm = this.state.selectedRowKeys;

    // 判断余额是否充足
    if(Number(balance) < Number(totalAmount)){
      notification.error({
        message: '提示',
        description: '就诊卡余额不足',
        duration: 3,
      })

      common.setLocatStore('payOrigin', '门诊缴费充值')

      // 保存选中的缴费处方
      setTimeout(function(){
        that.props.history.push({ 
          pathname : '/rechargeAmount',
          query : { amount: parseFloat(totalAmount - balance).toFixed(2) } 
        })
      }, 2000)
      return false
    }

    selectTerm.forEach((item) => {
      let num = Number(item)
      let param = {
        mr_no,
        pwd: '',
        Re_no: payData[num].RE_NO,
        Re_cost: payData[num].RE_COST,
        SE_CODE: payData[num].SE_CODE,
        op_code: deviceNum,
        RE_TYPE: payData[num].RE_TYPE,
        CL_NO: payData[num].CL_NO,
      }
      sendParams.push(param)
    })

    this.props.addOutpatient({
      amount: totalAmount,
      records: sendParams,
    })

    this.setState({
      spinLoading: true,
      payDialog: false,
    }, () => {
      sendHisPay(sendParams)
      .then(od => {
        isOpenPay = false
        if(od.status){
          that.props.cardInfo.balance = od.data[0].BALANCE
          that.printOutpatientPaymentOrder(od.data[0].XF_SERIAL)
        }
        this.setState({
          spinLoading: false
        })
      })
      .catch(err => {
        isOpenPay = false
        this.setState({
          spinLoading: false
        })
        that.props.history.push({
          pathname : '/error',
          query : { messageTitle: '失败', messageContent: err.toString()}
        })
      })
    })
  }
  /**** 非医保支付 end ****/

  /**** 医保支付 begin ****/
  // 医保支付拆解
  outpInsurPay() {
    const { selectedRowKeys, payMentData, totalAmount } = this.state
    const { currentCard, userInfo } = this.props.cardInfo

    this.setState({
      spinLoading: true
    })

    let paymentList = []
    selectedRowKeys.map((idx) => {
      paymentList.push(payMentData[idx])
      return idx
    })
    this.props.addOutpatient({  // 详情
      amount: totalAmount,
      records: paymentList,
    })

    let reNos = []
    paymentList.forEach(payList => {
      reNos.push(payList.RE_NO)
    })

    // 生成uuid
    const uidVal = uuidv4().replace(/-/g, '')

    const params = {
      re_list: reNos.join('|'),
      psn_no: userInfo.PSN_NO || '',
      req_id: uidVal,
    }
    console.log('门诊缴费列表 InsuPreSettle 请求参数：', params)

    insuPreSettle(params)
      .then((res) => {
        console.log('门诊缴费列表 InsuPreSettle 响应参数：', res)
        this.setState({
          spinLoading: false
        })

        if(res.status) {
          const {
            BATCH_NO,
            FEE_TYPE,
            COST_ORDER,
            FUND_PAY,
            ACC_PAY,
            CASH_PAY,
            MR_PAY,
            ONLINE_PAY } = res.data[0]

          this.setState({
            insurDialog: true,
            totalAmount: COST_ORDER,
            preSettle: {
              batchNo: BATCH_NO,
              feeType: FEE_TYPE,
              costOrder: COST_ORDER,
              fundPay: FUND_PAY,   // 医保支付金额
              accPay: ACC_PAY,    // 个人支付金额
              cashPay: CASH_PAY,  // 应付现金
              mrPay: MR_PAY,      // 院内账户支付
              onlinePay: ONLINE_PAY,  // 线上支付
              insurPassword: '',      // 医保密码
            }
          })
        }
      })
  }

  // 打开医保输入密码
  openInsurPassword(){
    const { preSettle } = this.state

    if(preSettle.feeType == '医保'){
      this.refs.isurPsRef.openModal() // 医保需要调起密码输入
    }
    else{
      this.sendInsurPayAmount()
    }
  }

  confirmInsurPasswrodpsText(passwordText){
    const { preSettle, insPasswordCount } = this.state

    if(insPasswordCount > 3) {
      notification.error({
        message: `提示`,
        description: '医保密码输入次数已超过3次',
        duration: 10,
      });
      return ;
    }
    
    if(passwordText !== ''){
      this.setState({
        preSettle: {
          ...preSettle,
          insurPassword: passwordText
        }
      }, () => {
        this.sendInsurPayAmount()
      })
    }
  }

  // 提交医保支付
  sendInsurPayAmount(){
    const that = this
    const { preSettle, payMentData, selectedRowKeys, insPasswordCount } = this.state
    const base = JSON.parse(localStorage.getItem('base'))
    const { deviceId, hospitalInfo } = base
    const deviceNum = (Number(deviceId) < 10) ? `0${deviceId}` : deviceId
    const {
      batchNo='',    // 预结算批次号
      feeType='',    // 费用类别（医保、自费）
      costOrder='',  // 费用总额
      fundPay='',    // 医保支付金额
      accPay='',     // 医保个人账户支付金额
      cashPay='',    // 应付现金
      mrPay='',      // 院内账户支付
      onlinePay='',  // 线上支付
      insurPassword='',  // 医保密码
    } = preSettle

    let detailList = [];
    let mr_no = this.props.cardInfo.mr_no;
    let payData = that.state.payMentData;
    let selectTerm = this.state.selectedRowKeys;
    let paymentList = []

    selectedRowKeys.map((idx) => {
      paymentList.push(payMentData[idx])
    })

    // 如果线上支付部分大于0，需要跳到支付列表
    if(onlinePay > 0){
      // 存储缴费信息
      const payObj = {
        businessName: '门诊缴费',
        businessType: 'payBill',
        printTitle: '门诊缴费',
        amount: onlinePay,
        paymentList,
        preSettle 
      }
      this.props.addPayment(payObj)
      
      this.props.history.push('/payList')
      return ;
    }

    // 如果没有个人需要支付的问题直接调用门诊缴费医保新接口
    selectTerm.forEach((item) => {
      let num = Number(item)
      let param = {
        mr_no,
        pwd: '',
        Re_no: payData[num].RE_NO,
        Re_cost: payData[num].RE_COST,
        SE_CODE: payData[num].SE_CODE,
        op_code: deviceNum,
        RE_TYPE: payData[num].RE_TYPE,
        CL_NO: payData[num].CL_NO,
      }
      detailList.push(param)
    })

    this.props.addOutpatient({
      amount: preSettle.cashPay,
      records: detailList,
    })

    let params = {
      batch_no: batchNo,
      pwd: insurPassword,
      order_id: '',
      trans_id: '',
      pay_lb: '',
      pay_type: '01',   // 农商行05、其它传01
      cost: onlinePay,
      op_code: deviceNum,
      mr_no: that.props.cardInfo.mr_no
    };
    console.log('门诊缴费列表 InsuSettle 请求：', params)

    that.setState({
      spinLoading: true,
      insurDialog: false,
    }, () => {
      insuSettle(params)
        .then((res) => {
          console.log('门诊缴费列表 InsuSettle 响应：', res)
          
          isOpenPay = false
          that.setState({ spinLoading: false })

          if(res.status && res.data){
            // 存储缴费信息
            const payObj = {
              businessName: '门诊缴费',
              businessType: 'payBill',
              printTitle: '门诊缴费',
              amount: onlinePay,   // cashPay,
              paymentList,
              preSettle,
              einvInfo: res.data[0].einv_info || '',    // 电子发票使用
            }
            that.props.addPayment(payObj)
            
            // 打印医保支付凭条
            that.printSettlePayBillInsu(res.data[0])
          }
          else{
            // 打开医保密码窗口，记录医保密码输入次数
            that.setState({
              insPasswordCount: insPasswordCount+1
            })
            that.refs.isurPsRef.openModal()
          }
        })
        .catch(err => {
          isOpenPay = false
          that.setState({
            spinLoading: false
          })
          that.props.history.push({
            pathname : '/error',
            query : { messageTitle: '失败', messageContent: err.toString()}
          })
        })
    })
  }

  // 打印医保缴费凭条
  printSettlePayBillInsu(od){
    const that = this
    const { userInfo } = this.props.cardInfo
    const base = JSON.parse(localStorage.getItem('base'))
    const { deviceId, hospitalInfo } = base
    const deviceNum = (Number(deviceId) < 10) ? `0${deviceId}` : deviceId

    const {
      PAT_NAME = '',
      MR_NO = '',
      FUND_PAY = '',
      ACC_PAY = '',
      BALC_INS = '',
      MR_PAY = '',
      ONLINE_PAY = '',
      CHARGE_TIME = '',
      COST_ORDER = '',
      FEE_TYPE = '',
    } = od
    
    const params = {
      HOS_NAME: hospitalInfo.value,
      P_NAME: PAT_NAME,
      ORDER_COST: COST_ORDER,
      FEE_TYPE,
      FUND_PAY,
      ACC_PAY,
      BALC_INS,
      MR_PAY,
      BALC_MR: userInfo.BALC_MR,
      ONLINE_PAY,
      OP_CODE: `自助${deviceNum}`,
      CHARGE_TIME,
    }
    console.log('门诊缴费医保缴费凭条打印 printPayBillInsu 请求参数：', params)

    printPayBillInsu(params)
      .then(res => {
        console.log('门诊缴费医保缴费凭条打印 printPayBillInsu 响应参数：', res)
        isOpenPay = false
        if(res.status){
          // 打印缴费明细
          that.getPayDetailList()
        }
      })
      .catch(err => {
        isOpenPay = false
        that.props.history.push({
          pathname : '/error',
          query : { messageTitle: '失败', messageContent: err.toString()}
        })
      })

  }
  /**** 医保支付 end ****/

  // 门诊缴费支付成功需要先打印支付账单
  printOutpatientPaymentOrder(serial){
    const that = this
    const { payment, cardInfo, outpatient, base } = this.props
    const { userInfo, display_no } = cardInfo
    const { deviceId, hospitalInfo } = base
    const deviceNum = (Number(deviceId) < 10) ? `0${deviceId}` : deviceId
    
    let sendParams = {
      HOS_NAME: hospitalInfo.value,
      P_NAME: userInfo.NAME,
      ORDER_ID: serial,
      CZ_TYPE: '就诊卡',
      COST: outpatient.amount,
      BALANCE: cardInfo.balance,
      OP_CODE: `自助${deviceNum}`,
    }

    if(cardInfo.currentCard.id === 'jzcard'){
      sendParams['DISPLAY_NO'] = display_no
    }
    printPayBill(sendParams)
    .then((res) => {
      // 跳转明细页面
      if(res.status){
        that.getPayDetailList()
      }
      else{
        notification.error({
          message: '提示',
          description: res.message,
          duration: 10,
        })
      }
    })
  }

  // 获取缴费明细数据
  getPayDetailList(){
    const that = this
    const { records, amount } = this.props.outpatient

    getPayDetails(records)
    .then(od => {
      od.data.forEach((detail, index) => {
        detail['key'] = index
      })

      that.props.addOutpatient({
        amount,
        recordDetail: od.data
      })

      that.printPayDetail()
    })
    .catch(err => {
      that.setState({
        loading: false
      })
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 跳转到打印详单
  printPayDetail(){
    const { amount } = this.props.outpatient
    const { payment } = this.props

    this.props.addPayment({
      businessType: 'payBill',
      printTitle: '门诊缴费',
      amount,
      ...payment,
    })
    this.props.history.push('/payPrint')
  }

  // 获取卡余额
  queryBalance(){
    let that = this,
        mr_no = this.props.cardInfo.mr_no

    getHisBalance({
      mr_no,
      pwd: '',
    })
    .then(od => {
      let balance = od.status ? od.data[0].BALANCE : 0

      that.setState({
        balance,
      })
      let createCardInfo = Object.assign(this.props.cardInfo, {
        balance
      })
      that.props.addCard(createCardInfo)

      // 打开支付窗口
      this.setState({
        payDialog: true
      })
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 获取一条记录的详情
  queryDetail(record){
    const that = this
    const { deviceId } = this.props.base
    const { RE_NO, RE_COST } = record
    const deviceNum = (Number(deviceId) < 10) ? `0${deviceId}` : deviceId
    let records = []

    records.push({
      Re_no: RE_NO,
      Re_cost: RE_COST,
      op_code: deviceNum
    })

    getPayDetails(records)
    .then(od => {
      let payDetailData = []
      od.data.forEach((pay, index) => {
        pay['key'] = index
        payDetailData.push(pay)
      })

      that.setState({
        detalDialog: true,
        payDetailData
      })
    })
    .catch(err => {
      that.setState({
        loading: false
      })
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  closeDetailDialog(){
    this.setState({
      payDetailData: [],
      detalDialog: false
    })
  }

  render() {
    const that = this
    const { 
      selectedRowKeys,
      queryInfo,
      columns,
      payMentData,
      totalAmount,
      payDialog,
      balance,
      detalDialog,
      detailColumns,
      payDetailData,
      spinLoading,
      loading,
      insurDialog,
      preSettle
    } = this.state

    const selectData = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps(record){
        return {
          defaultChecked: record.isTrue, // 配置默认勾选的列 （Bealean）
        };
      },
      hideDefaultSelections: false,
    }
    const quitTime = businessQuitTime.outpatientPay.payMentList

    return (
      <Spin spinning={spinLoading} tip="正在处理中请稍后...">
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <h3 className="business-name">门诊缴费 - 待交费处方</h3>
          <Table
            rowSelection={ selectData }
            columns={columns}
            dataSource={payMentData}
            loading={loading}
            scroll={{
              y: 465
            }}
            pagination={false}
            className="mt-25" />

          <div className="pay-balance">
            <em>合计: { totalAmount } 元</em>
            <em>
              <Button type="primary" size="large" onClick={this.openPayDialog} style={{backgroundColor: "#00bd58", border: "1px #00bd58 solid", marginTop: "20px"}}>去支付</Button>
            </em>
          </div>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>

        {/* 非医保结算 */}
        <Modal
          style={config.modalTop}
          title="确认支付"
          visible={payDialog}
          onOk={() => this.sendPayAmount()}
          onCancel={() => this.setState({payDialog: false})}
          cancelText="取消"
          okText="确定">
          <p>总共需要支付金额: <span className="f-col-1">{ totalAmount }元</span></p>
          <p>卡余额: <span className="f-col-1">{ balance }元</span></p>
          {
            parseFloat(totalAmount) > parseFloat(balance) ? <p>需补缴: <span className="f-col-1">{ parseFloat(totalAmount - balance).toFixed(2) }元</span></p> : ''
          }
        </Modal>

         {/* 医保结算 */}
         <Modal
          style={config.modalTop}
          title="确认支付"
          visible={insurDialog}
          onOk={() => this.openInsurPassword()}
          onCancel={() => this.setState({insurDialog: false})}
          cancelText="取消"
          okText="去支付">
            <div className="wid200">
              <Row gutter={[30, 16]}>
                <Col className="gutter-row" span={12}>
                  <div className="insurLab"><strong>费用总额：</strong></div>
                </Col>
                <Col className="gutter-row" span={12}>
                  <div className="insurLab"><strong>{ totalAmount }元</strong></div>
                </Col>
              </Row>
              <Divider />
              <Row gutter={[30, 16]}>
                <Col className="gutter-row" span={12}>
                  <div className="insurLab">医保基金支付：</div>
                </Col>
                <Col className="gutter-row" span={12}>
                  <div className="insurLab">{ preSettle.fundPay }元</div>
                </Col>
                <Col className="gutter-row" span={12}>
                  <div className="insurLab">个人账户支付：</div>
                </Col>
                <Col className="gutter-row" span={12}>
                  <div className="insurLab">{ preSettle.accPay }元</div>
                </Col>
                <Col className="gutter-row" span={12}>
                  <div className="insurLab">应付现金：</div>
                </Col>
                <Col className="gutter-row" span={12}>
                  <div className="insurLab">{ preSettle.cashPay }元</div>
                </Col>
                <Col className="gutter-row" span={12}>
                  <div className="insurLab">院内账户支付：</div>
                </Col>
                <Col className="gutter-row" span={12}>
                  <div className="insurLab">{ preSettle.mrPay }元</div>
                </Col>
                <Col className="gutter-row" span={12}>
                  <div className="insurLab2" style={{fontSize: '24px'}}>线上支付：</div>
                </Col>
                <Col className="gutter-row" span={12}>
                  <div className="insurLab2" style={{fontSize: '24px'}}>{ preSettle.onlinePay }元</div>
                </Col>
              </Row>
            </div>
        </Modal>

        {/* 医保密码输入 */}
        <MedicalInsurPassword 
          ref="isurPsRef"
          confirmInsurPasswrod={(psText) => this.confirmInsurPasswrodpsText(psText)} 
        />

        <Modal
          title="处方明细"
          width="900px"
          visible={detalDialog}
          onOk={ this.closeDetailDialog }
          onCancel={this.closeDetailDialog }
          cancelText="取消"
          okText="确定">
          <div style={{height: "300px", "overflowY": "scroll"}}>
            <Table 
              columns={detailColumns} 
              dataSource={payDetailData} 
              loading={loading}
              className="mt-25" />
          </div>
        </Modal>
      </Spin>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { cardInfo, pay, base, payment, outpatient } = state
  return {
    cardInfo,
    pay,
    base,
    payment,
    outpatient
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addCard: (data) => {
      dispatch(cardInfoAction.addCard(data))
    },
    addOutpatient: (data) => {
      dispatch(outpatientAction.addOutpatient(data))
    },
    addPayment: (data) => {
      dispatch(paymentAction.addPayment(data))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayMentList)