import React, { Component } from 'react'
import Head from '../../components/head'
import DigitalKeyboard from '../../components/digitalKeyboard'
import { Button, Icon, Input, notification } from 'antd'
import businessQuitTime from '../../utils/businessQuitTime'
import { connect } from 'react-redux'
import * as inpatientAction from '../../store/actions/inpatient'
import * as paymentAction from '../../store/actions/payment'
import './inpatientPay.scss'

/*
 * 住院押金缴纳
 */
class InpatientDeposit extends Component {
  constructor(props){
    super(props)
    this.state = {
      depositAmount: ''
    }

    this.goUpPage = this.goUpPage.bind(this)
    this.goHomePage = this.goHomePage.bind(this)
    this.getKeyboardNumber = this.getKeyboardNumber.bind(this)
    this.regDeposit = this.regDeposit.bind(this)
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/')
  }

  getKeyboardNumber(num){
    this.setState({
      depositAmount: num
    })
  }

  regDeposit(){
    let depositAmount = this.state.depositAmount
    
    if(depositAmount.length === 0){
      notification.error({
        message: `错误`,
        description: '请输入缴纳的金额',
        duration: 10,
      })
      return false
    }

    if(Number(depositAmount) === 0){
      notification.error({
        message: `错误`,
        description: '请输入缴纳不能为0',
        duration: 10,
      })
      return false
    }

    const paymentObj = {
      businessName: '住院押金缴费',
      businessType: 'inpatient',
      printTitle: '缴费',
      amount: depositAmount,
    }

    this.props.addPayment(paymentObj)
    this.props.history.push('/payList')
  }

  render() {
    const quitTime = businessQuitTime.inpatientPay.hpPatientInfo
    const { depositAmount } = this.state

    return (
      <div>
        <Head quitTime={quitTime} />
        
        <div className="context-container clearfix">
          <h3 className="business-name">住院押金 - 输入缴纳住院押金金额</h3>
          <div className="h-p-c">
            <Input size="large" placeholder="" value={depositAmount} style={{margin: "20px 0"}} />
            <DigitalKeyboard getKeyboardNumber={this.getKeyboardNumber} confirmBtn={this.regDeposit} />
          </div>
        </div>
        
        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    inpatient: state.inpatient,
    payment: state.payment,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addInpatient: (data) => {
      dispatch(inpatientAction.addInpatient(data))
    },
    addPayment: (data) => {
      dispatch(paymentAction.addPayment(data))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InpatientDeposit)