import React, { Component } from 'react'
import { Button, Input } from 'antd';
import Head from '../head/'

import './selectCard.scss'
import JzkUrl from '../../assets/img/jzk.png'

// 就诊卡
export default class VisitCard extends Component {

  constructor(props){
    super(props);
    this.state = {
      cardNumber: ''
    }
  }

  goHomePage(){
    this.props.history.push('/main')
  }
  goUpPage(){
    this.props.history.push('/selectCard')
  }
  goPayment(){
    this.props.history.push('/payMent')
  }

  changeCardNumber(e){
    let val = e.target.value;
    this.setState({
      cardNumber: val
    })
  }

  // 模拟器
  insNumber(num){
    let curNum = this.state.cardNumber
    this.setState({
      cardNumber: curNum+num
    })
  }
  deletePopNumber(){
    let curNum = this.state.cardNumber
    if(curNum === ''){
      return false;
    }

    curNum = curNum.substring(0, curNum.length - 1);
    
    this.setState({
      cardNumber: curNum
    })
  }
  clearNumber(){
    this.setState({
      cardNumber: ''
    })
  }

  render() {
    return (
      <div>
        <Head />

        <div className="card-box clearfix">
          <h1>请插入您的医保卡</h1>
          <div className="vis-card-box">
            <Input type="text" value={this.state.cardNumber} onChange={this.changeCardNumber.bind(this)} size="large"  />
          </div>

          <div className="clearfix">
            <div className="vis-img">
              <img src={JzkUrl} alt="" />
            </div>
            <div className="inp-ins-box vis-ins">
              <span onClick={this.insNumber.bind(this, "1")}>1</span>
              <span onClick={this.insNumber.bind(this, '2')}>2</span>
              <span onClick={this.insNumber.bind(this, '3')}>3</span>
              <span onClick={this.insNumber.bind(this, '4')}>4</span>
              <span onClick={this.insNumber.bind(this, '5')}>5</span>
              <span onClick={this.insNumber.bind(this, '6')}>6</span>
              <span onClick={this.insNumber.bind(this, '7')}>7</span>
              <span onClick={this.insNumber.bind(this, '8')}>8</span>
              <span onClick={this.insNumber.bind(this, '9')}>9</span>
              <span onClick={this.deletePopNumber.bind(this)}>退格</span>
              <span onClick={this.insNumber.bind(this, '0')}>0</span>
              <span onClick={this.clearNumber.bind(this)}>删除</span>
            </div>
          </div>
        </div>

        <div>
          <Button type="primary" size="large" onClick={this.goHomePage.bind(this)}
          style={{width:'150px', height: '50px', float: "right", marginRight: "40px", marginTop: "10px", fontSize: "20px"}}>退 出</Button>
          <Button type="primary" size="large" onClick={this.goUpPage.bind(this)}
          style={{width:'150px', height: '50px', float: "right", marginRight: "40px", marginTop: "10px", fontSize: "20px"}}>返 回</Button>
          <Button type="primary" size="large" onClick={this.goPayment.bind(this)}
              style={{width:'150px', height: '50px', float: "right", marginRight: "40px", marginTop: "10px", fontSize: "20px"}}>确 定</Button>
        </div>
      </div>
    )
  }
}
