import React, { Component } from 'react'
import Head from '../../components/head/'
import DigitalKeyboard from '../../components/digitalKeyboard/'
import businessQuitTime from '../../utils/businessQuitTime'
import { connect } from 'react-redux';
import * as rechargeAction from '../../store/actions/recharge'
import * as paymentAction from '../../store/actions/payment'
import { Button, Input, Icon, Modal, notification } from 'antd';
import './medicalCardRecharge.scss'

/*
 * 输入充值到就诊卡金额
 */ 
class RechargeAmount extends Component {
  constructor(props){
    super(props);
    this.state = {
      amount: '',
      amountDialog: false,
      userInfo: {},
    }

    this.goUpPage = this.goUpPage.bind(this);
    this.goHomePage = this.goHomePage.bind(this);
    this.sendPayAmount = this.sendPayAmount.bind(this);
    this.cancelAmountDialog = this.cancelAmountDialog.bind(this);
    this.getAmount = this.getAmount.bind(this);
    this.openAmountDialog = this.openAmountDialog.bind(this);
  }

  goHomePage(){
    this.props.history.push('/medicalCardRecharge')
  }
  goUpPage(){
    this.props.history.push('/')
  }

  componentDidMount(){
    this.setState({
      userInfo : this.props.cardInfo.userInfo
    })
  }

  // 获取金额
  getAmount(num){
    this.setState({
      amount: num
    })
  }

  openAmountDialog(){
    if(this.state.amount === ''){
      notification.error({
        message: '错误',
        description: '请输入充值金额',
        duration: 10
      });
      return false;
    }
    this.setState({
      amountDialog: true
    })
  }

  // 确认充值金额
  sendPayAmount(){
    // let createRecharge = Object.assign(this.props.recharge, {
    //   payAmount: Number(this.state.amount)
    // })
    
    // this.props.addRecharge(createRecharge);
    let curPayment = {};
    if(this.props.payment !== null){
      curPayment = this.props.payment
    }
    const payObj = Object.assign(curPayment, {
      businessName: '就诊卡充值',
      businessType: 'recharge',
      printTitle: '充值',
      amount: Number(this.state.amount)
    })
    this.props.addPayment(payObj);
    this.props.history.push('/payList');
  }

  cancelAmountDialog(){
    this.setState({
      amountDialog: false
    })
  }

  render() {
    const quitTime = businessQuitTime.medicalCardRecharge.rechargeAmount;
    const { userInfo, amount, amountDialog } = this.state;

    return (
      <div>
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <h3 className="business-name">就诊卡充值 - 输入金额</h3>
          <div className="rec-amount-info">
            <span>姓名: { userInfo.NAME }</span>
            <span>性别: { userInfo.SEX === '2' ? '女' : '男' }</span>
            <span>年龄: { userInfo.AGE }</span>
          </div>
          <div className="rec-amount-row clearfix">
            <div className="rec-amount-l">
              <Input size="large" placeholder="" value={amount} />
            </div>
            <div className="rec-amount-r">
              <DigitalKeyboard getKeyboardNumber={this.getAmount} confirmBtn={this.openAmountDialog} />
            </div>
          </div>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>     
        
        <Modal
          title="请确认充值金额"
          visible={amountDialog}
          onOk={this.sendPayAmount}
          onCancel={this.cancelAmountDialog}
          cancelText="取消"
          okText="确定">
          <p>充值金额为: { this.state.amount }元</p>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    recharge: state.recharge,
    cardInfo: state.cardInfo,
    payment: state.payment
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addRecharge: (data) => {
      dispatch(rechargeAction.addRecharge(data));
    },
    addPayment: (data) => {
      dispatch(paymentAction.addPayment(data))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RechargeAmount)