import React, { Component } from 'react'
import './notFoundPage.scss'

export default class NotFoundPage extends Component {
  render() {
    return (
      <div className="not-fonnd-page">
        <div className="found-page-img"></div>
      </div>
    )
  }
}
