import React, { Component } from 'react'
import './index.scss'

/*
* 模拟字母键盘
*/
export default class LetterKeyboard extends Component {
  constructor(props){
    super(props);
    this.state = {
      letter: ''
    }
  }

  insertLetter(text){
    let oldLetter = this.state.letter;

    this.setState({
      letter: oldLetter + text
    }, () => {
      this.sendMessage();
    })
  }

  deleteLetter(){
    const len = this.state.letter.length;

    if(len > 0){
      let newLetter = this.state.letter.substring(0, len-1);
      this.setState({
        letter: newLetter
      }, () => {
        this.sendMessage();
      })
    }
  }
  
  sendMessage(){
    this.props.letterText(this.state.letter);
  }

  render() {
    return (
      <div>
        <div className="lk-container clearfix">
          <div className="lk-row clearfix">
            <span onClick={this.insertLetter.bind(this, 'Q')}>Q</span>
            <span onClick={this.insertLetter.bind(this, 'W')}>W</span>
            <span onClick={this.insertLetter.bind(this, 'E')}>E</span>
            <span onClick={this.insertLetter.bind(this, 'R')}>R</span>
            <span onClick={this.insertLetter.bind(this, 'T')}>T</span>
            <span onClick={this.insertLetter.bind(this, 'Y')}>Y</span>
            <span onClick={this.insertLetter.bind(this, 'U')}>U</span>
            <span onClick={this.insertLetter.bind(this, 'I')}>I</span>
            <span onClick={this.insertLetter.bind(this, 'O')}>O</span>
            <span onClick={this.insertLetter.bind(this, 'P')}>P</span>
          </div>
          <div className="lk-row pl-44 clearfix">
            <span onClick={this.insertLetter.bind(this, 'A')}>A</span>
            <span onClick={this.insertLetter.bind(this, 'S')}>S</span>
            <span onClick={this.insertLetter.bind(this, 'D')}>D</span>
            <span onClick={this.insertLetter.bind(this, 'F')}>F</span>
            <span onClick={this.insertLetter.bind(this, 'G')}>G</span>
            <span onClick={this.insertLetter.bind(this, 'H')}>H</span>
            <span onClick={this.insertLetter.bind(this, 'J')}>J</span>
            <span onClick={this.insertLetter.bind(this, 'K')}>K</span>
            <span onClick={this.insertLetter.bind(this, 'L')}>L</span>
          </div>
          <div className="lk-row pl-77 clearfix">
            <span onClick={this.insertLetter.bind(this, 'Z')}>Z</span>
            <span onClick={this.insertLetter.bind(this, 'X')}>X</span>
            <span onClick={this.insertLetter.bind(this, 'C')}>C</span>
            <span onClick={this.insertLetter.bind(this, 'V')}>V</span>
            <span onClick={this.insertLetter.bind(this, 'B')}>B</span>
            <span onClick={this.insertLetter.bind(this, 'N')}>N</span>
            <span onClick={this.insertLetter.bind(this, 'M')}>M</span>
            <span onClick={this.deleteLetter.bind(this)} className="kl-Backspace">退格</span>
          </div>
        </div>
      </div>
    )
  }
}