import React, { Component } from 'react'
import Head from '../../components/head/'
import { getNewSession, sendExitCard, getInitDevice, getSetting } from '@/utils/request'
import config from '@/config.js'
import { connect } from 'react-redux';
import * as cardAction from '../../store/actions/createCard'
import * as cardInfoAction from '../../store/actions/cardInfo'
import * as baseAction from '../../store/actions/base'
import * as rechargeAction from '../../store/actions/recharge'
import * as paymentAction from '../../store/actions/payment'
import * as inpatientAction from '../../store/actions/inpatient'
import * as outpatientAction from '../../store/actions/outpatient'
import * as registerAction from '../../store/actions/register'
import * as printAction from '../../store/actions/print'
import * as adminAction from '../../store/actions/admin'
import { Button, Icon, notification } from 'antd'
import './main.scss'

// 是否允许跳转，当取到最后一个配置接口成功后才可以点进其它业务
let permitJump = false;

class Main extends Component {
  constructor(props){
    super(props);
    this.state = {
      menus: [],
      baseInfo: {},
    };

    this.moreFun = this.moreFun.bind(this);
    this.signOutCard = this.signOutCard.bind(this);
  }

  componentDidMount(){
    // 清除管理员登录状态
    localStorage.removeItem('adminLogin');
    console.log(`⏰ 版本更新日期: ${config.upDateTime}`)

    // 禁止鼠标右键菜单
    document.oncontextmenu = function(e){
      return false;
    }
    this.initMain();
  }

  initMain(){
    const that = this;
    // 清除所有store存储内容
    that.props.clearStore();
    permitJump = false;

    const deviceId = localStorage.getItem('deviceId'),
          secret = localStorage.getItem('secret');

    // 判断是否是第一次注册
    if(deviceId && secret){
      this.setState({
        baseInfo: {
          deviceId,
          secret
        }
      }, () => { that.querySetting(deviceId, secret) } );    
    }
    else{
      this.queryInitDevice();
    }
  }

  // 初始化设备
  queryInitDevice(){
    const that = this;

    getInitDevice()
    .then(od => {
      if(od.status){
        const deviceId = od.data.id,
            secret = od.data.secret;

        localStorage.setItem('deviceId', deviceId);
        localStorage.setItem('secret', secret);
        this.setState({
          baseInfo: {
            deviceId,
            secret
          }
        }, () => { that.querySetting(deviceId, secret) });
      }
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 获取配置接口
  querySetting(deviceId, secret){
    const that = this;
    const { baseInfo } = this.state;

    getSetting({
      deviceId, 
      secret,
    })
    .then(od => {
      console.log('GetSetting res', od && od.data)
      if(od.status){
        const mainMenu = od.data.menuList;
        const { HospitalInfo, deviceType, deviceName, DeviceFunctionConfig, terminalCode, System } = od.data;
        let cardFee = '';
        let hosInfo = {};

        // 感应式读卡，用户不会
        if(deviceType === '1'){
          that.props.clearCardInfoStore();
        }

        HospitalInfo.forEach(function(hos, idx, arr){
          if(hos.key === 'HOS_NAME'){
            hosInfo['key'] = hos.key;
            hosInfo['value'] = hos.value;
          }
          else if(hos.key === 'CARD_FEE'){
            cardFee = hos.value;
          }
        })

        // 医院代码
        System.forEach(function(sys){
          if(sys.key === 'HOSP_CODE'){
            hosInfo['hospCode'] = sys.value;
          }
        })
        

        // let cards = (DeviceFunctionConfig[2] && JSON.parse(DeviceFunctionConfig[2].value)) || []
        // let inCards = (DeviceFunctionConfig[3] && JSON.parse(DeviceFunctionConfig[3].value)) || []

        // 建卡业务 - 配置医院有无卡
        // let isHaveCardArr = DeviceFunctionConfig.filter((device) => {
        //   return device.key === 'isHaveCard'
        // })
        // let isHaveCard = isHaveCardArr[0].value === '0' ? false : true    // 1:建卡有实卡  0:建卡无实卡

        // 过滤门诊、住院读卡介质配置、电子发票最大打印数量
        let newArchivesList = []
        let cardValue = []
        let inCardValue = []
        let einvoicePrintValue = ''
        let isInvoiceValue = false
        let printTypeValue = '1'
        let isSwiper = '1'
        let screenType = false

        // 存储一些提示信息
        let tipsInfo = {}      

        // 挂号号源展示 true 时段展示   false 上下午展示
        let timesNumberEnable = false   

        // 当前医院是否 医保结算
        let isMedicalSettle = false
        
        // 挂号到科室，是否指定号别，如果有配置说明医院要指定号别，如果没有为false
        let regToDeptTypeMap = {}

        const newArchives = DeviceFunctionConfig.filter((item) =>{
          return item.key === 'newArchiveList'
        })
        const cards = DeviceFunctionConfig.filter((item) =>{
          return item.key === 'CardList'
        })
        const inCards = DeviceFunctionConfig.filter((item) =>{
          return item.key === 'inCardList'
        })
        const einvoicePrints = DeviceFunctionConfig.filter((item) =>{
          return item.key === 'einvoicePrint'
        })
        // 是否支持电子发票-门诊缴费电子发票
        const isInvoice = DeviceFunctionConfig.filter((item) =>{
          return item.key === 'isInvoice'
        })
        // 打印渠道类型
        const printType = DeviceFunctionConfig.filter((item) =>{
          return item.key === 'printType'
        })
        // 是否加载轮播图
        const swipterType = DeviceFunctionConfig.filter((item) =>{
          return item.key === 'swipterType'
        })
        // 屏幕类型
        const screenTypes = DeviceFunctionConfig.filter((item) =>{
          return item.key === 'screenType'
        })
        // 挂号提示
        const tipsInfos = DeviceFunctionConfig.filter((item) =>{
          return item.key === 'tipsInfo'
        })
        const timesNumberEnables = DeviceFunctionConfig.filter((item) =>{
          return item.key === 'timesNumberEnable'
        })
        // 是否医保结算
        const isMedicalSettles = DeviceFunctionConfig.filter((item) =>{
          return item.key === 'isMedicalSettle'
        })
        const regToDeptTypeMapArr = DeviceFunctionConfig.filter((item) =>{
          return item.key === 'regToDeptTypeMap'
        })

        if(newArchives.length > 0){
          newArchivesList = JSON.parse(newArchives[0].value)
        }
        if(cards.length > 0){
          cardValue = JSON.parse(cards[0].value)
        }
        if(inCards.length > 0){
          inCardValue = JSON.parse(inCards[0].value)
        }
        if(einvoicePrints.length > 0){
          einvoicePrintValue = Number(einvoicePrints[0].value)
        }
        if(isInvoice.length > 0){
          isInvoiceValue = isInvoice[0].value == '1' ? true : false
        }
        if(printType.length > 0){
          printTypeValue = printType[0].value
        }
        if(swipterType.length > 0){
          isSwiper = swipterType[0].value
        }
        if(screenTypes.length > 0){
          screenType = screenTypes[0].value
        }
        if(tipsInfos.length > 0){
          tipsInfo = JSON.parse(tipsInfos[0].value)
        }
        if(isMedicalSettles.length > 0){
          isMedicalSettle = isMedicalSettles[0].value === '1' ? true : false
        }
        if(timesNumberEnables.length > 0){
          timesNumberEnable = timesNumberEnables[0].value === 'true'
        }
        if(regToDeptTypeMapArr.length > 0){
          regToDeptTypeMap = JSON.parse(regToDeptTypeMapArr[0].value)
        }

        Object.assign(baseInfo, {
          deviceName,
          terminalCode,
          hospitalInfo: {
            key: hosInfo.key,
            value: hosInfo.value,
            cardFee: cardFee,
            isFeePay: false,
            deviceType,
            isInvoice: isInvoiceValue,
            hospCode: hosInfo.hospCode
          },
          einvoicePrint: einvoicePrintValue,
          menuList: mainMenu,
          printType: printTypeValue,    // 打印方式
          isSwiper,
          screenType,
          tipsInfo,
          timesNumberEnable,
          isMedicalSettle,
          
          // 卡类型列表、用于读取用户信息、支付功能
          // 1 jzcard	院内就诊卡	1	1	0	
          // 2 ybcard	医保卡	1	2	0	
          // 3 ylcard	银联卡	1	2	1	
          // 4 eybcard 电子医保卡	2	2	0	
          // 5 jkcard	健康卡	1	0	0	
          // 6 ejkcard 电子健康卡	2	0	0	
          // 7 weixin	微信	0	2	1	
          // 8 alipay	支付宝	0	2	1	
          cardList: cardValue,
          inCardList: inCardValue,
          newArchivesList,
          regToDeptTypeMap,
          // isHaveCard,

          // 测试
          // cardList: [         
          //   {
          //     name: "就诊卡",
          //     id: "jzcard",
          //     resourcesMode: 1,       // 获取用户资源方式：0不支持患者识别，1本地服务(API获取)，2网络获取（扫描二维码（串号））
          //     payMode: 0,             // 资源支付：0无支付功能，1内部支付，2外部支付
          //     isSeparatePay: 0,       // 是否单独支付：0无单独支付，1支持单独支付
          //   },
          //   {
          //     name: "医保卡",
          //     id: "ybcard",
          //     resourcesMode: 1,
          //     payMode: 2,
          //     isSeparatePay: 0,
          //   },
          //   {
          //     name: "银联卡",
          //     id: "ylcard",
          //     resourcesMode: 1,
          //     payMode: 2,
          //     isSeparatePay: 1,
          //   },
          //   {
          //     name: "电子医保卡",
          //     id: "eybcard",
          //     resourcesMode: 2,
          //     payMode: 2,
          //     isSeparatePay: 0,
          //   },
          //   {
          //     name: "健康卡",
          //     id: "jkcard",
          //     resourcesMode: 1,
          //     payMode: 0,
          //     isSeparatePay: 0,
          //   },
          //   {
          //     name: "电子健康卡",
          //     id: "ejkcard",
          //     resourcesMode: 2,
          //     payMode: 0,
          //     isSeparatePay: 0,
          //   },
          //   {
          //     name: "微信",
          //     id: "weixin",
          //     resourcesMode: 0,
          //     payMode: 2,
          //     isSeparatePay: 1,
          //   },
          //   {
          //     name: "支付宝",
          //     id: "alipay",
          //     resourcesMode: 0,
          //     payMode: 2,
          //     isSeparatePay: 1,
          //   }
          // ]
        })
        
        that.setState({
          menus: mainMenu.main,
          baseInfo
        }, () => { that.queryNewSession(deviceId, secret) });
      }
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 获取sessionId
  queryNewSession(deviceId, secret){
    const that = this;
    const { baseInfo } = this.state;
    
    getNewSession({
      deviceId, 
      secret,
    })
    .then(od => {
      if(od.status){
        window.sessionId = od.data.SessionId;
        window.deviceId = 2;

        Object.assign(baseInfo, {
          sessionId: od.data.SessionId
        })
        // 存储到Store
        that.props.addBase(baseInfo);
        localStorage.setItem('base', JSON.stringify(baseInfo));
        permitJump = true;
      }
      else{
        permitJump = false;
      }
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
      permitJump = false;
    })
  }

  // 退卡
  signOutCard(){
    const that = this;
    const { currentCard={} } = this.props.cardInfo || {};

    // 如果当前没有取到读卡类型，传空字符串，硬件会所有设备执行退出一遍
    let resId = currentCard.id || ''; 
    sendExitCard({
      RESOURSE_ID: resId
    })
    .then(od => {
      that.props.clearCardInfoStore();
    })
    .catch(err => {
      that.props.clearCardInfoStore();
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })

    that.props.clearCardInfoStore();
  }

  moreFun(){
    this.props.history.push('/moreFunctions')
  }

  isPermitJump(menu){
    if(permitJump){
      this.props.history.push(menu.link)
    }
    else{
      notification.error({
        message: `错误`,
        description: '配置获取中请稍后',
        duration: 10,
      });
      // 如果配置获取失败重新获取
      this.initMain();
    }
  }

  render () {
    const menus = () => {
      let menuHtml = [];
      let menusArr = this.state.menus.slice(0, 8);

      menusArr.forEach((menu) => {
        menuHtml.push(<div className="main-col" key={menu.link}>
          <p onClick={this.isPermitJump.bind(this, menu)}>
            <i className={menu.icon}></i>
            <span>{menu.name}</span>
          </p>
        </div>)
      })
      return menuHtml;
    }

    // const backCardButton = () => {
    //   if(hospitalInfo && hospitalInfo.deviceType !== '1'){
    //     return (
    //       <Button type="primary" size="large" onClick={this.signOutCard}
    //          style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
    //       <Icon type="poweroff" theme="outlined" />退 卡</Button>
    //     )
    //   }
    // }

    return (
      <div className="pos">
        <Head />
        
        <div className="main-content">
          <div className="main-row">
            { menus() }
          </div>
        </div>
        
        <div className="footer">
          <Button type="primary" size="large" className="left" style={{ backgroundColor: "#00bd58", "float": "left", border: "1px #00bd58 solid"}} onClick={this.moreFun}>
            <Icon type="plus-circle" theme="outlined" />更多功能</Button>
          <Button type="primary" size="large" onClick={this.signOutCard}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 卡</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { base, cardInfo } = state;
  return {
    base,
    cardInfo
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addBase: (data) => {
      dispatch(baseAction.addBase(data))
    },
    clearCardInfoStore: (data) => {
      localStorage.removeItem('cardInfo')
      dispatch(cardInfoAction.clearCard())
    },
    clearStore: (data) => {
      dispatch(cardAction.clearCardData())
      dispatch(rechargeAction.clearRecharge())
      dispatch(paymentAction.clearPayment())
      dispatch(inpatientAction.clearInpatient())     
      dispatch(outpatientAction.clearOutpatient())
      dispatch(registerAction.clearRegister())
      dispatch(printAction.clearPrint())
      dispatch(adminAction.clearAdmin())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)