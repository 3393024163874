import React, { Component } from 'react'
import Head from '../../components/head'
import { Button, Icon, Table } from 'antd'
import businessQuitTime from '../../utils/businessQuitTime'
import { getRegistrationsByMrNo, takeNo } from '@/utils/request'
import { connect } from 'react-redux'
import * as payMentAction from '../../store/actions/payment'

/*
 * 取号列表数据
 */
class TakeNumList extends Component {
  constructor(props){
    super(props);
    
    this.state = {
      pageSize: 7,
      loading: true,
      columns: [
        {
          title: '挂号序号',
          dataIndex: 'id',
        }, 
        {
          title: '患者名称',
          dataIndex: 'patientName',
        }, 
        {
          title: '科室编号',
          dataIndex: 'deptName',
        }, 
        {
          title: '就诊日期',
          dataIndex: 'acceptsAt',
        },
        {
          title: '就诊时段',
          dataIndex: 'timeBucket',
          render: (text, record) => (
            <span>
              {(text === '1')? '上午' : '下午'}
            </span>
          )
        },
        {
          title: '操作',
          width: '90px',
          render: (text, record) => (
            <span>
              <Button type="primary" onClick={this.clickTakeNumber.bind(this, text)} style={{ marginRight: '20px'}}>取号</Button>
            </span>
          )
        },
      ],
      takeNumberData: [],
    };

    this.goHomePage = this.goHomePage.bind(this);
    this.goUpPage = this.goUpPage.bind(this);
  }
  
  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/')
  }

  componentDidMount(){
    this.queryRegistrationsByMrNo();
  }

  // 获取数据
  queryRegistrationsByMrNo(){
    const that = this;
    const { cardInfo } = this.props;

    getRegistrationsByMrNo({
      MR_NO: cardInfo.mr_no,
    })
    .then(od => {
      if(od.status){
        let dataList = od.data;
        that.setState({
          takeNumberData: dataList
        })
      }
      that.setState({
        loading: false
      })
    })
    .catch(err => {
      that.setState({
        loading: false
      })
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 取号
  clickTakeNumber(row){
    const that = this;
    // const { deviceId } = this.props.base;
    const base = JSON.parse(localStorage.getItem('base'));
    const { deviceId } = base;
    
    // paid：0未支付 1已支付
    if(row.paid === '0'){
      this.props.addPayment({
        amount: parseFloat(row.amount),
        businessName: "",
        businessType: "takeNo",
        printTitle: "缴费",
        regId: row.id,
      });

      that.props.history.push('/payList');
    }
    else{
      takeNo({
        regId: row.id,
        deviceId,
      })
      .then(od => {
        if(od.status){
          let takeNoData = od.data;
          let newPayment = Object.assign(that.props.payment || {}, {
            businessName: '',
            businessType: 'takeNo',
            printTitle: '',
            amount: '',
            takeNo: takeNoData,
          })
          that.props.addPayment(newPayment)
  
          // 调用打印接口
          that.props.history.push('/payPrint');
        }
      })
      .catch(err => {
        that.props.history.push({ 
          pathname : '/error',
          query : { messageTitle: '失败', messageContent: err.toString()}
        })
      })
    }
  }

  render() {
    const quitTime = businessQuitTime.todayTakeNumber.takeNumList;
    const { userInfo={} } = this.props.cardInfo || {};
    const { pageSize, columns, takeNumberData, loading } = this.state;

    return (
      <div>
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <h3 className="business-name">预约取号 - 取号列表</h3>
           <div className="rec-amount-info">
            <span>姓名: { userInfo.NAME }</span>
            <span>性别: { userInfo.SEX === '2' ? '女' : '男' }</span>
            <span>年龄: { userInfo.AGE }</span>
            <span>身份证号码: { userInfo.CERTI_NO }</span>
          </div>

          <Table pagination={{ pageSize: pageSize }} 
                columns={columns} 
                dataSource={takeNumberData}
                loading={loading}
                className="mt-25" />
        </div> 

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    base: state.base,
    cardInfo: state.cardInfo,
    payment: state.payment,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addPayment: (data) => {
      dispatch(payMentAction.addPayment(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TakeNumList)