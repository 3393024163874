import React, { Component } from 'react'
import Head from '../head/'
import { Button, Icon, notification } from 'antd';
import { connect } from 'react-redux';
import businessQuitTime from '../../utils/businessQuitTime'
import './print.scss'

class PrintResult extends Component {
  constructor(props){
    super(props);
    this.state = {
      printContext: '正在打印中请稍候...'
    }
  }

  goHomePage(){
    this.props.history.push('/')
  }

  componentDidMount(){
    const { location } = this.props;
    let message = location.query && (location.query.messageContent || '');
    this.setState({
      printContext: message
    })
  }

  render() {
    const { printContext } = this.state

    return (
      <div>
        <Head quitTime={businessQuitTime.print.payPrint} />

        <div className="context-container clearfix">
          <div className="status-box">
            <div className="print-ico">
              <i className="icon iconfont icon--tiaomadayin"></i>
            </div>
            <div className="print-text">
              {printContext}
            </div>
          </div>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goHomePage.bind(this)}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    base: state.base,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addCard: (data) => {
      dispatch(cardInfoAction.addCard(data));
    },
    addRecharge: (data) => {
      dispatch(rechargeAction.addRecharge(data))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintResult)