import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom';
import Main from './main'
import MoreFunctions from './main/moreFunctions'

// 读卡公用
import ReadCard from '../components/readCard'
import Success from '../components/status/success'
import Error from '../components/status/error'
import NotFoundPage from '../components/notFoundPage'

// 医保读卡公用
import YbReadCard from '../components/ybReadCard'

// 打印凭条
import PayPrint from '../components/print/payPrint'
import PrintResult from '../components/print/printResult'
import PubPrint from '../components/print/pubPrint'

import VsitCard from '../components/selectCard/visitCard'
import MedicalCard from '../components/selectCard/medicalCard'

// 付款方式
import PayList from '../components/pay/payList'
import WxPay from '../components/pay/wxPay'
import ZfbPay from '../components/pay/zfbPay'
import YhkPay from '../components/pay/yhkPay'
import JhPay from '../components/pay/jhPay'
import NSJhPay from '../components/pay/nsjhPay'

// 建档发卡
import CreateArchives from './createArchives'
import ConfirmIdCard from './createArchives/confirmIdCard'
import ReadIdCard from './createArchives/readIdCard'
import InsertPhone from './createArchives/insertPhone'
import GrantCard from './createArchives/grantCard'
import BankUserInfo from './createArchives/bankUserInfo'
import PayAmount from './createArchives/payAmount'

// 无卡建档
import NoCard from './noCard'
import NoCardConfirmIdCard from './noCard/confirmIdCard'
import NoCardReadIdCard from './noCard/readIdCard'
import NoCardInsertPhone from './noCard/insertPhone'
import PrintQrcode from './noCard/printQrcode'

// 就诊卡充值
import MedicalCardRecharge from './medicalCardRecharge'
import ConfirmIdentityInfo from './medicalCardRecharge/confirmIdentityInfo'
import RechargeAmount from './medicalCardRecharge/rechargeAmount'

// 预约挂号
import AppointmentRegister from './appointmentRegister'

// 当日挂号
import TodayRegister from './todayRegister'
import TrPatientInfo from './todayRegister/trPatientInfo'
import TodaySelectDoctor from './todayRegister/todaySelectDoctor'
import TodaySelectDept from './todayRegister/todaySelectDept'
import TodaySelectDate from './todayRegister/todaySelectDate'

// 当日取号
import TodayTakeNumber from './todayTakeNumber'
import TtPatientInfo from './todayTakeNumber/ttPatientInfo'
import TakeNumList from './todayTakeNumber/takeNumList'

// 门诊费用查询
import OutpatientCost from './outpatientCost'
import OcPatientInfo from './outpatientCost/ocPatientInfo'
// import SelectDate from './outpatientCost/selectDate'
import CostList from './outpatientCost/costList'

// 门诊缴费
import OutpatientPay from './outpatientPay'
import OpPatientInfo from './outpatientPay/opPatientInfo'
import PayMentList from './outpatientPay/payMentList'
import OutpatientPayDetail from './outpatientPay/outpatientPayDetail'

// 药品价格查询
import QueryDrugs from './queryDrugs'
// 药品价格查询(old)
import DrugPrice from './drugPrice'
import DpPatientInfo from './drugPrice/dpPatientInfo'
import DrugpriceList from './drugPrice/drugpriceList'

// 收费项目查询
import QueryPaymentItems from './queryPaymentItems'

// 住院费用查询
import InpatientCost from './inpatientCost'
import QueryInpatient from './inpatientCost/queryInpatient'
import HcPatientInfo from './inpatientCost/hcPatientInfo'
import InpatientList from './inpatientCost/inpatientList'

// 住院押金
import InpatientPay from './inpatientPay'
import QueryInpatientPay from './inpatientPay/queryInpatientPay'
import HpPatientInfo from './inpatientPay/hpPatientInfo'
import InpatientDeposit from './inpatientPay/inpatientDeposit'

// 检验结果打印
// import TestResultsPrint from './testResultsPrint'
// import OutpatientPrint from './testResultsPrint/outpatientPrint'
// import OutpatientInfo from './testResultsPrint/outpatientInfo'
// import InsertHospitalized from './testResultsPrint/insertHospitalized'
// import HospitalizedInfo from './testResultsPrint/hospitalizedInfo'
// import HospitalizedPrint from './testResultsPrint/hospitalizedPrint'

// 门诊检验结果打印
import PrintOutpatientResult from './printOutpatientResult'
import OutpatientInfo from './printOutpatientResult/outpatientInfo'
import OutpatientPrint from './printOutpatientResult/outpatientPrint'
import OutpatientPrintLis from './printOutpatientResult/outpatientPrintLis'

// 核酸结果打印
import PrintNucleicAcidResult from './printNucleicAcidResult'
import NucleicAcidReadIdCardNo from './printNucleicAcidResult/readIdCardNo'
import NucleicAcidWriteIdCardNo from './printNucleicAcidResult/writeIdCardNo'
import NucleicAcidPatientInfo from './printNucleicAcidResult/nucleicAcidPatientInfo'
import NucleicAcidPrintResult from './printNucleicAcidResult/nucleicAcidPrintResult'

// 打印门诊心电报告
import PrintOutPatientECGReport from './printOutPatientECGReport'
import PrintOutpatientECGInfo from './printOutPatientECGReport/printOutpatientECGInfo'
import printOutpatientECGReportList from './printOutPatientECGReport/printOutpatientECGReportList'

// 住院检验结果打印
import PrintHospitalizationResult from './printHospitalizationResult/'
import HospitalizedInfo from './printHospitalizationResult/hospitalizedInfo'
import HospitalizedPrint from './printHospitalizationResult/hospitalizedPrint'
import InsertInNo from './printHospitalizationResult/insertInNo'
import HospitalizedPrintLis from './printHospitalizationResult/hospitalizedPrintLis'

// 打印住院心电报告
import PrintHospitalizedECGReport from './printHospitalizedECGReport'
import PrintHospitalizedECGReportInfo from './printHospitalizedECGReport/printHospitalizedECGReportInfo'
import PrintHospitalizedECGReportList from './printHospitalizedECGReport/printHospitalizedECGReportList'
import InsertNoECGReport from './printHospitalizedECGReport/insertNoECGReport'

// 打印电子发票
import PrintInvoice from './printInvoice'

// 注册电子健康卡
import HealthCard from './healthCard'
import HealthReadIdCard from './healthCard/healthReadIdCard'
import HealthInsertPhone from './healthCard/healthInsertPhone'
import HealthConfirmIdCard from './healthCard/healthConfirmIdCard'
import HealthShowQrcode from './healthCard/healthShowQrcode'

// Admin
import AdminLogin from './admin/adminLogin'
import AdminHome from './admin/adminHome'
import InserOrderId from './admin/print/inserOrderId'
import PrintData from './admin/print/printData'

// 科室介绍
import departmentIntro from './departmentIntro/'
import departmentDetail from './departmentIntro/departmentDetail'

// DEMO
import KeyboardTest from '../components/keyboardTest'

export default class Layout extends Component {
  render() {
    return (
      <Switch>
        <Route path="/" exact component={Main} />
        <Route path="/moreFunctions" component={ MoreFunctions } />

        {/* 建档、取卡 */}
        <Route path="/createArchives" component={ CreateArchives } />
        <Route path="/confirmIdCard" component={ ConfirmIdCard } />
        <Route path="/readIdCard/:quitTime" component={ ReadIdCard } />
        <Route path="/insertPhone" component={ InsertPhone } />
        <Route path="/grantCard" component={ GrantCard } />
        <Route path="/bankUserInfo" component={ BankUserInfo } />
        <Route path="/payAmount" component={ PayAmount } />

        {/* 无卡、建档 */}
        <Route path="/noCard" component={ NoCard } />
        <Route path="/noCardConfirmIdCard" component={ NoCardConfirmIdCard } />
        <Route path="/noCardReadIdCard/:quitTime" component={ NoCardReadIdCard } />
        <Route path="/noCardInsertPhone" component={ NoCardInsertPhone } />
        <Route path="/printQrcode" component={ PrintQrcode } />
        
        {/* 就诊卡充值 */}
        <Route path="/medicalCardRecharge" component={ MedicalCardRecharge } />
        <Route path="/confirmIdentityInfo" component={ ConfirmIdentityInfo } />   
        <Route path="/rechargeAmount" component={ RechargeAmount } />

        {/* 预约挂号 */}
        <Route path="/appointmentRegister" component={ AppointmentRegister } />

        {/* 当日挂号 */}
        <Route path="/todayRegister" component={ TodayRegister } />
        <Route path="/trPatientInfo" component={ TrPatientInfo } />
        <Route path="/todaySelectDate" component={ TodaySelectDate } />
        <Route path="/todaySelectDoctor" component={ TodaySelectDoctor } />
        <Route path="/todaySelectDept" component={ TodaySelectDept } />
        
        {/* 预约取号 */}
        <Route path="/todayTakeNumber" component={ TodayTakeNumber } />   
        <Route path="/ttPatientInfo" component={ TtPatientInfo } />   
        <Route path="/takeNumList" component={ TakeNumList } />   
        
        {/* 门诊费用查询 */}
        <Route path="/outpatientCost" component={ OutpatientCost } />
        <Route path="/ocPatientInfo" component={ OcPatientInfo } />
        {/* <Route path="/selectDate" component={ SelectDate } /> */}
        <Route path="/costList" component={ CostList } />
        
        {/* 门诊缴费 */}
        <Route path="/outpatientPay" component={ OutpatientPay } />
        <Route path="/opPatientInfo" component={ OpPatientInfo } />
        <Route path="/payMentList" component={ PayMentList } />
        <Route path="/outpatientPayDetail" component={ OutpatientPayDetail } />

        {/* 药品价格查询 */}
        <Route path="/queryDrugs" component={ QueryDrugs } />
        {/* 药品价格查询(老)*/}
        <Route path="/drugPrice" component={ DrugPrice } />
        <Route path="/dpPatientInfo" component={ DpPatientInfo } />
        <Route path="/drugpriceList" component={ DrugpriceList } />

        {/* 收费项目查询 */}
        <Route path="/queryPaymentItems" component={ QueryPaymentItems } />
        
        {/* 住院费用查询 */}
        <Route path="/inpatientCost" component={ InpatientCost } />
        <Route path="/queryInpatient" component={ QueryInpatient } />
        <Route path="/hcPatientInfo" component={ HcPatientInfo } />
        <Route path="/inpatientList" component={ InpatientList } />
        
        {/* 住院押金 */}
        <Route path="/inpatientPay" component={ InpatientPay } />
        <Route path="/queryInpatientPay" component={ QueryInpatientPay } />
        <Route path="/hpPatientInfo" component={ HpPatientInfo } />
        <Route path="/inpatientDeposit" component={ InpatientDeposit } />
        
        {/* 就诊卡 */}
        <Route path="/visitCard" component={ VsitCard } />
        {/* 医保卡 */}
        <Route path="/medicalCard" component={ MedicalCard } />
        {/* 读卡公用组件 */}
        <Route path="/readCard/:typeName/:cardId/:quitTime/:busCode" component={ ReadCard } />
        {/* 医保读卡公用组件 */}
        <Route path="/ybReadCard/:typeName/:cardId/:quitTime/:busCode" component={ YbReadCard } />

        {/* 支付列表 */}
        <Route path="/payList" component={ PayList } />
        <Route path="/wxPay" component={ WxPay } />
        <Route path="/zfbPay" component={ ZfbPay } />
        <Route path="/yhkPay" component={ YhkPay } />
        <Route path="/jhPay" component={ JhPay } />
        <Route path="/nsjhPay" component={ NSJhPay } />

        {/* 检验结果打印 
        <Route path="/testResultsPrint" component={ TestResultsPrint } />
        <Route path="/hospitalizedPrint" component={ HospitalizedPrint } />
        <Route path="/insertHospitalized" component={ InsertHospitalized } />*/}

        {/* 门诊检验结果打印 */}
        <Route path="/printOutpatientResult" component={ PrintOutpatientResult } />
        <Route path="/outpatientInfo" component={ OutpatientInfo } />
        <Route path="/outpatientPrint" component={ OutpatientPrint } />
        <Route path="/OutpatientPrintLis" component={ OutpatientPrintLis } />

        {/* 核酸结果打印 */}
        <Route path="/printNucleicAcidResult" component={ PrintNucleicAcidResult } />
        <Route path="/nucleicAcidReadIdCardNo" component={ NucleicAcidReadIdCardNo } />
        <Route path="/nucleicAcidWriteIdCardNo" component={ NucleicAcidWriteIdCardNo } />
        <Route path="/nucleicAcidPatientInfo" component={ NucleicAcidPatientInfo } />
        <Route path="/nucleicAcidPrintResult" component={ NucleicAcidPrintResult } />

        {/* 住院检验结果打印 */}
        <Route path="/printHospitalizationResult" component={ PrintHospitalizationResult } />
        <Route path="/hospitalizedInfo" component={ HospitalizedInfo } />
        <Route path="/hospitalizedPrint" component={ HospitalizedPrint } />
        <Route path="/insertInNo" component={ InsertInNo } />
        <Route path="/hospitalizedPrintLis" component={ HospitalizedPrintLis } />

        {/* 打印门诊心电报告 */}
        <Route path="/printOutPatientECGReport" component={ PrintOutPatientECGReport } />
        <Route path="/printOutpatientECGInfo" component={ PrintOutpatientECGInfo } />
        <Route path="/printOutpatientECGReportList" component={ printOutpatientECGReportList } />

        {/* 打印住院心电报告 */}
        <Route path="/printHospitalizedECGReport" component={ PrintHospitalizedECGReport } />
        <Route path="/printHospitalizedECGReportInfo" component={ PrintHospitalizedECGReportInfo } />
        <Route path="/printHospitalizedECGReportList" component={ PrintHospitalizedECGReportList } />
        <Route path="/insertNoECGReport" component={ InsertNoECGReport } />

        {/* 打印电子发票 */}
        <Route path="/printInvoice" component={ PrintInvoice } />

        {/* Admin */}
        <Route path="/adminLogin" component={ AdminLogin } />
        <Route path="/adminHome" component={ AdminHome } />
        <Route path="/printData/:orderId" component={ PrintData } />
        <Route path="/inserOrderId" component={ InserOrderId } />

        {/* 科室介绍 */}
        <Route path="/departmentIntro" component={ departmentIntro } />        
        <Route path="/departmentDetail/:code" component={ departmentDetail } />        
        
        {/* 打印凭条 */}
        <Route path="/payPrint" component={ PayPrint } />
        <Route path="/printResult" component={ PrintResult } />
        <Route path="/pubPrint" component={ PubPrint } />
        
        {/* 注册电子健康卡 */}
        <Route path="/healthCard" component={ HealthCard } />
        <Route path="/healthReadIdCard" component={ HealthReadIdCard } />
        <Route path="/healthConfirmIdCard" component={ HealthConfirmIdCard } />
        <Route path="/healthInsertPhone" component={ HealthInsertPhone } />
        <Route path="/healthShowQrcode" component={ HealthShowQrcode } />

        {/* 公用组件 */}
        <Route path="/error" component={ Error } />
        <Route path="/success" component={ Success } />

        {/* 测试 */}
        <Route path="/keyboardTest" component={KeyboardTest} />

        {/* 404 */}
        <Route component={ NotFoundPage } />
      </Switch>
    )
  }
}
