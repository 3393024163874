import React, { Component } from 'react'
import { Button, Icon, Row, Col } from 'antd'
import { connect } from 'react-redux'
import Head from '../../components/head'
import * as Action from '@/store/actions/createCard'

/*
 * 读取患者身份证信息
 */
class PrintNucleicAcidResult extends Component {
  constructor(props){
    super(props)
    this.state = {
      idCard: {},
    };

    this.goHomePage = this.goHomePage.bind(this)
  }

  goHomePage(){
    this.props.history.push('/')
  }

  componentDidMount(){
    
  }

  openReadIdCard(){
    this.props.history.push('/nucleicAcidReadIdCardNo')
  }

  openWriteIdCard(){
    this.props.history.push('/nucleicAcidWriteIdCardNo')
  }

  render() {
    return (
      <div>
        <Head quitTime={this.props.match.params.quitTime} />
        <div className="context-container clearfix">
          <h2 className="crumbs">核酸结果打印 - 请选择身份证号获取方式</h2>
          <div className="nuc-idcard"></div>
          <Row gutter={[24, 24]} className="nuc-row">
            <Col className="gutter-row" span={24}>
              <div className="nuc-card" onClick={() => this.openReadIdCard()}>刷卡获取</div>
            </Col>
            <Col className="gutter-row" span={24}>
              <div className="nuc-card" onClick={() => this.openWriteIdCard()}>手工录入</div>
            </Col>
          </Row>
        </div>
        <div className="footer">
          <Button type="primary" size="large" onClick={ this.goHomePage }
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    base: state.base,
    cardData: state.cardData
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addCardData: (data) => {
      dispatch(Action.addCardData(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintNucleicAcidResult)