export const addReprint = (data) => {
  return {
    type: "ADD_REPRINT",
    data
  }
}

export const clearAdmin = (data) => {
  return {
    type: "CLEAR_ADMIN",
    data
  }
}
