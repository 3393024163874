import React, { Component } from 'react'
import { 
  Button, 
  Input } from 'antd';

import DigitalKeyboard from './digitalKeyboard'
import LetterKeyboard from './LetterKeyboard'
import LetterAndNumKeyboard from './LetterAndNumKeyboard'

export default class KeyboardTest extends Component {
  constructor(props){
    super(props);
    this.state = {
      phone: '',
      phoneB: '',
      letter: '',
      letAdnNum: '',
      chars: ['X', 'Y']
    }
  }

  // 数字键盘
  getKeyboardNumber(num){
    this.setState({
      phone: num
    })
  }

  successBtn(){
    alert('A组件');
  }


  getKeyboardNumberB(num){
    this.setState({
      phoneB: num
    })
  }

  successBtnB(){
    alert('B组件');
  }


  // 字母键盘
  letterText(letter){
    this.setState({
      letter
    })
  }

  insText(str){
    this.setState({
      letAdnNum: str
    })
    console.log('insText', str)
  }

  // 键盘上触发确认
  clickConfirmBtn() {
    const { letAdnNum } = this.state
    console.log('输入的内容为：', letAdnNum)
  }

  componentDidMount(){

  }

  render() {
    const { chars, letAdnNum } = this.state

    return (
      <div style={{height: '3000px'}}>
        {/* <div>模拟键盘测试</div>
        <h1>请输入本人电话号码</h1>

        <div style={{width: "500px", margin: "20px 0"}}>
          <Input size="large" placeholder="" value={this.state.phoneB} />
          <DigitalKeyboard getKeyboardNumber={this.getKeyboardNumberB.bind(this)} confirmBtn={this.successBtnB.bind(this)} addChars={['X']} />
        </div>

        <div style={{width: "500px", margin: "20px 0"}}>
          <Input size="large" placeholder="" value={this.state.phoneB} />
          <DigitalKeyboard getKeyboardNumber={this.getKeyboardNumberB.bind(this)} confirmBtn={this.successBtnB.bind(this)} />
        </div>


        <div>模拟字母键盘</div>
        <h1>输入字母</h1>
        <div style={{width: "700px", margin: "20px 0", backgroundColor: "#f0f5f7"}}>
          <Input size="large" placeholder="" value={this.state.letter} />
        </div>
        <LetterKeyboard letterText={this.letterText.bind(this)} /> */}
        
        <div>模拟字母+数字键盘</div>
        <div style={{width: "700px", margin: "20px 0", backgroundColor: "#f0f5f7"}}>
          <Input type='password' size="large" placeholder="" value={letAdnNum} />
        </div>
        <LetterAndNumKeyboard letterText={(str) => this.insText(str)} confirmBtn={() => this.clickConfirmBtn()} />


        <div>按钮样式</div>
        <Button type="primary" size="large" style={{backgroundColor: "#00bd58", border: "1px #00bd58 solid", marginTop: "20px"}}>确 定</Button>

        <Button type="primary" size="large" style={{backgroundColor: "#00bd58", border: "1px #00bd58 solid", marginTop: "20px"}}
          onClick={() =>  this.props.history.push('/') }>跳转</Button>

      </div>
    )
  }
}
