import React, { Component } from 'react'
import Head from '../../components/head/'
import { Button, Icon } from 'antd';
import businessQuitTime from '../../utils/businessQuitTime'
import styles from './department.scss'

export default class DepartmentIntro extends Component {
  constructor(props){
    super(props);
    this.state = {
      departmentList: [],
    }
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/')
  }

  componentDidMount(){
    const departmentList = [
      {
        name: '儿科',
        code: 'pediatrics',
        text: '鸡泽县中医院儿科始建于1997年，经过二十余年的发展，年门诊量平均3000余人，年收治病人一千五百余人。一楼设有儿科门诊，三楼设有儿科病房，开放病床50余张，设有感染病区和非感染病区。病室宽敞、明亮、整洁、设放完善，配置了中央空调、集中供氧、负压吸引器、微波治疗仪...',
      },
      {
        name: '肛肠科',
        code: 'anorectal',
        text: '我们的理念是:以科技为先导,放眼世界,以专家为核心,服务全县,深度关注病人生理、心理需求,提供专业、温馨个性化服务,秉承质量第一,诚信行医,发扬医院良好传统,竭尽全力地满足患者的医疗康复需要!        肛肠科以“专业技能扎实,临床经验丰富,为患者解除痛苦”为己任,以“技术与服务”为核心竞争力...',
      },
      {
        name: '耳鼻喉科',
        code: 'otorhinolaryngology',
        text: '鸡泽县中医院耳鼻喉科专业设置齐全，技术一流，自创建以来，深受广大患者好评，该科室拥有耳鼻喉大型综合检查治疗台及先进的内镜消毒系统，常规开展儿童张口呼吸的多学科联合治疗，鼻内镜下鼻窦炎鼻内窦开放、鼻中隔偏曲矫正、鼻中隔穿孔修复、鼻息肉摘除、过敏性鼻炎及鼻部肿瘤...',
      },
      {
        name: '超声科',
        code: 'ultrasonic',
        text: '鸡泽县中医院超声科实力雄厚，设备先进，拥有2台高档彩色多普勒超声仪。美国LOGIQ``E9是一部妇科、产科、腹部、心脏及浅表检查于一身的全能彩超机。美国GE730是一部四维妇产科彩超机。科室采用先进的超声图文工作站系统对报告及档案实行电脑化管理。可开展多种超声诊疗项目...',
      },
      {
        name: '妇产科',
        code: 'maternity',
        text: '鸡泽县中医院妇产科拥有一支业务水平高、技术精湛、作风踏实、服务一流的医疗护理队伍。科室坚持走中西医结合发展之路开展多种疾病的中西医结合治疗，如：不孕症、子宫异常出血、宫颈炎、盆腔炎、阴道炎及围产期疾病的治疗积累了丰富的临床经验。病房环境温馨，舒适...',
      },
      {
        name: '康复科',
        code: 'recovery',
        text: '鸡泽县中医院康复医学科包括康复门诊及康复住院病区。科室面积1000余平方米，病床30张，设备资产500余万元。现有康复医师5人、康复治疗师6人、康复护士4人。         科室设有:康复诊室、康复评定室、言语吞咽治疗室、运动治疗大厅、作业治疗室、物理因子治疗大厅、针灸治疗室...',
      },
      {
        name: '肾内科',
        code: 'intrarenal',
        text: '肾脏病是常见病和多发病，如果继续恶化可以发展成尿毒症，严重危害人们的健康，慢性肾病已经发展成继心脑血管病、肿瘤、糖尿病之后威胁人类健康的重要疾病，目前世界已超过5亿人患有各种肾脏病，成为全球公共卫生问题。        肾内科是为了诊疗肾脏病设置的一个临床科室，常见病有...',
      },
      {
        name: '口腔科',
        code: 'oralCavity',
        text: '我院口腔科以临床医疗为主，引进先进的数字诊断技术，对多种口腔科疾病实施全面的普查、检查、诊疗及修复。   口腔科现拥有进口综合口腔治疗台、口腔科专用高压消毒机、荷兰牙钻注油机等多种先进设备。为降低就医风险，口腔科严把消毒质量关，所有用物均“一人一用一消毒/灭菌”...',
      },
      {
        name: '外二科',
        code: 'surgery',
        text: '科是一个专业梯队合理，诊疗规范，技术精湛，医德高尚，既有中医特色又能适应现代化医院发展要求，进行各种外科常规及复杂手术的专科。诊疗范围：各种烧伤烫伤，化学伤电烧伤，烧伤整形，疤痕去除术，褥疮，长期伤口不愈合，腰椎间盘突出，腰椎管狭窄症，腰椎滑脱，颈椎病等脊柱退变性疾病...',
      },
      {
        name: '中医院手术室',
        code: 'operation',
        text: '手术室是医院对病人实施手术、治疗、检查、诊断并担负抢救工作的重要场所。   我们的服务宗旨:让人性化护理模式在手术中广泛推广，要求全体医护人员以全方位的视角，以最佳的心理护理措施，用爱心、同情心和高度地责任心，确保病人的安全，保证手术的顺利进行...',
      }
    ]

    this.setState({
      departmentList
    })
  }

  toDepartmentDetail(code){
    this.props.history.push(`/departmentDetail/${code}`)
  }

  render() {
    const quitTime = businessQuitTime.microService.departmentIntro;
    const { departmentList } = this.state;

    return (
      <div>
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <h2 className="crumbs">医院微网站服务 - 科室介绍</h2>
          <div className="c-content-scroll">
            <ul className="department-list">
              {
                departmentList.map((list, index) => {
                  return  (<li className="clearfix" onClick={() => this.toDepartmentDetail(list.code)} key={list.code}>
                    <div className="d-name">{ list.name }</div>
                    <div className="d-context">
                      <h3 className="d-title">{ list.name }</h3>
                      <p className="d-mini">
                        { list.text }          
                      </p>
                    </div>
                  </li>)
                })
              }
            </ul>
          </div>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage.bind(this)} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage.bind(this)}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}
