/*
 *  门诊
 */
export const addOutpatient = (data) => {
  return {
    type: "ADD_OUTPATIENT",
    data
  }
}

export const clearOutpatient = (data) => {
  return {
    type: "CLEAR_OUTPATIENT",
    data
  }
}
