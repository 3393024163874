import React, { Component } from 'react'
import Head from '../../components/head'
import { Button, Icon } from 'antd';
import businessQuitTime from '../../utils/businessQuitTime'

// redux
import { connect } from 'react-redux';
import * as cardInfoAction from '../../store/actions/cardInfo'

import './outpatientCost.scss'

/*
 * 当日门诊费用查询
 */
class OcPatientInfo extends Component {
  constructor(props){
    super(props);
    this.state = {
      userInfo: {}
    }
    this.goUpPage = this.goUpPage.bind(this);
    this.goHomePage = this.goHomePage.bind(this);
    this.gotoCostList = this.gotoCostList.bind(this);
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/outpatientCost')
  }

  componentDidMount(){
    this.setState({
      userInfo : this.props.cardInfo.userInfo
    })
  }

  gotoCostList(){
    this.props.history.push('/costList')
  }

  render() {
    const quitTime = businessQuitTime.outpatientCost.ocPatientInfo;
    const { userInfo } = this.state;
    const { balance='0', currentCard={} } = this.props.cardInfo || {};

    // 是否显示余额
    const showBalance = () => {
      if(currentCard.payMode !== 0 && currentCard.id === 'jzcard'){
        return <p>卡余额: <span>{ balance }元</span></p>
      }
    }

    return (
      <div>
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <h3 className="business-name">门诊费用查询 - 确认患者信息</h3>
          <div className="par-info">
            <p>姓名: <span>{ userInfo.NAME }</span></p>
            <p>性别: <span>{ userInfo.SEX === '2' ? '女' : '男' }</span></p>
            <p>年龄: <span>{ userInfo.AGE }</span></p>
            <p>身份证号: <span>{ userInfo.CERTI_NO }</span></p>
            <p>卡余额: <span>{ balance }元</span></p>
            <Button type="primary" size="large" onClick={this.gotoCostList} style={{width: '100%', backgroundColor: "#00bd58", border: "1px #00bd58 solid", marginTop: "20px"}}>确定</Button>
          </div>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    cardInfo: state.cardInfo
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addCard: (data) => {
      dispatch(cardInfoAction.addCard(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OcPatientInfo)