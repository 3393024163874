import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Head from '../../components/head/'
import { Button, Icon, notification } from 'antd';
import { connect } from 'react-redux';
import * as printAction from '../../store/actions/print'
import businessQuitTime from '../../utils/businessQuitTime'
import { getInvInfo, getPrintInvoice, queryInvoiceFile } from '@/utils/request'

/*
* 打印电子发票
*/
class PrintInvoice extends Component {
  constructor(props){
    super(props);
    this.state = {
      invoiceCode: '',
      invoiceNum: '',
      jym: '',
      serial: '',
      payer: '',
      date: '',
    };
    this.goUpPage = this.goUpPage.bind(this);
    this.goHomePage = this.goHomePage.bind(this);
    this.inputRef = React.createRef()
  }

  componentDidMount(){
    this.inputRef.current.focus()
    // this.bindKeyBoard()
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/')
  }

  // bindKeyBoard(){
  //   const that = this
  //   const val = 'http://nontax.hebcz.cn/static/esq?ec=13061020&en=0010561633&rn=506410'

  //   getInvInfo({
  //     qrcode: val
  //   })
  //   .then(od => {
  //     if(od.status){
  //       that.setState({
  //         invoiceCode: od.data['电子票据代码'],
  //         invoiceNum: od.data['电子票据号码'],
  //         jym: od.data['校验码']
  //       }, function(){
  //         that.queryPrintInvoice()
  //       })
        
  //     }
  //     else{
  //       notification.error({
  //         message: od.message,
  //         description: '错误',
  //         duration: 10,
  //       });
  //     }
  //   })
  //   .catch(err => {
  //     that.props.history.push({ 
  //       pathname : '/error',
  //       query : { messageTitle: '失败', messageContent: err.toString()}
  //     })
  //   })
  // }

  bindKeyBoard(evt){
    const that = this

    if(evt.keyCode === 13 || evt.keyCode === 108 || evt.keyCode === 32){
      const val = evt.target.value

      getInvInfo({
        qrcode: val
      })
      .then(od => {
        if(od.status){
          that.setState({
            invoiceCode: od.data['电子票据代码'],
            invoiceNum: od.data['电子票据号码'],
            jym: od.data['校验码']
          }, function(){
             that.queryPrintInvoice()
          })
        }
        else{
          notification.error({
            message: od.message,
            description: '错误',
            duration: 10,
          });
        }
      })
      .catch(err => {
        that.props.history.push({ 
          pathname : '/error',
          query : { messageTitle: '失败', messageContent: err.toString()}
        })
      })
    }
  }

  queryPrintInvoice(){
    const that = this
    const { invoiceCode, invoiceNum, jym } = this.state

    getPrintInvoice({
      "电子票据代码": invoiceCode,
      "电子票据号码": invoiceNum,
      "校验码": jym,
    })
    .then(od => {
      if(od.status){

        if(od.data.length === 0){
          notification.error({
            message: od.message,
            description: '错误',
            duration: 10,
          });
          return false
        }
        const res = od.data[0]
        that.setState({
          invoiceCode: res['电子票据代码'],
          invoiceNum: res['电子票据号码'],
          jym: res['校验码'],
          serial: res['业务流水号'],
          payer: res['交款人'],
          date: res['开票日期'],
        })
  
        // 获取电子票据文件信息
        that.getInvoiceFile()
      }
      else{
        notification.error({
          message: od.message,
          description: '错误',
          duration: 10,
        });
      }
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 获取电子票据文件信息
  getInvoiceFile(){
    const that = this
    const {
      invoiceCode,
      invoiceNum,
      jym,
      serial,
      payer,
      date,
    } = this.state

    queryInvoiceFile({
      "电子票据代码": invoiceCode,
      "电子票据号码": invoiceNum,
      "校验码": jym,
      "业务流水号": serial,
      "交款人": payer,
      "开票日期": date,
    })
    .then(od => {
      if(od.status){
        let invoiceFile = od.data;
        Object.assign(print, {printData: invoiceFile});
        that.props.addPrint({
          businessType: 1,
          printData: invoiceFile
        })

        // 去打印发票
        that.props.history.push({ 
          pathname : '/pubPrint',
          query : {}
        })
      }
      else{
        notification.error({
          message: od.message,
          description: '错误',
          duration: 10,
        });
      }
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  render() {
    const quitTime = businessQuitTime.print.printInvoice

    return (
      <div>
        <Head quitTime={quitTime} />
        <div className="context-container clearfix">
          <h2 className="crumbs">打印电子发票</h2>
          <h3 className="business-name">请扫码电子发票的二维码</h3>
          <div className="c-content">
            <div style={{width: '500px', margin: '10px auto 0'}}>
              <input type="text"
                ref={this.inputRef}
                onKeyUp={(evt) => this.bindKeyBoard(evt)}
                style={{width: '500px',
                height: '50px',
                marginTop: '20px',
                border: '1px #ccc solid',
                fontSize: '31px'}} />
            </div>
          </div>
        </div>
        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { print } = state;
  return {
    print,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addPrint: (data) => {
      dispatch(printAction.addPrint(data));
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PrintInvoice)