import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Head from '../../components/head'
import { Button, Icon } from 'antd';
import { connect } from 'react-redux';
import ShowCardImg from '@/components/showCardImg'
import * as cardInfoAction from '../../store/actions/cardInfo'
import * as baseAction from '../../store/actions/base'
import businessQuitTime from '../../utils/businessQuitTime'
import './printOutpatientResult.scss'

class PrintOutpatientResult extends Component {
  constructor(props){
    super(props);
    this.state = {};
    this.goUpPage = this.goUpPage.bind(this);
    this.goHomePage = this.goHomePage.bind(this);
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/')
  }

  render() {
    const quitTime = businessQuitTime.printOutpatientResult.printOutpatientResult;
    const readCardQuitTime = businessQuitTime.outpatientCost.readCard;
    const { base } = this.props; 
    let mediumList = [];
    if(base){
      let cardList = base.cardList || [];
      cardList.forEach((card) => {
        if(card.resourcesMode !== 0){
          mediumList.push(card)
        }
      })
    }

    return (
      <div>
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <h2 className="crumbs">门诊检验结果查询打印 - 选择办卡介质</h2>
          <h3 className="business-name">请选择查询方式</h3>
          <div className="c-content">
            <div className="medium-List">
              {
                mediumList.map((medium) => {
                  return (
                    <Link to={`/readCard/outpatientInfo/${medium.id}/${readCardQuitTime}/303`} key={medium.id}>
                      <ShowCardImg cardId={medium.id} />
                      <i>{ medium.name }</i>
                    </Link>
                  )
                })
              }
            </div>
          </div>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { base, cardInfo } = state;
  return {
    base,
    cardInfo,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addBase: (data) => {
      dispatch(baseAction.addBase(data));
    },
    addCard: (data) => {
      dispatch(cardInfoAction.addCard(data));
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PrintOutpatientResult)
