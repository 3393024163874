import React, { Component } from 'react'
import { Button, Icon } from 'antd'
import Head from '../../components/head'
import businessQuitTime from '../../utils/businessQuitTime'
import { connect } from 'react-redux'
import './record.scss'

/*
 * 确认银行卡身份证信息
 */
class BankUserInfo extends Component {
  constructor(props){
    super(props)
    this.state = {
      cardInfo: {}
    };

    this.jumpWritePhone = this.jumpWritePhone.bind(this)
    this.goUpPage = this.goUpPage.bind(this)
    this.goHomePage = this.goHomePage.bind(this)
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/createArchives')
  }

  jumpWritePhone(){
    this.props.history.push('/insertPhone')
  }

  componentDidMount(){
    this.setState({
      cardInfo: this.props.createCard.createCard
    })
  }

  render() {
    const { bankUserInfo } = businessQuitTime.createArchives
    const { NAME, CERTI_NO, SEX } = this.props.createCard

    return (
      <div>
        <Head quitTime={bankUserInfo} />
        <div className="context-container clearfix">
          <h2 className="crumbs">建档发卡 - 读身份证</h2>
          <h3 className="business-name">请确认你的身份信息</h3>
          <div className="con-card">
            <div className="create-bankinfo">
              <span>姓名: {NAME}</span>
              <span>性别: {SEX === '1' ? '男' : '女'}</span>
              <span>身份证: {CERTI_NO}</span>
            </div>
            <em>
              <Button type="primary" size="large" onClick={ this.jumpWritePhone } style={{backgroundColor: "#00bd58", border: "1px #00bd58 solid"}}>确 定</Button>
            </em>
          </div>
        </div>
        <div className="footer">
          <Button type="primary" size="large" onClick={ this.goUpPage } className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={ this.goHomePage }
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    createCard: state.createCard
  }
}

export default connect(mapStateToProps)(BankUserInfo)