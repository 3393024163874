import React, { Component } from 'react'
import { Button, Icon, Input } from 'antd'
import Head from '../../components/head'
import { queryPatientinfoFromLIS } from '@/utils/request'
import { connect } from 'react-redux'
import DigitalKeyboard from '../../components/digitalKeyboard'
import * as Action from '@/store/actions/createCard'

/*
 * 写入患者身份证号码  130634 19651014 071X
 */
class WriteIdCardNo extends Component {
  constructor(props){
    super(props)
    this.state = {
      idCardNo: '',
    };

    this.goHomePage = this.goHomePage.bind(this)
  }

  goHomePage(){
    this.props.history.push('/')
  }

  componentDidMount(){

  }

  insertIdCard(val) {
    this.setState({
      idCardNo: val
    })
  }

  // 获取患者身份信息
  searchPatientIdCard(){
    const that = this
    const { idCardNo } = this.state

    if(idCardNo === ''){
      notification.error({
        message: `错误`,
        description: response.message || '',
        duration: 10,
      })

      return false
    }

    queryPatientinfoFromLIS({
      NUMBER: idCardNo
    })
    .then(od => {
      if(od.status){
        let [ datas ] = od.data
        
        if(datas){
          // 未传性别、出生年月日信息，重身份证获取
          datas['SEX'] = datas.NUMBER.substring(16, 17) % 2 ? '男' : '女'
          datas['BIRTHDAY'] = datas.NUMBER.substring(6, 14);
          that.idCard = datas
          that.props.addCardData(datas)
          that.props.history.push(`/nucleicAcidPatientInfo`)
        }
        else{
          notification.error({
            message: '错误',
            description: '未获取到患者信息',
            duration: 10,
          })
        }
      }
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  render() {
    const { idCardNo } = this.state

    return (
      <div>
        <Head quitTime={this.props.match.params.quitTime} />
        <div className="context-container clearfix">
          <h2 className="crumbs">核酸结果打印 - 输入身份证号码</h2>
          <div className="drugs-box">
            <div style={{width: "650px", margin: "40px auto 10px", overflow: "hidden"}}>
              <div style={{ width:"100%", float: "left" }}><Input size="large" placeholder="" value={idCardNo} /></div>
            </div>
            <DigitalKeyboard getKeyboardNumber={this.insertIdCard.bind(this)} confirmBtn={this.searchPatientIdCard.bind(this)} addChars={['X']} />
          </div>
        </div>
        <div className="footer">
          <Button type="primary" size="large" onClick={ this.goHomePage }
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    base: state.base,
    cardData: state.cardData
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addCardData: (data) => {
      dispatch(Action.addCardData(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WriteIdCardNo)