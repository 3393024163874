import React, { Component } from 'react'
import { Button, Input, Icon, notification } from 'antd'
import Head from '../../components/head'
import businessQuitTime from '../../utils/businessQuitTime'
import DigitalKeyboard from '../../components/digitalKeyboard'
import { eJjkSignup, registerHealthCard } from '../../utils/request'
import { connect } from 'react-redux'
import * as Action from '@/store/actions/createCard'
import { makePatient} from '@/utils/request'
import './index.scss'

/*
 * 输入手机号
 */
class HealthInsertPhone extends Component {
  constructor(props){
    super(props)
    this.state = {
      phone: ''
    }

    this.getPhoneNumber = this.getPhoneNumber.bind(this);
    this.successInsPhone = this.successInsPhone.bind(this);
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/healthConfirmIdCard')
  }

  getPhoneNumber(phone){
    this.setState({
      phone
    })
  }

  successInsPhone(){
    const phoneNum = this.state.phone;
    if(phoneNum.length === 0){
      notification.error({
        message: '提示',
        description: '请填写手机号码',
        duration: 10,
      });
      return false;
    }
    if(phoneNum.length+1 !== 12){
      notification.error({
        message: '提示',
        description: '请正确输入手机号码',
        duration: 10,
      });
      return false
    }

    // 存储手机号码
    const createCardData = Object.assign(this.props.createCard, {phone: phoneNum})
    this.props.addCardData(createCardData)
    this.sendJKkSignup()

    // this.sendRegisterHealthCard()
    // this.props.history.push('/grantCard')
  }

  // 本地服务接口注册电子健康卡
  sendJKkSignup() {
    const that = this
    const { NAME, NUMBER, phone, SEX, BIRTHDAY, ADDRESS, PEOPLENATIONCODE } = this.props.createCard
    const sexs = SEX === '男' ? 1 : 2
    let deviceId = localStorage.getItem('deviceId')
    if(deviceId){
      deviceId = Number(deviceId) < 10 ? `0${deviceId}` : deviceId
    }
    const params = {
      NAME,
      IDNO: NUMBER,
      SEX: sexs,
      NATION: PEOPLENATIONCODE,
      BIRTHDAY,
      PHONE: phone,
      DEVICE_ID: deviceId
    }

    eJjkSignup(params)
    .then(od => {
      params['ERHCCARDNO'] = od.data.ERHCCARDNO
      let createCardData = Object.assign(this.props.createCard, params)
      that.props.addCardData(createCardData);
      this.props.history.push('/healthShowQrcode')
    })
    .catch(err => {
      that.props.history.push({
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 后台服务注册电子健康卡
  // sendRegisterHealthCard() {
  //   const that = this
  //   const { NAME, NUMBER, phone, SEX, BIRTHDAY, ADDRESS, PEOPLENATIONCODE } = this.props.createCard
  //   const sexs = SEX === '男' ? 1 : 2
  //   let deviceId = localStorage.getItem('deviceId')
  //   if(deviceId){
  //     deviceId = Number(deviceId) < 10 ? `0${deviceId}` : deviceId
  //   }
  //   const params = {
  //     NAME,
  //     IDNO: NUMBER,
  //     SEX: sexs,
  //     NATION: PEOPLENATIONCODE,
  //     BIRTHDAY,
  //     PHONE: phone,
  //     DEVICE_ID: deviceId
  //   }

  //   registerHealthCard(params)
  //   .then(od => {
  //     params['ERHCCARDNO'] = od.data.ERHCCARDNO
  //     let createCardData = Object.assign(this.props.createCard, params)
  //     that.props.addCardData(createCardData);
  //     this.props.history.push('/healthShowQrcode')
  //   })
  //   .catch(err => {
  //     that.props.history.push({ 
  //       pathname : '/error',
  //       query : { messageTitle: '失败', messageContent: err.toString()}
  //     })
  //   })
  // }

  render() {
    return (
      <div>
        <Head quitTime={businessQuitTime.createArchives.insertPhone} />
        <div className="context-container clearfix">
          <h2 className="crumbs">注册电子健康卡 - 录入手机号</h2>
          <h3 className="business-name">请输入本人手机号码</h3>
          <div className="ins-phone">
            <Input size="large" placeholder="" value={this.state.phone} />
          </div>
          <DigitalKeyboard getKeyboardNumber={this.getPhoneNumber} confirmBtn={this.successInsPhone} />
        </div>
        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage.bind(this)} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage.bind(this)}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { base, createCard } = state
  return {
    base,
    createCard
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addCardData: (data) => {
      dispatch(Action.addCardData(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HealthInsertPhone)