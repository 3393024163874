// 目录 reducer/index.js
import {combineReducers} from "redux";
import base from './base'
import currentBusiness from './currentBusiness'
import createCard from './createCard'
import cardInfo from './cardInfo'
import recharge from './recharge'
import payment from './payment'
import inpatient from './inpatient'
import outpatient from './outpatient'
import register from './register'
import admin from './admin'
import print from './print'

export default combineReducers({
  base,
  currentBusiness,
  createCard,
  cardInfo,
  recharge,
  payment,
  inpatient,
  outpatient,
  register,
  admin,
  print,
});
