import React, { Component } from 'react'
import Head from '../../components/head'
import { Button, Icon } from 'antd';
import businessQuitTime from '../../utils/businessQuitTime'
import { connect } from 'react-redux';

class TtPatientInfo extends Component {
  constructor(props){
    super(props);
    this.state = {}

    this.goUpPage = this.goUpPage.bind(this);
    this.goHomePage = this.goHomePage.bind(this);
    this.gotoTakeNumList = this.gotoTakeNumList.bind(this);
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/todayTakeNumber')
  }

  gotoTakeNumList(){
    this.props.history.push('/takeNumList')
  }

  render() {
    const quitTime = businessQuitTime.todayRegister.ttPatientInfo;
    const { userInfo={} } = this.props.cardInfo || {};

    return (
      <div>
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <h3 className="business-name">预约取号 - 确认患者信息</h3>
          <div className="par-info">
            <p>姓名: <span>{ userInfo.NAME }</span></p>
            <p>性别: <span>{ userInfo.SEX === '2' ? '女' : '男' }</span></p>
            <p>年龄: <span>{ userInfo.AGE }</span></p>
            <p>身份证号: <span>{ userInfo.CERTI_NO }</span></p>
            <p>卡余额: <span>{ this.props.cardInfo.balance }元</span></p>
            <Button type="primary" size="large" onClick={this.gotoTakeNumList} style={{width: '100%', backgroundColor: "#00bd58", border: "1px #00bd58 solid", marginTop: "20px"}}>确定</Button>
          </div>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    cardInfo: state.cardInfo
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addCard: (data) => {
      dispatch(cardInfoAction.addCard(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TtPatientInfo)