import React, { Component } from 'react'
import { Input, notification } from 'antd';
import AdminHead from '../adminHeader'
import { connect } from 'react-redux';
import DigitalKeyboard from '@/components/digitalKeyboard/'
import '../admin.scss'

/*
* 补打打印单
*/
class PrintData extends Component {
  constructor(props){
    super(props);
    this.state = {
      orderId: ''
    };

    this.getOrderId = this.getOrderId.bind(this);
    this.successOrderId = this.successOrderId.bind(this);
  }
  
  componentDidMount () {

  }

  getOrderId(orderId){
    this.setState({
      orderId
    })
  }

  successOrderId(){
    const { orderId } = this.state;

    if(orderId.length === 0){
      notification.error({
        message: '提示',
        description: '请填写订单号',
        duration: 10,
      });
      return false;
    }

    if(orderId.length !== 6){
      notification.error({
        message: '提示',
        description: '订单号为6位数字',
        duration: 10,
      });
      return false;
    }
    // 跳转到发卡页面
    this.props.history.push(`/printData/${orderId}`);
  }
  
  render() {
    const { orderId } = this.state;

    return (
      <React.Fragment>
        <AdminHead />

        <div className="adm-content">
          <h1>请输入订单号后六位数字</h1>
          <div className="ins-phone">
            <Input size="large" placeholder="" value={orderId} />
          </div>
          <DigitalKeyboard getKeyboardNumber={this.getOrderId} confirmBtn={this.successOrderId} />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    cardInfo: state.cardInfo,
    payment: state.payment
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addPayment: (data) => {
      dispatch(paymentAction.addPayment(data))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintData)