const payment = (state = {}, action) => {
  switch(action.type){
    case 'ADD_PAYMENT':
      return action.data;

    case 'CLEAR_PAYMENT':
      return null;

    default:
      return state;
  }
}

export default payment