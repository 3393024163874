import React, { Component } from 'react'
import Head from '../../components/head/'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import * as baseAction from '@/store/actions/base'
import { Button, Icon } from 'antd';
import './main.scss'

class MoreFunctions extends Component {
  constructor(props){
    super(props);
    this.state = {
      basicMenuList: [],
      outpatientMenuList: [],
      inpatientMenuList: [],
      otherMenuList: [],
    };
    this.goUpPage = this.goUpPage.bind(this);
    this.gotoAmdin = this.gotoAmdin.bind(this);
  }

  goUpPage(){
    this.props.history.push('/')
  }
  gotoAmdin(){
    this.props.history.push('/adminLogin')
  }

  componentDidMount(){
    const { basic, inpatient, other, outpatient } = this.props.base.menuList.moreFunction;
    
    this.setState({
      basicMenuList: basic, 
      inpatientMenuList: inpatient, 
      otherMenuList: other, 
      outpatientMenuList: outpatient 
    })
  }
  
  render() {
    const { basicMenuList=[], outpatientMenuList=[], inpatientMenuList=[], otherMenuList=[] } = this.state;

    const basicMenu = () =>{
      let basicArr = [];
      if(basicMenuList.length > 0){
        basicMenuList.forEach((basic, idx, arr) => {
          basicArr.push(<li key={idx}>
            <Link to={basic.link}>
              <i className={basic.icon}></i>
              <span>{basic.name}</span>
            </Link>
          </li>);
        })
      }
      return basicArr
    }

    const outpatientMenu = () =>{
      let outpatientArr = [];
      if(outpatientMenuList.length > 0){
        outpatientMenuList.forEach((outpatient, idx, arr) => {
          outpatientArr.push(<li key={idx}>
            <Link to={outpatient.link}>
              <i className={outpatient.icon}></i>
              <span>{outpatient.name}</span>
            </Link>
          </li>);
        })
      }
      return outpatientArr
    }

    const inpatientMenu = () =>{
      let inpatientArr = [];
      if(inpatientMenuList.length > 0){
        inpatientMenuList.forEach((inpatient, idx, arr) => {
          inpatientArr.push(<li key={idx}>
            <Link to={inpatient.link}>
              <i className={inpatient.icon}></i>
              <span>{inpatient.name}</span>
            </Link>
          </li>);
        })
      }
      return inpatientArr
    }

    const otherMenu = () =>{
      let otherArr = [];
      if(otherMenuList.length > 0){
        otherMenuList.forEach((other, idx, arr) => {
          otherArr.push(<li key={idx}>
            <Link to={other.link}>
              <i className={other.icon}></i>
              <span>{other.name}</span>
            </Link>
          </li>);
        })
      }
      return otherArr
    }

    return (
      <div>
        <Head />

        <div className="mf-content">
          <div className="mf-row clearfix">
            <h1 className="mf-til">医院微网站服务</h1>
            <ul>
              { basicMenu() }
            </ul>
          </div>

          <div className="mf-row clearfix">
            <h1 className="mf-til">门诊服务</h1>
            <ul>
              { outpatientMenu() }
            </ul>
          </div>

          <div className="mf-row clearfix">
            <h1 className="mf-til">住院服务</h1>
            <ul>
              { inpatientMenu() }
            </ul>
          </div>

          <div className="mf-row clearfix">
            <h1 className="mf-til">其他服务</h1>
            <ul>
              { otherMenu() }
            </ul>
          </div>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage} style={{ backgroundColor: "#61b0ef", "float": "right", border: "1px #61b0ef solid"}}>
            <Icon type="arrow-left" />返回主菜单</Button>
          <Button type="primary" size="large" onClick={this.gotoAmdin} style={{ "backgroundColor": "#00bd58", border: "1px #00bd58 solid", "float": "left"}}>
            <Icon type="team" theme="outlined" />管理员进入</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    base: state.base
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addBase: (data) => {
      dispatch(baseAction.addBase(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MoreFunctions)