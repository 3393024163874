import React, { Component } from 'react'
import { Button, Icon } from 'antd'
import Head from '../../components/head'
import { getReadIDCard } from '@/utils/request'
import { connect } from 'react-redux'
import * as Action from '@/store/actions/createCard'
import BrushCardImg from '@/assets/img/cardId.png'

/*
 * 读取身份证信息
 */
class ReadIdCard extends Component {
  constructor(props){
    super(props)
    this.state = {
      idCard: {},
    };

    this.goHomePage = this.goHomePage.bind(this)
  }

  goHomePage(){
    this.props.history.push('/')
  }

  componentDidMount(){
    this.readIDCard()
  }

  // 读取身份证
  readIDCard(){
    const that = this

    getReadIDCard()
    .then(od => {
      if(od.status){
        that.idCard = od.data
        that.props.addCardData(od.data)
        that.props.history.push(`/confirmIdCard`)
      }
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  render() {
    return (
      <div>
        <Head quitTime={this.props.match.params.quitTime} />
        <div className="context-container clearfix">
          <h2 className="crumbs">读身份证</h2>
          <h3 className="business-name">请将身份证放置在感应区</h3>
          <div className="read-card">
            <img src={BrushCardImg} alt=""/>
          </div>
        </div>
        <div className="footer">
          <Button type="primary" size="large" onClick={ this.goHomePage }
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    base: state.base,
    cardData: state.cardData
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addCardData: (data) => {
      dispatch(Action.addCardData(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReadIdCard)