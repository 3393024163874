export const addRegister = (data) => {
  return {
    type: "ADD_REGISTER",
    data
  }
}

export const clearRegister = (data) => {
  return {
    type: "CLEAR_REGISTER",
    data
  }
}