const inpatient = (state = {}, action) => {
  switch(action.type){
    case 'ADD_INPATIENT':
      return action.data;

    case 'CLEAR_INPATIENT':
      return null;

    default:
      return state;
  }
}

export default inpatient