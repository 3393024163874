import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Head from '../../components/head/'
import { Button, Icon } from 'antd';
import businessQuitTime from '../../utils/businessQuitTime'

import medicalCard from '../../assets/img/jzcard.png'

export default class AppointmentRegister extends Component {
  constructor(props){
    super(props);
    this.state = {}
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/')
  }

  render() {
    const quitTime = businessQuitTime.appointmentRegister.appointmentRegister;
    const readCardQuitTime = businessQuitTime.appointmentRegister.readCard;

    return (
      <div>
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <h2 className="crumbs">预约挂号 - 选择就诊介质</h2>
          <h3 className="business-name">请选择就诊介质</h3>
          <div className="c-content">
            <Link to={`/readCard/trPatientInfo/${readCardQuitTime}`}>
              <span><img src={medicalCard} alt="" /></span>
              <i>就诊卡</i>
            </Link>
          </div>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage.bind(this)} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage.bind(this)}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}
