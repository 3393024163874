import React, { Component } from 'react'
import Head from '../../components/head'
import { Button, Icon } from 'antd';
import { connect } from 'react-redux';
import QRCode from 'qrcode.react';
import businessQuitTime from '../../utils/businessQuitTime'

class healthShowQrcode extends Component {
  constructor(props){
    super(props);
    this.state = {
      resMessage: '电子健康卡注册成功，可拍照留存二维码用于后续就诊',
      erhcCardQr: '',
    };
    this.goUpPage = this.goUpPage.bind(this);
    this.goHomePage = this.goHomePage.bind(this);
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/insertPhone')
  }
  
  componentDidMount(){
    const { ERHCCARDNO } = this.props.createCard;
    this.setState({
      erhcCardQr: ERHCCARDNO+':1'
    })
  }

  render() {
    const quitTime = businessQuitTime.testResultsPrint.hospitalizedPrint;
    const { resMessage, erhcCardQr } = this.state;
    const renderRq = () => {
      if(erhcCardQr){
        return <QRCode value={ erhcCardQr } size="140" />
      }
    }

    return (
      <div>
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <h2 className="crumbs">注册电子健康卡 - 注册成功</h2>
          <div className="health-qr">
            { renderRq() }
          </div>
          <div className="health-til">{resMessage}</div>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { base, createCard } = state
  return {
    base,
    createCard,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(healthShowQrcode)