import React, { Component } from 'react'
import { Input, Modal, notification } from 'antd';
import LetterAndNumKeyboard from '../LetterAndNumKeyboard'
import config from '@/config.js'
import './index.scss'

/*
* 输入医保密码
*/
export default class MedicalInsurPassword extends Component {
  constructor(props){
    super(props);
    this.state = {
      insurPasswordModal: false,
      psText: ''
    }
  }

  componentDidMount() {
    this.setState({
      psText: ''
    })
  }

  insText(str){
    this.setState({
      psText: str
    })
  }

  // 键盘上触发确认
  clickConfirmBtn() {
    const { psText } = this.state

    if(psText === ''){
      notification.error({
        message: '提示',
        description: '请输入医保密码！',
        duration: 10,
      })
      return false
    }


    if(this.props.confirmInsurPasswrod){
      this.props.confirmInsurPasswrod(psText)
    }

    this.setState({
      insurPasswordModal: false,
      psText: '',
    })

    this.refs.letterRef.clearLertter()
  }

  openModal() {
    this.setState({
      insurPasswordModal: true
    })
    // const that = this
    // window.setTimeout(function(){
    //   debugger;
    //   that.refs.psRef.focus()
    // }, 2000)
  }

  handleCancel(){
    if(this.props.cancelClick){
      this.props.cancelClick()
    }

    this.setState({
      insurPasswordModal: false,
      psText: '',
    })

    this.refs.letterRef.clearLertter()
  }

  render() {
    const { psText, insurPasswordModal } = this.state

    return (
      <Modal 
        title="请输入医保密码" 
        width="90%"
        cancelText={this.props.cancelText || "取消"}
        okText="确定"
        visible={insurPasswordModal} 
        onOk={() => this.clickConfirmBtn()}
        onCancel={() => this.handleCancel()}
        style={config.modalTop}
      >
        <div className="insurModalBox">
          <div style={{width: "100%", margin: "20px auto 0", backgroundColor: "#f0f5f7"}}>
            <Input  
              ref={function (input) {
                if (input != null) {
                  input.focus();
                }
              }}
              type='password' size="large" placeholder="" value={psText} />
          </div>
          <LetterAndNumKeyboard 
            ref="letterRef"
            letterText={(str) => this.insText(str)} 
            confirmBtn={() => this.clickConfirmBtn()} 
          />
        </div>
      </Modal>
    )
  }
}
