import React, { Component } from 'react'
import Head from '../../components/head'
import { Button, Icon, Input, notification } from 'antd';
import DigitalKeyboard from '../../components/digitalKeyboard'
import { connect } from 'react-redux';
import * as inpatientAction from '@/store/actions/inpatient'
import { getDepositCost } from '@/utils/request'
import businessQuitTime from '../../utils/businessQuitTime'

class InsertHospitalized extends Component {
  constructor(props){
    super(props);
    this.state = {
      cardNo: '',
      hospitaliNum: ''
    };
    this.goUpPage = this.goUpPage.bind(this);
    this.goHomePage = this.goHomePage.bind(this);
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/printHospitalizationResult')
  }

  getInpatient(num){
    this.setState({
      cardNo: num
    })
  }

  sendInpatient(){
    const cardNo = this.state.cardNo;

    if(cardNo === ''){
      notification.error({
        message: `错误`,
        description: '请输入住院号',
        duration: 10,
      });
      return false;
    }
    this.queryDepositCost(cardNo);
  }

  // 患者信息
  queryDepositCost(hospitaliNum){
    const that = this;

    getDepositCost({
      in_no: hospitaliNum
    })
    .then(data => {
      if(data.status){
        let patientInfo = {};
        let od = data.data[0];

        for(let key in od){
          patientInfo[key] = String.trim(od[key]);
        }
        that.props.addInpatient({
          inpatientNo: hospitaliNum,
          patientInfo,
        })

        that.props.history.push('/hospitalizedInfo')
      }
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  render() {
    const quitTime = businessQuitTime.testResultsPrint.insertHospitalized;
    const { cardNo } = this.state;

    return (
      <div>
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <h2 className="crumbs">住院检验结果查询打印 - 输入住院号</h2>
          <div className="hc-cont">
            <Input size="large" placeholder="" value={cardNo} id="zyh" className="ins-inpatient" />
            <DigitalKeyboard getKeyboardNumber={this.getInpatient.bind(this)} confirmBtn={this.sendInpatient.bind(this)} />
          </div>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    cardInfo: state.cardInfo,
    inpatient: state.inpatient,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addInpatient: (data) => {
      dispatch(inpatientAction.addInpatient(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InsertHospitalized)