import React, { Component } from 'react'
import Head from '../../components/head'
import { Button, Icon, Table } from 'antd';
import businessQuitTime from '../../utils/businessQuitTime'

// redux
import { connect } from 'react-redux';
import * as cardInfoAction from '../../store/actions/cardInfo'

import { queryDrugPrice } from '@/utils/request'

/*
 * 药品价格查询列表
 */
class DrugpriceList extends Component {
  constructor(props){
    super(props);
    this.state = {

      // 表数据
      pageSize: 7,
      loading: true,
      columns: [
        {
          title: '药品名称',
          dataIndex: 'M_NAME',
        }, 
        {
          title: '批号',
          dataIndex: 'MST_NO',
        }, 
        {
          title: '规格',
          dataIndex: 'M_SPEC',
        }, 
        {
          title: '剂型',
          dataIndex: 'M_DOSAGE',
        },
        {
          title: '包装单位',
          dataIndex: 'M_UNIT',
        },
        {
          title: '价格',
          dataIndex: 'M_R_PRICE',
        },
        {
          title: '药品种类',
          dataIndex: 'CL_STAT_KIND',
        },
        {
          title: '厂家',
          dataIndex: 'MF_NAME',
        },
      ],
      curOutFeeList: [],
    }

    this.goUpPage = this.goUpPage.bind(this);
    this.goHomePage = this.goHomePage.bind(this);
  }
  
  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/dpPatientInfo')
  }

  componentDidMount(){
    this.getDrugPrice();

    let userInfo = this.props.cardInfo.userInfo
    this.setState({
      username: userInfo.NAME,
      sex: userInfo.SEX === '2' ? '女' : '男',
      age: userInfo.AGE,
      cardNo: userInfo.CERTI_NO,
    })
  }

  // 获取药品列表
  getDrugPrice(){
    const that = this;

    queryDrugPrice({
      drugname: ''
    })
    .then(od => {
      if(od.status){
        let dataList = [];
        this.setState({
          curOutFeeList: dataList
        })
      }
      that.setState({
        loading: false
      })
    })
    .catch(err => {
      that.setState({
        loading: false
      })
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  render() {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      // onSelection: this.onSelection,
    };
    const quitTime = businessQuitTime.outpatientCost.costList;
    const { username, sex, age, cardNo, pageSize, columns, curOutFeeList, loading } = this.state;

    return (
      <div>
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <h3 className="business-name">门诊费用查询 - 当日门诊费用</h3>
          <div className="rec-amount-info">
            <span>姓名: { username }</span>
            <span>性别: { sex }</span>
            <span>年龄: { age }</span>
            <span>身份证号码: { cardNo }</span>
          </div>

          <Table pagination={pageSize}
                 columns={columns} 
                 dataSource={curOutFeeList}
                 loading={loading}
                 className="mt-25"  />
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    cardInfo: state.cardInfo
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addCard: (data) => {
      dispatch(cardInfoAction.addCard(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DrugpriceList)