import React, { Component } from 'react'
import Head from '../../components/head'
import DigitalKeyboard from '../../components/digitalKeyboard'
import { Button, Icon, Input, notification } from 'antd'
import businessQuitTime from '../../utils/businessQuitTime'
import { getDepositCost } from '@/utils/request'
import { connect } from 'react-redux'
import * as inpatientAction from '../../store/actions/inpatient'
import './inpatientPay.scss'

/*
 * 住院押金
 */
class InpatientPay extends Component {
  constructor(props){
    super(props)
    this.state = {
      inNo: ''
    }

    this.goUpPage = this.goUpPage.bind(this)
    this.goHomePage = this.goHomePage.bind(this)
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/')
  }

  getInNo(val){
    this.setState({
      inNo: val
    })
  }

  getPatientNo(){
    const that = this
    const { inNo } = this.state

    if(inNo === ''){
      notification.error({
        message: `错误`,
        description: '请输入住院号',
        duration: 10,
      })
      return false
    }

    that.queryDepositCost(inNo)
  }

  // 获取患者信息和押金余额
  queryDepositCost(inNo){
    const that = this

    getDepositCost({
      in_no: inNo
    })
    .then(data => {
      if(data.status){
        let patientInfo = {}
        let od = data.data[0]

        for(let key in od){
          patientInfo[key] = String.trim(od[key])
        }
        
        // 用户三要素脱敏
        const { NAME, CERTI_NO } = patientInfo
        patientInfo['NAME'] = NAME && this.userNameEncryption(NAME)
        patientInfo['COMP_NAME'] = NAME

        // patientInfo.userInfo['PHONE'] = PHONE && this.phoneEncryption(PHONE)
        patientInfo['CERTI_NO'] = CERTI_NO && this.idCardEncryption(CERTI_NO)
        patientInfo['COMP_CERTI_NO'] = CERTI_NO

        that.props.addInpatient({
          inpatientNo: inNo,
          patientInfo,
        })
        that.props.history.push('/hpPatientInfo')
      }
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

   // 身份证脱敏
   idCardEncryption(idCard){
    return idCard.replace(/^(.{3}).+(.{3})$/,"$1************$2")
  }

  // 手机号脱敏
  phoneEncryption(phone){
    return phone.replace(/^(.{3}).+(.{4})$/,"$1****$2")
  }

  // 姓名脱敏
  userNameEncryption(userName){
    let name = ''

    switch(userName.length){
      case '2':
        name = userName.replace(/^(.).+$/,"$1*")
        break

      case '3':
        name = userName.replace(/^(.).+(.)$/,"$1*$2")
        break

      case '4':
        name = userName.replace(/^(.).+(.)$/,"$1**$2")
        break
        
      default:
        name = userName.replace(/^(.).+(.)$/,"$1***$2")
        break
    }
    return name
  }

  render() {
    const quitTime = businessQuitTime.inpatientPay.inpatientPay
    const { inNo } = this.state

    return (
      <div>
        <Head quitTime={quitTime} />
          
        <div className="context-container clearfix">
          <h3 className="business-name">住院押金 - 请输入您的住院号</h3>
          <div className="h-p-c">
            <Input size="large" placeholder="" value={inNo} style={{margin: "20px 0"}} />
            <DigitalKeyboard getKeyboardNumber={this.getInNo.bind(this)} confirmBtn={this.getPatientNo.bind(this)} />
          </div>
        </div>
        
        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>          
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    inpatient: state.inpatient,
    cardInfo: state.cardInfo,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addInpatient: (data) => {
      dispatch(inpatientAction.addInpatient(data))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InpatientPay)