import React, { Component } from 'react'
import { Button, Icon } from 'antd'
import Head from '../../components/head'
import businessQuitTime from '../../utils/businessQuitTime'
import { connect } from 'react-redux'
import * as Action from '@/store/actions/createCard'
import './record.scss'
import tempCard from '../../assets/img/tempCard.png'

/*
 * 确认身份证信息
 */
class ConfirmIdCard extends Component {
  constructor(props){
    super(props)
    this.state = {
      cardInfo: null
    };

    this.jumpWritePhone = this.jumpWritePhone.bind(this)
    this.goUpPage = this.goUpPage.bind(this)
    this.goHomePage = this.goHomePage.bind(this)
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/createArchives')
  }

  jumpWritePhone(){
    this.props.history.push('/insertPhone')
  }

  componentDidMount(){
    console.log('建档发卡展示身份信息：', JSON.stringify(this.props.createCard.createCard))
    this.setState({
      cardInfo: this.props.createCard.createCard.userInfo
    })
  }

  render() {
    const { cardInfo } = this.state;

    return (
      <div>
        <Head quitTime={businessQuitTime.createArchives.confirmIdCard} />
        <div className="context-container clearfix">
          <h2 className="crumbs">建档发卡 - 请确认你的身份信息</h2>
          <div className="con-card">
            <div className="idcard-con">
              <div className="idcard-name pos-a">{ cardInfo && cardInfo.NAME }</div>
              <div className="idcard-sex pos-a">{ cardInfo && cardInfo.SEX }</div>
              <div className="idcard-peopleNation pos-a">{ cardInfo && cardInfo.PEOPLENATION }</div>
              <div className="idCard-birthday pos-a">{ cardInfo && cardInfo.BIRTHDAY }</div>
              <div className="idcard-address pos-a">{ cardInfo && cardInfo.ADDRESS }</div>
              <div className="idcard-card pos-a">{ cardInfo && cardInfo.NUMBER }</div>
              <div className="idCard-img">
                <img src={tempCard} alt=""/>
              </div>
            </div>
            <em>
              <Button type="primary" size="large" onClick={ this.jumpWritePhone } style={{backgroundColor: "#00bd58", border: "1px #00bd58 solid"}}>确 定</Button>
            </em>
          </div>
        </div>
        <div className="footer">
          <Button type="primary" size="large" onClick={ this.goUpPage } className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={ this.goHomePage }
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    createCard: state
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addCardData: (data) => {
      dispatch(Action.addCardData(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmIdCard);