import React, { Component } from 'react'
import Head from '../../components/head'
import { Button, Icon } from 'antd';
import businessQuitTime from '../../utils/businessQuitTime'
import { getDept } from '@/utils/request'
import { connect } from 'react-redux';
import * as registerAction from '../../store/actions/register'
import './todayRegister.scss'

/*
 * 选择科室
 */
class TodaySelectDept extends Component {
  constructor(props){
    super(props);
    this.state = {
      deptData: [],
      dateData: [],
    }

    this.goToSelectDate = this.goToSelectDate.bind(this);
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/todayRegister')
  }

  goToSelectDate(){
    this.props.history.push('/todaySelectDoctor')
  }

  componentDidMount(){
    this.queryDept();
  }


  queryDept(){
    const that = this;

    getDept()
    .then(od => {
      if(od.status){
        const { dept, date } = od.data

        if(date.length === 0){
          notification.error({
            message: `错误`,
            description: '今日不能挂号',
            duration: 10,
          });
          that.props.history.push('/')
        }

        this.setState({
          deptData: dept
        })

        const registerObj = Object.assign(this.props.register || {}, {
          selectedDate: date[0]
        })
        that.props.addRegister(registerObj)
      }
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  selectDept(info){
    let registerObj = Object.assign(this.props.register, {
      selectedDept: info
    })
    this.props.addRegister(registerObj);

    this.props.history.push('/todaySelectDoctor');
  }

  render() {
    const quitTime = businessQuitTime.todayRegister.todaySelectDept;
    const { deptData } = this.state;
    const renderList = () => {
      let deptHtml = [];
      deptData.forEach((dept, idx, arr) => {
        deptHtml.push(<p onClick={this.selectDept.bind(this, dept)} key={idx}>{ dept.name }</p>)
      })
      return deptHtml;
    }
    
    return (
      <div>
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <h3 className="business-name">当日挂号 - 请选择科室</h3>
          <div className="dep-list">

            <div className="dep-cont">
              <div className="dep-cont-list clearfix">
                { renderList() }
              </div>
            </div>
          </div>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage.bind(this)} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage.bind(this)}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    register: state.register
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addRegister: (data) => {
      dispatch(registerAction.addRegister(data));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TodaySelectDept)