/* 充值数据 */
export const addRecharge = (data) => {
  return {
    type: "ADD_RECHARGE",
    data
  }
}

export const clearRecharge = (data) => {
  return {
    type: "CLEAR_RECHARGE",
    data
  }
}