import React, { Component } from 'react'
import { Button, Icon, Modal, notification } from 'antd'
import Head from '../../components/head'
import businessQuitTime from '../../utils/businessQuitTime'
import { connect } from 'react-redux'
import * as Action from '@/store/actions/createCard'
import * as PaymentAction from '@/store/actions/payment'
import { 
  getMakeCard, 
  getGetKey, 
  getReadCard, 
  getHisMakeCard, 
  sendWriteCard, 
  recycleCard,
  printVouCard,
  sendExitCard,
  sendHisRecharge,
  refund } from '@/utils/request'
import './record.scss'

/*
 * 发卡
 */
class GrantCard extends Component {
  constructor(props){
    super(props);
    this.state = {
      cardNo: '',
      displayNo: '',
      cardKey: '',
      isSignOutBtn: true,
      amountDialog: false,
      cardFee: '',
      isCardFee: false,     // true有卡费 false没有
      jzCard: {},
    }

    this.goUpPage = this.goUpPage.bind(this)
    this.goHomePage = this.goHomePage.bind(this)
    this.sendPayAmount = this.sendPayAmount.bind(this)
    this.gotoPlayList = this.gotoPlayList.bind(this)
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/insertPhone')
  }

  componentDidMount() {
    const { payment } = this.props
    const base = JSON.parse(localStorage.getItem('base'))
    const { cardList } = base || {}
    const jzCard = cardList.find((card) => card.id == 'jzcard')

    this.setState({
      cardFee: base.hospitalInfo.cardFee,
      jzCard,
    })
    debugger;

    // 判断是否有建档卡费 isFeePay初始化前端配置为false，如果交缴费成功后为true
    // isCardFee为true需要先缴卡费
    if(Number(base.hospitalInfo.cardFee != 0)){
      if(!base.hospitalInfo.isFeePay){
        // 去支付
        const payInfo = {
          businessName: '建卡费用', 
          businessType: 'createCard',
          printTitle: '',       // 打印的title 『充值』or『缴费』
          cardFee: base.hospitalInfo.cardFee,  // 卡费金额
          amount: base.hospitalInfo.cardFee,  // 支付金额，需要加
        }
  
        let createPaymentData = Object.assign(payment || {}, payInfo)
        this.props.addPayment(createPaymentData)

        // 无充值功能跳到支付支付卡费，有充值功能跳到充值界面
        if(jzCard.payMode){
          this.sendPayAmount()
        }
        else{
          this.gotoPlayList()
        }
      }
      else{
        this.queryCardID()
      }
    }
    else{
      this.queryCardID()
    }
  }

  // 取得卡序列号
  queryCardID(){
    const that = this

    getMakeCard()
    .then(od => {
      if(od.status){
        let cardNo =  od.data.CARD_NO
        that.setState({
          cardNo,
        }, () => {
          that.queryKeyB(cardNo)
        })
      }
      else{
        that.isSignOutBtn = false
      }
    })
    .catch(err => {
      that.isSignOutBtn = false
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 取得卡序列号
  queryKeyB(card_no){
    const that = this

    getGetKey({
      card_no
    })
    .then(od => {
      if(od.status){
        let cardKey = od.data[0].CARDKEY
        that.setState({
          cardKey
        },() => {
          that.queryReadCard(cardKey) 
        })
      }
      else{
        that.isSignOutBtn = false
      }
    })
    .catch(err => {
      that.isSignOutBtn = false;
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 取得卡序列号
  queryReadCard(cardkey){
    const that = this

    getReadCard({
      cardkey
    })
    .then(od => {
      if(od.status){
        let displayNo = od.data.DISPLAY_NO
        that.setState({
          displayNo
        }, () => {
          that.setCardStore()
        })
      }
      else{
        that.isSignOutBtn = false
        // 执行卡回收
        that.queryRecycleCard()
      }
    })
    .catch(err => {
      that.isSignOutBtn = false;
      that.queryRecycleCard();
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 存储到redux仓库
  setCardStore(){
    // 存储手机号码
    let ste = this.state
    let createCardData = Object.assign(this.props.createCard, {
        cardNo: ste.cardNo,
        displayNo: ste.displayNo,
        cardKey: ste.cardKey,
      }                                  
    );
    this.props.addCardData(createCardData)
    this.queryMakeCard();
  }

  // 请求HIS发卡
  queryMakeCard(){
    const that = this
    const cardStore = this.props.createCard
    const { userInfo, idType, nationality } = cardStore
    const { COMP_NAME='', ADDRESS='', PEOPLENATIONCODE='', SEX='', NUMBER='', BIRTHDAY='' } = userInfo
    const name = userInfo.COMP_NAME.trim()
    const sex = userInfo.SEX.trim()
    const num = userInfo.NUMBER.trim()
    const birthday = userInfo.BIRTHDAY.trim()
    const displayNo = cardStore.displayNo.trim()
    
    // const { deviceId, hospitalInfo } = this.props.base;
    const { jzCard } = this.state
    let baseInfo = JSON.parse(localStorage.getItem('base'))
    const { deviceId, hospitalInfo } = baseInfo;
    const deviceNum = (Number(deviceId) < 10) ? `0${deviceId}` : deviceId
    debugger;

    let params = {
      card_no: cardStore.cardNo,
      DISPLAY_NO: displayNo,        // 卡面号 字符串    
      CERTI_NO: num,                // 身份证号码 字符串
      NAME: name,                   // 患者姓名 字符串
      SEX: sex === '女' ? 2 : 1,    // 患者性别 字符串（ 1） 是 1 代表男， 2 代
      H_TEL: cardStore.phone,       // 患者手机号码 字符串
      BIRTHDAY: birthday,           // 患者生日 字符串
      OP_CODE: deviceNum,
      CARD_FEE: hospitalInfo.cardFee,
      H_ADDRESS: userInfo.ADDRESS,
      folk: PEOPLENATIONCODE,
      id_type: idType,
      nationality,
    }
    
    // 有卡并且缴完费用，需要加充值流水等几个参数
    if(Number(hospitalInfo.cardFee) !== 0 && hospitalInfo.isFeePay){    //  && jzCard.payMode
      const { orderId, transactionId, payType } = this.props.payment;
      let payTypeCode = '';
      switch(payType){
        case "现金":
          payTypeCode = 0;
          break;
        
        case "银行卡":
          payTypeCode = 1;
          break;
  
        case "微信":
          payTypeCode = 2;
          break;
  
        case "支付宝":
          payTypeCode = 3;
          break;
  
        default:
          payTypeCode = 9;
          break;
      }
      params['cz_type'] = payTypeCode
      params['Cz_serial'] = transactionId
      params['Zz_serial'] = orderId
    }

    getHisMakeCard(params)
    .then(od => {
      if(od.status){
        baseInfo.hospitalInfo.isFeePay = true
        localStorage.setItem('base', JSON.stringify(baseInfo))
        that.writeCard(od.data[0].MR_NO)
      }
      else{
        // 执行卡回收
        that.queryRecycleCard()
      }
    })
    .catch(err => {
      that.isSignOutBtn = false
      that.queryRecycleCard()
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 写卡
  writeCard(mr_no){
    const that = this;
    const { cardFee } = this.state
    const baseInfo = JSON.parse(localStorage.getItem('base'))
    const { cardList } = baseInfo;
    mr_no = mr_no.trim()
    // 取出就诊卡配置
    const jzCard = cardList.find((card) => card.id == 'jzcard')

    sendWriteCard({
      cardkey: this.props.createCard.cardKey,
      mr_no,
    })
    .then(od => {
      if(od.status){
        if(jzCard.payMode == '0'){ // 无充值功能
          that.exitCard()
        }
        else{ // 有充值功能
          if(Number(cardFee) === 0){  // 无卡费
            const payment = that.props.payment
            const payInfo = {
              businessName: '就诊卡充值', 
              businessType: 'createCard',
              printTitle: '充值',
              cardFee: 0,
              mr_no
            }
            const createPaymentData = Object.assign(payment || {}, payInfo)
            that.props.addPayment(createPaymentData)
            that.props.history.push('/payAmount')
          }
          else{
            that.rechargePay(mr_no) 
          }
        }
      }
      else{
        that.isSignOutBtn = false
      }
    })
    .catch(err => {
      that.isSignOutBtn = false
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 有卡费写完卡调充值
  rechargePay(mr_no){
    let that = this
    const { createCard, payment } = this.props
    const { transactionId="", orderId="", Cz_type="" } = createCard
    const base = JSON.parse(localStorage.getItem('base'))
    const { deviceId } = base
    const deviceNum = (Number(deviceId) < 10) ? `0${deviceId}` : deviceId
    const Balance = parseFloat(payment.amount - payment.cardFee).toString()

    sendHisRecharge({
      mr_no,
      Balance,
      Pwd: '',
      Cz_type,
      Cz_serial: transactionId,
      Zz_serial: orderId,
      op_code: deviceNum,
    })
    .then(od => {
      if(od.status){
        // cardInfo.balance = od.data[0].BALANCE      
        notification.success({
          message: `成功`,
          description: od.message,
        });
        that.printCreateCard()
      }
      else{
        notification.error({
          message: `失败`,
          description: od.message,
        });
        // 退费退卡
        that.refund()
        that.exitCard();
      }
    })
    .catch(err => {
      that.refund()
      that.exitCard();
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 退费
  refund(){
    const that = this
    const { createCard, payment } = this.props
    const { orderId="", subject="", desc="", platform="" } = createCard
    const base = JSON.parse(localStorage.getItem('base'))
    const fee = parseFloat(payment.amount - payment.cardFee).toString()
    const refundId = `${new Date().getTime()}_${base.deviceId}`

    const params = {
      platform,
      order_id: orderId,
      refund_request_id: refundId,
      refund_fee: fee,
      subject,
      desc,
      operator_id: '0',
      windows_id: '0',
    }

    refund(params)
    .then(od => {
      if(od.status){
        notification.success({
          message: `成功`,
          description: od.message,
        });
        that.props.history.push({ 
          pathname : '/',
        })
      }
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 建卡打印凭条
  printCreateCard(){
    const that = this
    const { cardFee } = this.state
    const { createCard, payment } = this.props
    const { NAME, NUMBER } = createCard
    const base = JSON.parse(localStorage.getItem('base'))
    const { deviceId } = base
    const deviceNum = (Number(deviceId) < 10) ? `0${deviceId}` : deviceId
    const BALANCE = parseFloat(payment.amount - cardFee).toString()
    const params = {
      HOS_NAME: base.hospitalInfo.value,
      P_NAME: NAME,
      CERTI_NO: NUMBER,
      COST: cardFee,
      BALANCE,
      OP_CODE: `自助${deviceNum}`,
    }
    debugger;

    printVouCard(params)
    .then(od => {
      that.exitCard()
      that.props.history.push({ 
        pathname : '/success',
        query : { messageTitle: '建档成功', messageContent: '请取出就诊卡妥善保管'}
      })
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/success',
        query : { messageTitle: '建档成功', messageContent: '请取出就诊卡妥善保管'}
      })
    })
  }

  // 手动退出新卡
  exitCard(){
    const that = this

    sendExitCard({
      RESOURSE_ID: 'jzcard'
    })
    .then(od => {
      if(od.status){
        that.props.history.push({ 
          pathname : '/success',
          query : { messageTitle: '建档成功', messageContent: '请取出就诊卡妥善保管'}
        })
      }
      else{
        notification.error({
          message: `错误`,
          description: od.message,
          duration: 10,
        })
      }
    })
    .catch(err => {
      that.isSignOutBtn = false
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 卡回收
  queryRecycleCard(){
    let that = this
    
    recycleCard()
    .then(od => {
      if(od.status){
        that.goHomePage()
      }
      else{
        that.isSignOutBtn = false
      }
    })
    .catch(err => {
      that.isSignOutBtn = false
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 去充值固定金额界面
  sendPayAmount(){
    this.props.history.push('/payAmount')
  }

  gotoPlayList(){
    this.props.history.push('/payList')
  }

  render() {
    const { isSignOutBtn, amountDialog, cardFee } = this.state
    return (
      <div>
        <Head quitTime={businessQuitTime.createArchives.grantCard} />
        <div className="context-container clearfix">
          <h2 className="crumbs">建档发卡 - 确认发卡</h2>
          <div className="suc-card">
            <span>正在签约，请耐心等待...</span>
          </div>
        </div>
        <div className="footer">
          {/*<Button type="primary" size="large" onClick={this.goUpPage} className="left" disabled={isSignOutBtn}>
            <Icon type="left" />上一步</Button>*/}
          <Button type="primary" size="large" onClick={this.goHomePage}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}} disabled={ isSignOutBtn }>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
        <Modal
          title="提示"
          visible={amountDialog}
          onOk={this.sendPayAmount}
          onCancel={this.gotoPlayList}
          cancelText="取消"
          okText="确定">
          <p>请先去充值，并支付建卡费用: { cardFee }元</p>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    createCard: state.createCard,
    base: state.base,
    payment: state.payment,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addCardData: (data) => {
      dispatch(Action.addCardData(data));
    },
    addPayment: (data) => {
      dispatch(PaymentAction.addPayment(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GrantCard)