/*
 * 公用打印（非缴费类）存储
 */
export const addPrint = (data) => {
  return {
    type: "ADD_PRINT",
    data
  }
}

export const clearPrint = (data) => {
  return {
    type: "CLEAR_PRINT",
    data
  }
}
