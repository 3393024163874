import React, { Component } from 'react'
import { Button } from 'antd';
import Head from '../head/'

import './selectCard.scss'
import YbkUrl from '../../assets/img/ybk.png'

// 医保卡
export default class MedicalCard extends Component {

  constructor(props){
    super(props);
    this.state = {}
  }

  goHomePage(){
    this.props.history.push('/main')
  }

  render() {
    return (
      <div>
        <Head />

        <div className="card-box clearfix">
          <h1>请插入您的医保卡</h1>
          <div className="jzk-box">
            <img src={YbkUrl} alt="" />
          </div>
        </div>        
        
        <div>
          <Button type="primary" size="large" onClick={this.goHomePage.bind(this)}
              style={{width:'150px', height: '50px', float: "right", marginRight: "40px", marginTop: "10px", fontSize: "20px"}}>退 出</Button>
          <Button type="primary" size="large" onClick={this.goHomePage.bind(this)}
              style={{width:'150px', height: '50px', float: "right", marginRight: "40px", marginTop: "10px", fontSize: "20px"}}>返 回</Button>
          <Button type="primary" size="large"
              style={{width:'150px', height: '50px', float: "right", marginRight: "40px", marginTop: "10px", fontSize: "20px"}}>确 定</Button>
        </div>
      </div>
    )
  }
}
