const mapNames = {
  prescription: { // 处方类别
    '1': '诊疗',
    '2': '西成',
    '3': '液体',
    '4': '草药',
    '5': '毒麻精',
    '66': '挂号',
    '99': '诊挂费',
  }
}

// 支付配置 
const payConfs = {
  yhk: {
    name: '银行卡',
    platForm: 'Bank',
    czType: '1'
  },
  weChat: {
    name: '微信',
    platForm: 'WeChat',
    czType: '2'
  },
  aliPay: {
    name: '支付宝',
    platForm: 'AliPay',
    czType: '3'
  },
  nshPay: {
    name: '农商行聚合支付',
    platForm: 'nsqrcode',
    czType: '4'
  },
}

// 民族代码JSON
const nation = [
  {"id":"01","name":"汉族"},
  {"id":"02","name":"蒙古族"},
  {"id":"03","name":"回族"},  
  {"id":"04","name":"藏族"},
  {"id":"05","name":"维吾尔族"},
  {"id":"06","name":"苗族"},
  {"id":"07","name":"彝族"},
  {"id":"08","name":"壮族"},
  {"id":"09","name":"布依族"},
  {"id":"10","name":"朝鲜族"},
  {"id":"11","name":"满族"},
  {"id":"12","name":"侗族"},
  {"id":"13","name":"瑶族"},
  {"id":"14","name":"白族"},
  {"id":"15","name":"土家族"},
  {"id":"16","name":"哈尼族"},
  {"id":"17","name":"哈萨克族"},
  {"id":"18","name":"傣族"},
  {"id":"19","name":"黎族"},
  {"id":"20","name":"傈僳族"},
  {"id":"21","name":"佤族"},
  {"id":"22","name":"畲族"},
  {"id":"23","name":"高山族"},
  {"id":"24","name":"拉祜族"},
  {"id":"25","name":"水族"},
  {"id":"26","name":"东乡族"},
  {"id":"27","name":"纳西族"},
  {"id":"28","name":"景颇族"},
  {"id":"29","name":"柯尔克孜族"},
  {"id":"30","name":"土族"},
  {"id":"31","name":"达斡尔族"},
  {"id":"32","name":"仫佬族"},
  {"id":"33","name":"羌族"},
  {"id":"34","name":"布朗族"},
  {"id":"35","name":"撒拉族"},
  {"id":"36","name":"毛难族"},
  {"id":"37","name":"仡佬族"},
  {"id":"38","name":"锡伯族"},
  {"id":"39","name":"阿昌族"},
  {"id":"40","name":"普米族"},
  {"id":"41","name":"塔吉克族"},
  {"id":"42","name":"怒族"},
  {"id":"43","name":"乌孜别克族"},
  {"id":"44","name":"俄罗斯族"},
  {"id":"45","name":"鄂温克族"},
  {"id":"46","name":"崩龙族"},
  {"id":"47","name":"保安族"},
  {"id":"48","name":"裕固族"},
  {"id":"49","name":"京族"},
  {"id":"50","name":"塔塔尔族"},
  {"id":"51","name":"独龙族"},
  {"id":"52","name":"鄂伦春族"},
  {"id":"53","name":"赫哲族"},
  {"id":"54","name":"门巴族"},
  {"id":"55","name":"珞巴族"},
  {"id":"56","name":"基诺族"}
]


export function getSession(key){
  return sessionStorage.getItem(key)
}

export function setSession(key, val){
  return sessionStorage.setItem(key, val)
}

export function getLocatStore(key){
  return localStorage.getItem(key)
}
export function setLocatStore(key,val){
  return localStorage.setItem(key, val)
}
export function delLocatStore(key){
  return localStorage.removeItem(key)
}
export function clearLocatStore(){
  return localStorage.clear()
}

// 身份证号脱敏
const idCardEncryption = (idCard) => {
  if(!idCard){
    return ''
  }
  return idCard.replace(/^(.{3}).+(.{3})$/,"$1************$2")
}

// 手机号脱敏
const phoneEncryption = (phone) => {
  if(!phone){
    return ''
  }
  return phone.replace(/^(.{3}).+(.{4})$/,"$1****$2")
}

// 姓名脱敏
const userNameEncryption = (userName) => {
  let name = ''
  if(!userName){
    return ''
  }

  switch(userName.length){
    case '2':
      name = userName.replace(/^(.).+$/,"$1*")
      break

    case '3':
      name = userName.replace(/^(.).+(.)$/,"$1*$2")
      break

    case '4':
      name = userName.replace(/^(.).+(.)$/,"$1**$2")
      break

    default:
      name = userName.replace(/^(.).+(.)$/,"$1***$2")
      break
  }
  return name
}

// 通过身份证号返回性别
const getGenderFromIDCard = (idCardNumber) => {
  if(!idCardNumber){
    return ''
  }

  // 获取身份证号码的倒数第二位数字
  var genderDigit = parseInt(idCardNumber.charAt(idCardNumber.length - 2));
  
  // 返回性别
  return genderDigit % 2 === 0 ? '女' : '男'
}

// 通过身份证号返回出生日期
const getBirthdayFromIDCard = (idCardNumber) =>{
  if(!idCardNumber){
    return ''
  }

  // 根据身份证号码提取出生年月日
  var year = idCardNumber.substring(6, 10);
  var month = idCardNumber.substring(10, 12);
  var day = idCardNumber.substring(12, 14);
  
  // 组装成出生日期字符串，注意这里不能有中文his保存录入不到库中
  var birthday = `${year}-${month}-${day}`;
  
  return birthday;
}

export {
  mapNames,
  payConfs,
  nation,
  idCardEncryption,
  phoneEncryption,
  userNameEncryption,
  getGenderFromIDCard,
  getBirthdayFromIDCard,
}