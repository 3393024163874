import React, { Component } from 'react'
import Head from '../../components/head'
import { Button, Icon, Table } from 'antd';
import businessQuitTime from '../../utils/businessQuitTime'

// redux
import { connect } from 'react-redux';
import * as cardInfoAction from '../../store/actions/cardInfo'

import { queryCurOutFee } from '@/utils/request'

/*
 * 当日门诊费用列表
 */
class QueryResult extends Component {
  constructor(props){
    super(props);
    this.state = {
      // 表数据
      pageSize: 7,
      loading: true,
      columns: [
        {
          title: '药品名称',
          dataIndex: 'name',
        }, 
        {
          title: '规格',
          dataIndex: 'specifications',
        }, 
        {
          title: '数量',
          dataIndex: 'amount',
        }, 
        {
          title: '单价',
          dataIndex: 'price',
        }
        , 
        {
          title: '总价',
          dataIndex: 'totalPrice',
        }
      ],
      curOutFeeList: [],
    }

    this.goUpPage = this.goUpPage.bind(this);
    this.goHomePage = this.goHomePage.bind(this);
  }
  
  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/ocPatientInfo')
  }

  componentDidMount(){
    this.getCurOutFee();

    const userInfo = this.props.cardInfo.userInfo;
    this.setState({
      username: userInfo.NAME,
      sex: userInfo.SEX === '2' ? '女' : '男',
      age: userInfo.AGE,
      cardNo: userInfo.CERTI_NO,
    })
  }

  // 读取就诊卡号
  getCurOutFee(){
    const that = this;

    queryCurOutFee({
      mr_no: that.props.cardInfo.mr_no,
      pwd: '',
    })
    .then(od => {
      if(od.status){
        let dataList = [];
        if(od.data.length !== 0){
          od.data.forEach((fee, idx, arr) => {
            dataList.push({
              name: fee.M_NAME,
              specifications: fee.M_SPEC,
              amount: fee.M_COUNT,
              price: fee.M_PRICE,
              totalPrice: fee.M_COST,
            })
          })
        }

        this.setState({
          curOutFeeList: dataList,
          loading: false
        })
      }
      else{
        this.setState({
          curOutFeeList: [],
          loading: false
        })
      }
    })
    .catch(err => {
      that.setState({
        loading: false
      })
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  twoDecimal(num){
    return Math.round((num*100)/100)
  }


  render() {
    const quitTime = businessQuitTime.outpatientCost.costList;
    const { username, sex, age, cardNo, pageSize, columns, curOutFeeList, loading } = this.state;

    return (
      <div>
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <h3 className="business-name">门诊费用查询 - 当日门诊费用</h3>
          <div className="rec-amount-info">
            <span>姓名: { username }</span>
            <span>性别: { sex }</span>
            <span>年龄: { age }</span>
            <span>身份证号码: { cardNo }</span>
          </div>

          <Table pagination={{ pageSize }}
                 columns={columns} 
                 dataSource={curOutFeeList}
                 loading={loading}
                 className="mt-25"  />
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    cardInfo: state.cardInfo
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addCard: (data) => {
      dispatch(cardInfoAction.addCard(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QueryResult)