import React from 'react'
import jzcard from '@/assets/img/jzcard.png'
import ybcard from '@/assets/img/ybcard.png'
import ylcard from '@/assets/img/ylcard.png'
import jkcard from '@/assets/img/jkcard.png'
import eybcard from '@/assets/img/eybcard.png'
import ejzcard from '@/assets/img/ejzcard.png'
import ejkcard from '@/assets/img/ejkcard.png'
import inputcard from '@/assets/img/inputcard.png'
import idcard from '@/assets/img/idcard.png'
import ybsfzCard from '@/assets/img/ybsfzCard.png'

/*
* 展示读卡介质
*/
const ShowCardImg = (props) => {
  const { cardId } = props

  const readCardImg = (cardId) =>  {
    let cardImg = '';

    switch(cardId){
      case 'jzcard': // 就诊卡
        cardImg = <img src={jzcard} alt=""/>
        break;

      case 'ybcard': // 医保卡
        cardImg = <img src={ybcard} alt=""/>
        break;

      case 'ylcard': // 银联卡
        cardImg = <img src={ylcard} alt=""/>
        break;

      case 'jkcard': // 健康卡
        cardImg = <img src={jkcard} alt=""/>
        break;

      case 'eybcard': // 电子医保卡
        cardImg = <img src={eybcard} alt=""/>
        break;

      case 'ejzcard': // 电子就诊卡
        cardImg = <img src={ejzcard} alt=""/>
        break;

      case 'ejkcard': // 电子健康卡
        cardImg = <img src={ejkcard} alt=""/>
        break;

      case 'inputcard': // 直接录入卡
        cardImg = <img src={inputcard} alt=""/>
        break;

      case 'idcard': // 身份证
        cardImg = <img src={idcard} alt=""/>
        break;

     case 'YBSFZ': // 电子医保凭证
        cardImg = <img src={ybsfzCard} alt=""/>
        break;

      default:
        cardImg = <img src={idcard} alt=""/>
    }
    return cardImg
  }

  return (
    <span>
      { readCardImg(cardId) }
    </span>
  )

}

export default ShowCardImg