/*
 *  住院费用查询
 */
export const addInpatient = (data) => {
  return {
    type: "ADD_INPATIENT",
    data
  }
}

export const clearInpatient = (data) => {
  return {
    type: "CLEAR_INPATIENT",
    data
  }
}
