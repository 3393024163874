import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { Button, Icon } from 'antd';
import businessQuitTime from '../../utils/businessQuitTime'
import Head from '../../components/head/'
import { setSession } from '@/utils/common'
import ShowCardImg from '@/components/showCardImg'
import * as rechargeAction from '../../store/actions/recharge'
import './medicalCardRecharge.scss'

/*
 * 就诊卡充值 
 */ 
class MedicalCardRecharge extends Component {
  constructor(props){
    super(props);
    this.state = {};
    
    this.goUpPage = this.goUpPage.bind(this);
    this.goHomePage = this.goHomePage.bind(this);
  }

  componentDidMount(){
    this.props.addRecharge({})
  }

  goHomePage(){
    this.props.history.push('/')
  }
  goUpPage(){
    this.props.history.push('/')
  }

  // 触发读卡
  changeReadCard (medium){
    setSession('rechargeName', medium.name)   // 存储卡充值的卡名
    const readCardQuitTime = businessQuitTime.medicalCardRecharge.readCard
    this.props.history.push(`/readCard/confirmIdentityInfo/${medium.id}/${readCardQuitTime}/301`)
  }

  render() {
    const quitTime = businessQuitTime.medicalCardRecharge.medicalCardRecharge;
    const readCardQuitTime = businessQuitTime.medicalCardRecharge.readCard;

    const { base } = this.props; 
    let mediumList = [];
    if(base){
      let cardList = base.cardList || [];
      cardList.forEach((card) => {
        if(card.resourcesMode !== 0){
          mediumList.push(card)
        }
      })
    }

    return (
      <div>
        <Head quitTime={quitTime} />

        <div className="context-container clearfix">
          <h2 className="crumbs">就诊卡充值 - 选择充值介质</h2>
          <h3 className="business-name">请选择充值介质</h3>
          <div className="c-content">
            <div className="recharge-list">
              {
                mediumList.map((medium) => {
                  return (
                    <div className="recharge-box" onClick={() => this.changeReadCard(medium)}>
                      <ShowCardImg cardId={medium.id} />
                      <i>{ medium.name }</i>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goUpPage} className="left">
            <Icon type="left" />上一步</Button>
          <Button type="primary" size="large" onClick={this.goHomePage}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
          <div className="address"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { base, cardInfo } = state;
  return {
    base,
    cardInfo,
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addRecharge: (data) => {
      dispatch(rechargeAction.addRecharge(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicalCardRecharge)