export const addPayment = (data) => {
  return {
    type: "ADD_PAYMENT",
    data
  }
}

export const clearPayment = (data) => {
  return {
    type: "CLEAR_PAYMENT",
    data
  }
}
